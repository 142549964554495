<app-bottom-sheet-content-template>

  <h1 Header>your number port is <strong>pending</strong></h1>

  <div Content>
    <p>Keep the SIM from your old network in your phone until the porting process is complete, so we can sms you.</p>

    <div class="button-container">
      <button (click)="returnToManage()">got it</button>
    </div>
  </div>

</app-bottom-sheet-content-template>
