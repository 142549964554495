import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { SupportService } from 'src/app/core/services/support.service';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';

@Component({
  selector: 'raingo-request-a-call-sheet',
  templateUrl: './request-a-call-sheet.component.html',
  styleUrl: './request-a-call-sheet.component.scss'
})
export class RequestACallSheetComponent {
  contactForm: FormGroup;
  ngUnsubscribe = new Subject<void>()

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private supportService: SupportService
  ) { }


  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.contactForm = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(/([0-9A-Za-z\(/\W+)]{2,})/)])],
      number: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$|^[0-9]{12}$')])],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])],
      alternativeNumber: ['', Validators.compose([Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])],

    });
  }
  submit() {
    this.formSubmit()
  }

  private formSubmit() {
    let service = this.store.selectSnapshot(DashboardState.GetSelectedService)

    const body: any = {
      message: `<h2> subject: 'rain network support'}</h2>
  <p style= "font-size: 14px;">(name): ${this.contactForm.value.name}</p>
  <p style= "font-size: 14px;">(tel): ${this.contactForm.value.number}</p>
  <p style= "font-size: 14px;">(email): ${this.contactForm.value.email}</p>
  <p style= "font-size: 14px;">(product): ${service?.product?.name}</p>
  <p style= "font-size: 14px;">(platform): RainGo</p>`
    };

    const ticketRequest = {
      name: this.contactForm.value.name,
      address: null,
      email: this.contactForm.value.email,
      subject: `'rain network support'} #${this.contactForm.value.number}`,
      referenceId: this.contactForm.value.number,
      body,
      captcha: null,
    };

    this.supportService.addTicket(ticketRequest)

  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
