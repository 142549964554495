import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import * as CancellationsActions from "./actions/cancellations.actions"
import { CancellationService } from "src/app/services/cancellation.service";
import { tap } from "rxjs";
import { ShowBottomSheet } from "../BottomSheet/actions/bottom-sheet-actions.actions";

export interface CancellationsStateModel {
  cancellationDate: string
  cancellationReasons: string
}

@State<CancellationsStateModel>({
  name: 'CancellationsState',
  defaults: {
    cancellationDate: '',
    cancellationReasons: ''
  },
})
@Injectable()

export class CancellationsState {
  constructor(
    private cancellationService: CancellationService,
    private store: Store
  ) { }



  @Selector()
  static GetCancellationDate(state: CancellationsStateModel) {
    return state.cancellationDate;
  }
  @Selector()
  static GetCancellationReasons(state: CancellationsStateModel) {
    return state.cancellationReasons;
  }


  @Action(CancellationsActions.CancelFourGService)
  Cancel4GLegacyService(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.CancelFourGService) {
    return this.cancellationService.cancelService(action.request).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ShowBottomSheet("4G-cancellation-success"))
        },
        error: () => {
          this.store.dispatch(new ShowBottomSheet("4G-cancellation-failure"))
        }
      })
    );
  }

  @Action(CancellationsActions.RevokeFourGCancellation)
  RevokeFourGCancellation(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.RevokeFourGCancellation) {
    return this.cancellationService.revokeCancellation(action.serviceId).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ShowBottomSheet("4G-revoke-cancellation-success"))
        },
        error: () => {
          this.store.dispatch(new ShowBottomSheet("4G-revoke-cancellation-failure"))
        }
      })
    );
  }


  @Action(CancellationsActions.CancelFiveGService)
  CancelFiveGService(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.CancelFiveGService) {
    return this.cancellationService.cancelRainOneService(action.request).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ShowBottomSheet("5G-cancellation-success"))
        },
        error: () => {
          this.store.dispatch(new ShowBottomSheet("5G-cancellation-failure"))
        }
      })
    );
  }

  @Action(CancellationsActions.RevokeFiveGCancellation)
  RevokeFiveGCancellation(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.RevokeFiveGCancellation) {
    return this.cancellationService.revokeRainOneCancellation(action.serviceId).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ShowBottomSheet("5G-revoke-cancellation-success"))
        },
        error: () => {
          this.store.dispatch(new ShowBottomSheet("5G-cancellation-failure"))
        }
      })
    );
  }


  @Action(CancellationsActions.GetCancellationDate)
  GetCancellationDate(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.GetCancellationDate) {
    return this.cancellationService.getCancellationDate(action.serviceId).subscribe(res => {
      if (res) {
        ctx.patchState({
          cancellationDate: res.cancellationDate
        })
      }
    })
  }

  @Action(CancellationsActions.SetCancellationReasons)
  SetCancellationReasons(ctx: StateContext<CancellationsStateModel>, action: CancellationsActions.SetCancellationReasons) {
    ctx.patchState({
      cancellationReasons: action.reason
    })
  }
}
