<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>oops</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>Your request failed. Please try again</p>
    </div>

    <div class="button-container">
      <button class="cancel-btn" (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>