<app-bottom-sheet-content-template>
  <h1 Header class="port-header">port <strong>cancellation failed</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>Your porting cancellation request has failed.</p>
    </div>

    <div class="button-container">
      <button (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
