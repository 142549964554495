<div class="header">
  <img (click)="goHome()" src="../../../assets/images/rain-go-logo.svg" alt="rainGo logo" width="75.79px" height="18px">
  <div class="col-right">

    <div *ngIf="showTestPageMenuItem" (click)="goToTestPage()" class="sign-in">Test Page</div>

    <div *ngIf="(viewModel$ | async)?.canSignIn" (click)="goAccountDetails()" class="sign-in">Account Details</div>

    <button *ngIf="(viewModel$ | async)?.canSignIn" (click)="handleAuth()" class="sign-in">Sign Out</button>

  </div>
</div>
