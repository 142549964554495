@if (!(isFirstTimeLanding$ | async)) {
  @if ((navConfig$ | async); as navConfig) {
    @if (navConfig.authed) {
      <div class="navbar">
        @if (navConfig.home && showShopNav) {
        <div class="navbar-option wifi" [class.selected]="selectedNavIcon === 'home'" (click)="goToShop()">
          <div class="icon-container">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.31641 1H3.24929L5.82003 13.0032C5.91433 13.4428 6.15893 13.8358 6.51172 14.1145C6.86452 14.3932 7.30343 14.5402 7.75292 14.5302H17.2047C17.6446 14.5295 18.0711 14.3787 18.4138 14.1029C18.7564 13.827 18.9947 13.4425 19.0893 13.0129L20.6839 5.83221H8.03318M8.03318 18.0336C8.03318 18.5673 7.60049 19 7.06674 19C6.53299 19 6.1003 18.5673 6.1003 18.0336C6.1003 17.4998 6.53299 17.0671 7.06674 17.0671C7.60049 17.0671 8.03318 17.4998 8.03318 18.0336ZM18.6641 18.0336C18.6641 18.5673 18.2314 19 17.6976 19C17.1639 19 16.7312 18.5673 16.7312 18.0336C16.7312 17.4998 17.1639 17.0671 17.6976 17.0671C18.2314 17.0671 18.6641 17.4998 18.6641 18.0336Z"
                [attr.stroke]="getStroke('home')" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <p>shop</p>
        </div>
        } @else {
        <div class="navbar-option">
        </div>
        }
        @if (navConfig.ask) {
        <div class="navbar-option" [class.selected]="selectedNavIcon === 'ask'" (click)="goToAskRain()">
          <div class="icon-container">
            <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 9.1H9.66667C10.1382 9.1 10.5903 9.28964 10.9237 9.62721C11.2571 9.96477 11.4444 10.4226 11.4444 10.9V13.6C11.4444 14.0774 11.2571 14.5352 10.9237 14.8728C10.5903 15.2104 10.1382 15.4 9.66667 15.4H8.77778C8.30628 15.4 7.8541 15.2104 7.5207 14.8728C7.1873 14.5352 7 14.0774 7 13.6V9.1ZM7 9.1C7 8.03629 7.20693 6.983 7.60896 6.00026C8.011 5.01753 8.60028 4.12459 9.34315 3.37243C10.086 2.62028 10.9679 2.02364 11.9385 1.61658C12.9091 1.20951 13.9494 1 15 1C16.0506 1 17.0909 1.20951 18.0615 1.61658C19.0321 2.02364 19.914 2.62028 20.6569 3.37243C21.3997 4.12459 21.989 5.01753 22.391 6.00026C22.7931 6.983 23 8.03629 23 9.1M23 9.1V13.6M23 9.1H20.3333C19.8618 9.1 19.4097 9.28964 19.0763 9.62721C18.7429 9.96477 18.5556 10.4226 18.5556 10.9V13.6C18.5556 14.0774 18.7429 14.5352 19.0763 14.8728C19.4097 15.2104 19.8618 15.4 20.3333 15.4H21.2222C21.6937 15.4 22.1459 15.2104 22.4793 14.8728C22.8127 14.5352 23 14.0774 23 13.6M23 13.6V15.4C23 16.3548 22.6254 17.2705 21.9586 17.9456C21.2918 18.6207 20.3874 19 19.4444 19H15"
                [attr.stroke]="getStroke('ask')" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <p><strong>ask</strong>rain</p>
        </div>
        } @else {
        <div class="navbar-option">
        </div>
        }
        @if(navConfig.manageAccount || navConfig.manageSim) {
        @if(navConfig.manageAccount) {
        <div class="navbar-option" (click)="manageService()" [class.selected]="selectedNavIcon === 'manage'">
          <div class="icon-container">
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.571289 6.71429H23.4284M2.857 1H21.1427C22.4051 1 23.4284 2.02335 23.4284 3.28571V14.7143C23.4284 15.9767 22.4051 17 21.1427 17H2.857C1.59464 17 0.571289 15.9767 0.571289 14.7143V3.28571C0.571289 2.02335 1.59464 1 2.857 1Z"
                [attr.stroke]="getStroke('pay')" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <p>pay</p>
        </div>
        } @else {
          @if(showManageNav) {
            <div class="navbar-option" (click)="manageService()" [class.selected]="selectedNavIcon === 'manage'">
              <div class="icon-container">
                <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 11C17.7614 11 20 8.76142 20 6C20 3.23858 17.7614 1 15 1C12.2386 1 10 3.23858 10 6C10 8.76142 12.2386 11 15 11ZM15 11C17.1217 11 19.1566 11.8429 20.6569 13.3431C22.1571 14.8434 23 16.8783 23 19M15 11C12.8783 11 10.8434 11.8429 9.34315 13.3431C7.84285 14.8434 7 16.8783 7 19"
                    [attr.stroke]="getStroke('manage')" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <p>manage</p>
            </div>
          } @else {
            <div class="navbar-option">
            </div>
          }
        }
        }
      </div>
    } @else {
    <div class="navbar unAuthed">
      @if (navConfig.home) {
      <div class="navbar-option wifi" [class.selected]="selectedNavIcon === 'home'" (click)="goToShop()">
        <div class="icon-container">
          @if (selectedNavIcon !== 'home') {
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.31641 1H3.24929L5.82003 13.0032C5.91433 13.4428 6.15893 13.8358 6.51172 14.1145C6.86452 14.3932 7.30343 14.5402 7.75292 14.5302H17.2047C17.6446 14.5295 18.0711 14.3787 18.4138 14.1029C18.7564 13.827 18.9947 13.4425 19.0893 13.0129L20.6839 5.83221H8.03318M8.03318 18.0336C8.03318 18.5673 7.60049 19 7.06674 19C6.53299 19 6.1003 18.5673 6.1003 18.0336C6.1003 17.4998 6.53299 17.0671 7.06674 17.0671C7.60049 17.0671 8.03318 17.4998 8.03318 18.0336ZM18.6641 18.0336C18.6641 18.5673 18.2314 19 17.6976 19C17.1639 19 16.7312 18.5673 16.7312 18.0336C16.7312 17.4998 17.1639 17.0671 17.6976 17.0671C18.2314 17.0671 18.6641 17.4998 18.6641 18.0336Z"
              stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          } @else {
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.31641 1H3.24929L5.82003 13.0032C5.91433 13.4428 6.15893 13.8358 6.51172 14.1145C6.86452 14.3932 7.30343 14.5402 7.75292 14.5302H17.2047C17.6446 14.5295 18.0711 14.3787 18.4138 14.1029C18.7564 13.827 18.9947 13.4425 19.0893 13.0129L20.6839 5.83221H8.03318M8.03318 18.0336C8.03318 18.5673 7.60049 19 7.06674 19C6.53299 19 6.1003 18.5673 6.1003 18.0336C6.1003 17.4998 6.53299 17.0671 7.06674 17.0671C7.60049 17.0671 8.03318 17.4998 8.03318 18.0336ZM18.6641 18.0336C18.6641 18.5673 18.2314 19 17.6976 19C17.1639 19 16.7312 18.5673 16.7312 18.0336C16.7312 17.4998 17.1639 17.0671 17.6976 17.0671C18.2314 17.0671 18.6641 17.4998 18.6641 18.0336Z"
              stroke="#0077C8" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          }
        </div>
        <p>shop</p>
      </div>
      } @else {
      <div class="navbar-option">
      </div>
      }
      @if (navConfig.ask) {
      <div class="navbar-option" (click)="goToAskRain()">
        <div class="icon-container">
          <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 9.1H9.66667C10.1382 9.1 10.5903 9.28964 10.9237 9.62721C11.2571 9.96477 11.4444 10.4226 11.4444 10.9V13.6C11.4444 14.0774 11.2571 14.5352 10.9237 14.8728C10.5903 15.2104 10.1382 15.4 9.66667 15.4H8.77778C8.30628 15.4 7.8541 15.2104 7.5207 14.8728C7.1873 14.5352 7 14.0774 7 13.6V9.1ZM7 9.1C7 8.03629 7.20693 6.983 7.60896 6.00026C8.011 5.01753 8.60028 4.12459 9.34315 3.37243C10.086 2.62028 10.9679 2.02364 11.9385 1.61658C12.9091 1.20951 13.9494 1 15 1C16.0506 1 17.0909 1.20951 18.0615 1.61658C19.0321 2.02364 19.914 2.62028 20.6569 3.37243C21.3997 4.12459 21.989 5.01753 22.391 6.00026C22.7931 6.983 23 8.03629 23 9.1M23 9.1V13.6M23 9.1H20.3333C19.8618 9.1 19.4097 9.28964 19.0763 9.62721C18.7429 9.96477 18.5556 10.4226 18.5556 10.9V13.6C18.5556 14.0774 18.7429 14.5352 19.0763 14.8728C19.4097 15.2104 19.8618 15.4 20.3333 15.4H21.2222C21.6937 15.4 22.1459 15.2104 22.4793 14.8728C22.8127 14.5352 23 14.0774 23 13.6M23 13.6V15.4C23 16.3548 22.6254 17.2705 21.9586 17.9456C21.2918 18.6207 20.3874 19 19.4444 19H15"
              stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <p><strong>ask</strong>rain</p>
      </div>
      } @else {
      <div class="navbar-option">
      </div>
      }
      <div class="navbar-option" (click)="openSheet()">
        <div class="icon-container">
          <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 11C17.7614 11 20 8.76142 20 6C20 3.23858 17.7614 1 15 1C12.2386 1 10 3.23858 10 6C10 8.76142 12.2386 11 15 11ZM15 11C17.1217 11 19.1566 11.8429 20.6569 13.3431C22.1571 14.8434 23 16.8783 23 19M15 11C12.8783 11 10.8434 11.8429 9.34315 13.3431C7.84285 14.8434 7 16.8783 7 19"
              stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <p>sign in</p>
      </div>
    </div>
    }
  }
}@else {
  @if ((navConfig$ | async); as navConfig) {
    <div class="navbar unAuthed">
    <div class="navbar-option" [class.selected]="selectedNavIcon === 'home'" (click)="goToShop()">
    <div class="icon-container">
    @if (selectedNavIcon !== 'home') {
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.31641 1H3.24929L5.82003 13.0032C5.91433 13.4428 6.15893 13.8358 6.51172 14.1145C6.86452 14.3932 7.30343 14.5402 7.75292 14.5302H17.2047C17.6446 14.5295 18.0711 14.3787 18.4138 14.1029C18.7564 13.827 18.9947 13.4425 19.0893 13.0129L20.6839 5.83221H8.03318M8.03318 18.0336C8.03318 18.5673 7.60049 19 7.06674 19C6.53299 19 6.1003 18.5673 6.1003 18.0336C6.1003 17.4998 6.53299 17.0671 7.06674 17.0671C7.60049 17.0671 8.03318 17.4998 8.03318 18.0336ZM18.6641 18.0336C18.6641 18.5673 18.2314 19 17.6976 19C17.1639 19 16.7312 18.5673 16.7312 18.0336C16.7312 17.4998 17.1639 17.0671 17.6976 17.0671C18.2314 17.0671 18.6641 17.4998 18.6641 18.0336Z"
        stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    } @else {
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.31641 1H3.24929L5.82003 13.0032C5.91433 13.4428 6.15893 13.8358 6.51172 14.1145C6.86452 14.3932 7.30343 14.5402 7.75292 14.5302H17.2047C17.6446 14.5295 18.0711 14.3787 18.4138 14.1029C18.7564 13.827 18.9947 13.4425 19.0893 13.0129L20.6839 5.83221H8.03318M8.03318 18.0336C8.03318 18.5673 7.60049 19 7.06674 19C6.53299 19 6.1003 18.5673 6.1003 18.0336C6.1003 17.4998 6.53299 17.0671 7.06674 17.0671C7.60049 17.0671 8.03318 17.4998 8.03318 18.0336ZM18.6641 18.0336C18.6641 18.5673 18.2314 19 17.6976 19C17.1639 19 16.7312 18.5673 16.7312 18.0336C16.7312 17.4998 17.1639 17.0671 17.6976 17.0671C18.2314 17.0671 18.6641 17.4998 18.6641 18.0336Z"
        stroke="#0077C8" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    }
    </div>
    <p>shop</p>
    </div>
    <div class="navbar-option sfsf" (click)="goToAskRain()">
    <div class="icon-container">
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9.1H9.66667C10.1382 9.1 10.5903 9.28964 10.9237 9.62721C11.2571 9.96477 11.4444 10.4226 11.4444 10.9V13.6C11.4444 14.0774 11.2571 14.5352 10.9237 14.8728C10.5903 15.2104 10.1382 15.4 9.66667 15.4H8.77778C8.30628 15.4 7.8541 15.2104 7.5207 14.8728C7.1873 14.5352 7 14.0774 7 13.6V9.1ZM7 9.1C7 8.03629 7.20693 6.983 7.60896 6.00026C8.011 5.01753 8.60028 4.12459 9.34315 3.37243C10.086 2.62028 10.9679 2.02364 11.9385 1.61658C12.9091 1.20951 13.9494 1 15 1C16.0506 1 17.0909 1.20951 18.0615 1.61658C19.0321 2.02364 19.914 2.62028 20.6569 3.37243C21.3997 4.12459 21.989 5.01753 22.391 6.00026C22.7931 6.983 23 8.03629 23 9.1M23 9.1V13.6M23 9.1H20.3333C19.8618 9.1 19.4097 9.28964 19.0763 9.62721C18.7429 9.96477 18.5556 10.4226 18.5556 10.9V13.6C18.5556 14.0774 18.7429 14.5352 19.0763 14.8728C19.4097 15.2104 19.8618 15.4 20.3333 15.4H21.2222C21.6937 15.4 22.1459 15.2104 22.4793 14.8728C22.8127 14.5352 23 14.0774 23 13.6M23 13.6V15.4C23 16.3548 22.6254 17.2705 21.9586 17.9456C21.2918 18.6207 20.3874 19 19.4444 19H15"
        stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    </div>
    <p><strong>ask</strong>rain</p>
    </div>
    @if (navConfig.signIn) {
    <div class="navbar-option" (click)="openSheet()">
    <div class="icon-container">
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 11C17.7614 11 20 8.76142 20 6C20 3.23858 17.7614 1 15 1C12.2386 1 10 3.23858 10 6C10 8.76142 12.2386 11 15 11ZM15 11C17.1217 11 19.1566 11.8429 20.6569 13.3431C22.1571 14.8434 23 16.8783 23 19M15 11C12.8783 11 10.8434 11.8429 9.34315 13.3431C7.84285 14.8434 7 16.8783 7 19"
        stroke="#575757" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    </div>
    <p>sign in</p>
    </div>
    }
    </div>
  }
}