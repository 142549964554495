import { Utils } from "@Utils";
import { IInvoice, IUsersFCDetails } from "src/app/interfaces/account.interfaces";

export class GetUsersBillCycleDate {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date');
}

export class GetUsersBillCycleDateSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date success');
    constructor(public readonly payload: {user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period:{start: number}}}}) {}
}

export class GetUsersBillCycleDateFail {
    static readonly type = Utils.Helpers.Type('[Core: Billcycle] Get users billcycle date fail');
    constructor(public readonly payload: any) {}
}

export class FetchAccountInvoices {
    static readonly type = Utils.Helpers.Type('[Core: Invoices] Fetch account invoices');
}

export class FetchAccountInvoicesSuccess {
    static readonly type = Utils.Helpers.Type('[Core: Invoices] Fetch account invoices success');
    constructor(public readonly payload: IInvoice[]) {}
}

export class FetchAccountInvoicesFail {
    static readonly type = Utils.Helpers.Type('[Core: Invoices] Fetch account invoices fail');
    constructor(public readonly payload: any) {}
}
export class FetchUsersFCDetails {
    static readonly type = Utils.Helpers.Type('[Core: User-Details] Fetch users FC details');
}

export class FetchUsersFCDetailsSuccess {
    static readonly type = Utils.Helpers.Type('[Core: User-Details] Fetch users FC details success');
    constructor(public readonly payload: IUsersFCDetails) {}
}
export class FetchUsersFCDetailsFail {
    static readonly type = Utils.Helpers.Type('[Core: User-Details] Fetch users FC details fail');
    constructor(public readonly payload: any) {}
}