import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { AuthState } from '../store/Auth/auth.state';
import { environment } from 'src/environments/environment';
import { ClientModeState } from '../store/Client Mode/client-mode.state';
import { FiveGCancellationRequest, LegacyFourGCancellationRequest } from '../store/Cancellations/actions';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {

  constructor(
    private http: HttpClient,
    private store: Store,

  ) { }


  //4G cancellations & NVIDIA
  public cancelService(request: LegacyFourGCancellationRequest): Observable<any> {

    let payload = {
      comments: request.comments,
      reason: request.reason,
      collection_address: request.collection_address,
      type: 'scheduled',

    };

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);


    const requestPath = `${environment.SERVICE_PROXY_API}/service/${request.serviceId}/_cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      })
    };

    return this.http.post(requestPath, payload, httpOptions as any)
  }

  //4G revoke cancellations % NVIDIA
  public revokeCancellation(serviceId: string): Observable<any> {

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_revoke-cancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      })
    };
    return this.http.post(requestPath, {}, httpOptions as any);
  }

  //5G cancellations (rainOne and legacy)
  public cancelRainOneService(payload: FiveGCancellationRequest): Observable<any> {
    const { serviceId, collection_address, reason, comments, contact_number, type, } = payload

    const body = {
      reason,
      comments,
      contact_number,
      service_id: payload.serviceId,
      collection_address,
      type
    }

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_precancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      })
    };
    return this.http.post(requestPath, body, httpOptions as any);
  }

  //5G revoke cancellations
  public revokeRainOneCancellation(serviceId: string): Observable<any> {

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_revoke-precancel`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      })
    };
    return this.http.post(requestPath, {}, httpOptions as any);
  }

  // 5G precancel Date
  public getCancellationDate(serviceId: string): Observable<any> {

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const requestPath = `${environment.SERVICE_PROXY_API}/service/${serviceId}/_cancellation-date`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
        ApiKey: environment.apiKey
      })
    };
    return this.http.get(requestPath, httpOptions as any)
  }

}
