import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-port-failed-sheet',
  templateUrl: './port-failed-sheet.component.html',
  styleUrl: './port-failed-sheet.component.scss'
})
export class PortFailedSheetComponent {
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id

  constructor(
    private store: Store,
  ) { }

  goToPortInPage() {
    this.store.dispatch(new CloseBottomSheet())
    this.store.dispatch(new Navigate([`services/${this.serviceId}/manage/porting`]));
  }

}
