import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, map, Observable, timeout } from 'rxjs';
import { Result } from 'src/app/interfaces/interfaces';
import { ServiceHelper } from 'src/app/services/serviceHelper';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private store: Store,
    private http: HttpClient,
  ) { }

  public addTicket(request: any): Observable<Result<any>> {

    const requestPath = `${environment.Notify_Url}/notify/support/ticket`;


    const body = {
      name: request.name,
      email: request.email,
      subject: request.subject,
      body: request.body,
      // referenceId: request.referenceId,
    };

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken

      })
    };

    return this.http.post(requestPath, body, httpOptions).pipe(
      map((response: any) => {
        return (response);
      }),
      catchError(result => ServiceHelper.handleError<boolean>(result))
    );
  }

}
