import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { ManageCardComponent } from './manage-card/manage-card.component';
import { Navigate } from '@ngxs/router-plugin';
import { BackButtonModule } from '../components/back-button/back-button.module';
import { mapPortStatus } from 'src/app/pages/number-porting-page/number-porting.interface';
import { BottomSheetName } from 'src/app/store/BottomSheet/intefaces/bottom-sheet-interfaces';
import { ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { RevokeFiveGCancellation, RevokeFourGCancellation } from 'src/app/store/Cancellations/actions/cancellations.actions';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'raingo-manage-options-page',
  standalone: true,
  imports: [
    ManageCardComponent,
    BackButtonModule,
    CommonModule
  ],
  templateUrl: './manage-options-page.component.html',
  styleUrl: './manage-options-page.component.scss'
})
export class ManageOptionsPageComponent implements OnInit {
  serviceType = this.store.selectSnapshot(DashboardState.GetSelectedService)
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id
  portStatus = this.store.selectSnapshot(DashboardState.GetPortStatus)
  hasScheduledCancellation: string
  status: string;
  portTitle: string = '';
  portDescription: string = '';

  constructor(
    private store: Store
  ) { }


  ngOnInit(): void {
    const status = mapPortStatus(this.portStatus || '');
    this.setPortTitle(status);
    this.setPortContent(status);
    console.log(this.serviceType);

  }

  portNumber() {
    if (!this.portStatus) {
      this.store.dispatch(new Navigate([`services/${this.serviceId}/manage/porting`]));
    } else {
      const status = mapPortStatus(this.portStatus || '');

      let bottomSheet: BottomSheetName | null = null;

      switch (status) {
        case 'failed':
          bottomSheet = 'port-failed';
          break;
        case 'complete':
          bottomSheet = 'port-success';
          break;
        case 'cancel':
          bottomSheet = 'port-cancellation';
          break;
        case 'pending':
          bottomSheet = 'port-pending';
          break;
      }

      if (bottomSheet) {
        this.store.dispatch(new ShowBottomSheet(bottomSheet));
      }
    }
  }

  setPortContent(status: string) {
    this.portDescription = status === 'complete' || status === 'failed' || status === 'pending'
      ? 'Need assistance? Ask one of our support agents to get back to you.'
      : 'Keep your old number by moving it to your rain 4G mobile phone SIM.';
  }

  setPortTitle(status: string) {
    this.portTitle =
      status === 'complete' ? 'port my number' :
        status === 'pending' ? 'port number pending' :
          status === 'failed' ? 'port number failed' :
            'port my number';
  }

  public showSimDetails() {
    return this.store.dispatch(new ShowBottomSheet('sim-details'));
  }

  goToSimSwap() {
    this.store.dispatch(new Navigate([`services/${this.serviceId}/manage/sim-swap`]));
  }
  cancel4GService() {
    this.store.dispatch(new ShowBottomSheet('4G-cancellation'))

  }
  cancel5GService() {
    this.store.dispatch(new ShowBottomSheet('5G-cancellation'))
  }

  reverse4GCancellation() {
    this.store.dispatch(new RevokeFourGCancellation(this.serviceId))
  }
  reverse5GCancellation() {
    this.store.dispatch(new RevokeFiveGCancellation(this.serviceId))
  }

  upgradeService() {
    this.store.dispatch(new ShowBottomSheet('upgrade-service'))

  }
}






