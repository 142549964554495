import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { ChangeOpenSheet, CloseBottomSheet, ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { GetCancellationDate } from 'src/app/store/Cancellations/actions/cancellations.actions';

@Component({
  selector: 'raingo-five-g-cancellation-sheet',
  templateUrl: './five-g-cancellation-sheet.component.html',
  styleUrl: './five-g-cancellation-sheet.component.scss'
})
export class FiveGCancellationSheetComponent {
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id

  constructor(
    private store: Store
  ) { }


  cancel() {

    this.store.dispatch([new Navigate([`services/${this.serviceId}/manage/cancellation-survey`]), new CloseBottomSheet(), new GetCancellationDate(this.serviceId)])

  }

  requestACall() {
    this.store.dispatch(new ChangeOpenSheet("request-a-call"));
  }

}
