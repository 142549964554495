<app-bottom-sheet-content-template>
  <h1 Header class="page-header"><strong>your SIM cancellation is unsuccessful</strong></h1>

  <div Content>
    <div class="message-container">
      <p>Your cancellation request has failed please try again</p>
    </div>

    <div class="button-container">
      <button (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>