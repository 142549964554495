import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { TurnOffSignInLoading } from 'src/app/store/Core/actions/core.actions';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConversionUtils } from 'turbocommons-ts';
import { SSOPayload, SSOService } from 'src/app/services/sso.service';
import { Navigate } from '@ngxs/router-plugin';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { AssignSimState } from 'src/app/store/AssignSim/assign-sim.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { SignIn, SignInFail, SignOut } from 'src/app/store/Auth/actions/auth.actions';
import { CoreState } from 'src/app/store/Core/core.state';

@Component({
  selector: 'raingo-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent {
  @Select(CoreState.RegisterLoading) loading$?: Observable<boolean>;
  @Select(AuthState.getAuthError) authError$?: Observable<{message: string, hasError: boolean}>;
  private ngDestroy$: Subject<any> = new Subject();

  private hasRedirect: boolean = true;
  private redirectUrl: string = '/services';

  public signInForm!: FormGroup;
  public attemptedSignIn: boolean = false;
  public showPassword: boolean = false;
  public isSigningIn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private readonly aRoute: ActivatedRoute,
    private readonly sso: SSOService,
    private readonly ref: ChangeDetectorRef,
    private readonly actions: Actions
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.checkForParams();

    this.actions.pipe(
      ofActionCompleted(SignInFail)
    ).subscribe({
      next: (_) => this.isSigningIn = false
    })
  }

  setForm() {
    this.signInForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    });
  }

  openRegisterSheet() {
    this.store.dispatch(new BottomSheetActions.ChangeOpenSheet('register'));
  }

  async signIn() {
    if(this.isSigningIn) return;
    const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage);    
    this.attemptedSignIn = false;

    const { email, password } = this.signInForm.getRawValue() ?? {};
    const cannotProceed = !email || !password || this.signInForm.invalid || this.signInForm.errors;

    if (cannotProceed) return this.attemptedSignIn = true;
    this.isSigningIn = true;
    return this.hasRedirect ? this.store.dispatch(new SignIn(email,password, this.redirectUrl)) : this.store.dispatch(new SignIn(email,password));
  }

  private checkForParams() {
    this.aRoute.queryParams
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe({
      next: (params) => {
        this.ssoSignIN();
        if(params['redirectTo']) {
          this.hasRedirect = true;
          this.redirectUrl = params['redirectTo'];
        }
      }
    });
  }

  private ssoSignIN() {
    const href = window.location.href;
    if(!href.includes("sso=")) return;
    this.store.dispatch(new SignOut(true));
    this.sso.decodeUrlParams(href)
    .then(
      (res: any) => {
        const sso: SSOPayload = JSON.parse(res);
        
        if(sso) {
          
          setTimeout(() => {
            const decodeBaseString = ConversionUtils.base64ToString(sso.token);
            const parsedToken = JSON.parse(decodeBaseString.split('}')[1]+ "}");
            if(this.signInForm) {
              this.signInForm.get('email')?.patchValue(parsedToken.sub);
              this.signInForm.get('password')?.patchValue("***********");
              const redirectUrl = sso.redirectUrl ? sso.redirectUrl : '/services';
              setTimeout(() => {
                this.store.dispatch([new TurnOffSignInLoading(),new CloseBottomSheet(), new Navigate([redirectUrl])])
              }, 700);
            }
          }, 500);
        }
      }
    )
  }

  forgotPassword() {
    this.store.dispatch(new BottomSheetActions.ChangeOpenSheet("forgot_password"));
  }

  public toggleHideSHowPassword() {
    this.showPassword = !this.showPassword;
    this.ref.detectChanges();
  }

  public getType() {
    return this.showPassword ? 'text' : 'password';
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }

}
