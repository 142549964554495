import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/Auth/auth.state';

@Injectable()
export class PermissionsService {
  constructor(private store: Store, public router: Router) {}

  canActivate(): boolean {
    if (this.store.selectSnapshot(AuthState.isSignedIn)) {
      return true;
    }
    
    this.router.navigate(['/']);
    return false;
  }
}

export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};
