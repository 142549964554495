import { Component } from '@angular/core';
import { PortMyNumberInfo, portMyNumberInfo } from './number-porting.interface';
import { NumberPortingFormComponent } from './number-porting-form/number-porting-form.component';
import { BackButtonModule } from 'src/app/shared/components/back-button/back-button.module';

@Component({
  selector: 'raingo-number-porting-page',
  standalone: true,
  imports: [
    NumberPortingFormComponent,
    BackButtonModule
  ],
  templateUrl: './number-porting-page.component.html',
  styleUrl: './number-porting-page.component.scss'
})
export class NumberPortingPageComponent {
  portInfo: PortMyNumberInfo = portMyNumberInfo;
  hidePortContent: boolean = false
  sections = [
    { title: 'Things to note:', className: 'porting-notes', items: this.portInfo.notes },
    { title: 'Just a heads up:', className: 'porting-disclaimer', items: this.portInfo.disclaimer }
  ];


  port() {
    this.hidePortContent = !this.hidePortContent
  }

}
