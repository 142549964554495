import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-port-request-success-sheet',
  templateUrl: './port-request-success-sheet.component.html',
  styleUrl: './port-request-success-sheet.component.scss'
})
export class PortRequestSuccessSheetComponent {


  constructor(
    private store: Store
  ) { }

  returnToManage() {
    this.store.dispatch(new CloseBottomSheet())
    let serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id
    this.store.dispatch(new Navigate([`services/${serviceId}`]))

  }

}
