import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { FetchServices } from 'src/app/store/Core/actions/core.actions';

@Component({
  selector: 'raingo-revoke-five-g-cancellation',
  templateUrl: './revoke-five-g-cancellation.component.html',
  styleUrl: './revoke-five-g-cancellation.component.scss'
})
export class RevokeFiveGCancellationComponent {

  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch([new CloseBottomSheet(), new FetchServices(), new Navigate(['/services'])])
  }

}
