import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CoreActions } from './actions';
import {
  AssignDeviceLayout,
  ClearServicePolicies,
  EndHomeLoader,
  FetchServices,
  GetWalletData,
  HideHeader,
} from './actions/core.actions';
import { TopupService } from 'src/app/services/topup.service';
import { FormatWalletDataService } from 'src/app/services/format-wallet-data.service';
import { Navigate } from '@ngxs/router-plugin';
import {
  AxiomRegistrationResponse,
  BalancesManagerResponse,
  Bucket,
  ClientDetails,
  DigitalIdentityClientObject,
  FiveGPrepaidService,
  IGroupedService,
  PurchaseHistoryItem,
  ServiceObject,
  TLSDecryptedResponse,
  TLSEncryptedResponse,
  WalletObject,
  defaultWallet,
  emptyClientDetails,
} from 'src/app/interfaces/interfaces';

import { environment } from 'src/environments/environment';
import { paginateServices } from './functions/core-state-functions';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { BottomSheetActions } from '../BottomSheet/actions';
import { v4 as uuidv4 } from 'uuid';

import {
  OTPConfig,
  OTPCreateResponse,
  OTpConfigToAccept,
  OpenOtp,
} from 'src/app/interfaces/otp.interface';
import { MessageBarConfig } from 'src/app/interfaces';

import { AssignSimService } from 'src/app/services/assign-sim.service';
import { NextStep, RetryLoading, SetCanAssign } from '../AssignSim/actions/assign-sim.actions';
import { AssignSimState } from '../AssignSim/assign-sim.state';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AuthState } from '../Auth/auth.state';
import { AuthUserWithSessionId, SignIn } from '../Auth/actions/auth.actions';
import { AuthActions } from '../Auth/actions';
import { ClientModeState } from '../Client Mode/client-mode.state';
import { AssignSimListItem } from 'src/app/interfaces/assign-sim.interfaces';
import { IServicePolicy, IServicesGrouping } from 'src/app/interfaces/services.interface';
import { Utils } from '@Utils';
import { FetchProductsSuccess } from '../Product/actions/product.actions';
import { BACK } from './actions/router.actions';
import { CatelogueState } from '../Catelogue/catelogue.state';
import * as fromSvcActions from './actions/service.actions';
import { SetPageTitle } from 'src/app/pages/Dashboard/store/actions/dashboard.actions';
import { ServicesService } from 'src/app/core/services/services.service';
import { CloseBottomSheet, ShowBottomSheet } from '../BottomSheet/actions/bottom-sheet-actions.actions';
import { ServiceStatuses } from 'src/app/core/enums/service-status.enum';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { SpeedUpMigration, TopUp, TopUpSuccess } from '../topup/actions/topup.actions';
import { TopupState } from '../topup/topup.state';
import { SetClientMode } from '../Client Mode/actions/client-mode.actions';
import { IPromoConfig } from 'src/app/interfaces/promo-config.interface';

interface CoreStateModel {
  registerLoading: boolean;
  homeLoader: boolean;
  layout: string;
  show_loader: boolean;
  data: WalletObject;
  clientDetails: ClientDetails;
  reachedEnd: boolean;
  checkedOut: boolean;
  redirectCounter: number;
  services: IServicesGrouping | null;
  servicesLoaded: boolean;
  purchaseHistory: PurchaseHistoryItem[] | null;
  nickname: string | null;
  TLSIdentifiers: {
    loaded: boolean;
    identifier: string | null;
  };
  decodeIdentifier: TLSDecryptedResponse | null;
  TLSDecryptedResponse: TLSDecryptedResponse | null;
  testTLSDecryptedResponse: TLSDecryptedResponse | null;
  TLSService: ServiceObject | null;
  isIdentifiedUser: boolean;
  isPrimaryUser: boolean;
  error: any;
  messageBarConfig: MessageBarConfig;
  optConfig: OTPConfig;
  oTpConfigToAccept: OTpConfigToAccept | null;
  firstTimeLanding: boolean;
  whoamiTest: {
    res: any;
    error: any;
  };
  isFormattingServices: boolean;
  otpResponse: OTPCreateResponse | null;
  acceptedOTPResponse: any;
  testWalletBalance: any;
  buckets: any;
  svcRes: any;
  openOtps: Array<OpenOtp> | null;
  assignSimPayload: any;
  createAccountError: {
    status: HttpStatusCode | null;
    code: string | null;
    message: { error: string } | null;
  }
  testingCheck: string;
  assignedSimsList: Array<AssignSimListItem> | null;
  tlsloaded: boolean;
  prepaid_5g: {
    hasService: boolean,
    serviceID: string | null,
    bucketID: string | null,
    bucket: Bucket | null,
    loaded: boolean
  }
  insertedSim: string | null;
  freeGiGEligbleServices: string[] | null;
  freeGIGTest: {
    wMsisdn?: string | null,
    wSVC?: ServiceObject | null,
    isAuthed: boolean,
    ids?: string | null,
    log: string | null
  },
  hideHeader: boolean,
  primaryMsisdn: string | null,
  numRainMobileSims: number | null,
  groupedServices: {[id: string]: IGroupedService} | null,
  servicePolicies: Array<IServicePolicy> | null,
  servicePoliciesLoaded: boolean,
  addonCompleteModal: {
    show: boolean,
    type: string,
    newSpeed: string,
    currentSpeed: string,
    customerType: string
  },
  hasReceivedDataAmounts: boolean;
  promoConfig: IPromoConfig  | null
}

@State<CoreStateModel>({
  name: 'core_state',
  defaults: {
    assignedSimsList: null,
    registerLoading: false,
    isFormattingServices: false,
    homeLoader: false,
    layout: '',
    show_loader: false,
    data: defaultWallet(),
    clientDetails: emptyClientDetails(),
    reachedEnd: false,
    checkedOut: false,
    redirectCounter: 0,
    services: null,
    servicesLoaded: false,
    nickname: null,
    purchaseHistory: null,
    TLSIdentifiers: {
      loaded: false,
      identifier: null,
    },
    decodeIdentifier: null,
    TLSDecryptedResponse: null,
    testTLSDecryptedResponse: null,
    TLSService: null,
    isIdentifiedUser: false,
    isPrimaryUser: false,
    error: null,
    messageBarConfig: {
      show: false,
      message: '',
    },
    acceptedOTPResponse: null,
    optConfig: {
      sent: false,
      verified: false,
      otp: '',
    },
    otpResponse: null,
    oTpConfigToAccept: null,
    firstTimeLanding: true,
    whoamiTest: {
      res: null,
      error: null,
    },
    testWalletBalance: null,
    buckets: null,
    svcRes: null,
    openOtps: null,
    assignSimPayload: null,
    createAccountError: {
      status: null,
      code: null,
      message: null,
    },
    testingCheck: '',
    tlsloaded: false,
    prepaid_5g: {
      hasService: false,
      serviceID: null,
      bucketID: null,
      bucket: null,
      loaded: false
    },
    insertedSim: null,
    freeGiGEligbleServices: null,
    freeGIGTest: {
      wMsisdn: null,
      wSVC: null,
      isAuthed: false,
      ids: null,
      log: null
    },
    hideHeader: false,
    primaryMsisdn: null,
    numRainMobileSims: null,
    groupedServices: null,
    servicePolicies: null,
    servicePoliciesLoaded: false,
    addonCompleteModal: {
      show: false,
      type: 'none',
      newSpeed: '',
      currentSpeed: '',
      customerType: ''
    },
    hasReceivedDataAmounts: false,
    promoConfig: null
  }
})
@Injectable()
export class CoreState {

  constructor(
    private topupService: TopupService,
    private formatWalletDataService: FormatWalletDataService,
    private store: Store,
    private authService: AuthService,
    private userService: UserService,
    private _assignSimSvc: AssignSimService,
    private readonly _servicsSvc: ServicesService
  ) { }

  @Selector()
  static HomeLoading(state: CoreStateModel) {
    return state.homeLoader;
  }
  @Selector()
  static testingCheck(state: CoreStateModel) {
    return state.testingCheck;
  }

  @Selector()
  static getClientDetails(state: CoreStateModel) {
    return state.clientDetails;
  }
  @Selector()
  static getDecodedTLSCredentials(state: CoreStateModel) {
    return state.decodeIdentifier;
  }

  @Selector()
  static isServicePoliciesLoaded(state: CoreStateModel) {
    return state.servicePoliciesLoaded;
  }

  @Selector()
  static acceptedOTPResponse(state: CoreStateModel) {
    return state.acceptedOTPResponse;
  }

  @Selector()
  static GetAssignSimPayload(state: CoreStateModel) {
    return state.assignSimPayload;
  }

  @Selector()
  static tlsloaded(state: CoreStateModel) {
    return state.tlsloaded;
  }

  @Selector()
  static getFreeGIGTest(state: CoreStateModel) {
    return state.freeGIGTest;
  }
  @Selector()
  static GetPrimaryMsisdn(state: CoreStateModel) {
    return state.primaryMsisdn;
  }

  @Selector()
  static GetFreeGIGEligbleSvcs(state: CoreStateModel) {
    return state.freeGiGEligbleServices;
  }

  @Selector()
  static GetTLSService(state: CoreStateModel) {
    return state.TLSService;
  }

  @Selector()
  static getWhoami(state: CoreStateModel) {
    return state.whoamiTest;
  }
  @Selector()
  static getServices(state: CoreStateModel) {
    const services = state?.services;
    const { TLSService } = state ?? null;

    if (TLSService) {
      services?.Mobile?.push(TLSService);
    }

    return services;
  }

  @Selector()
  static GetGroupedServices(state: CoreStateModel): {[id: string]: IGroupedService} {
    const services = state?.groupedServices;

    return services!;
  }

  @Selector()
  static GetServicePolicies(state: CoreStateModel): IServicePolicy[] {
    const policies = state?.servicePolicies;

    return policies!;
  }
 
  @Selector([ClientModeState.GetClientMode])
  static getAllServices(state: CoreStateModel, mode: string) {
    const services = state?.services;
    const policies = state.servicePolicies;
    const { TLSService } = state ?? null;
    
    if (TLSService) {
      services?.Mobile?.push(TLSService);
    }
    
    let flattednArray = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(services as any);
    if(policies !== null) {

      flattednArray = flattednArray.map((svc: ServiceObject) => {
        const policy = policies.find((p) => p.service_id === svc.id);
        if(policy) {
          return Object.assign({policy: policy}, svc);
        }
        
        return svc;
      })
    }
    const prepaid5GBucket = state.prepaid_5g;
    if(mode === 'mobile') {
      const sortedArray = flattednArray.filter((svc) => (svc.product?.id === '6cf4e840-00e3-4f8c-a0a0-d3baacc95fac' || svc.product?.id === '1174f6df-0dbd-4ad7-ad9f-0da20ce866fb'));
      const groupedSvcs = Utils.Helpers.sortServicesByParent(sortedArray as any);
      return groupedSvcs;
    } else {
      const sortedArray = flattednArray.filter((svc) => (svc.product?.id !== '6cf4e840-00e3-4f8c-a0a0-d3baacc95fac' && svc.product?.id !== '1174f6df-0dbd-4ad7-ad9f-0da20ce866fb' && (svc.account_type?.toLocaleLowerCase() === mode || !svc.account_type)));
      
      if(prepaid5GBucket && sortedArray.find((svc) => svc.id === prepaid5GBucket?.serviceID)) {
        const groupedSvcs = Utils.Helpers.sortServicesByParent(sortedArray as any, prepaid5GBucket as any);
        return groupedSvcs;
      } else if(services?.Legacy && services?.Fixed){
        return Utils.Helpers.sortServicesByParent([...services?.Fixed as any, ...services?.Legacy as any]);
      } 
      else {
        const groupedSvcs = Utils.Helpers.sortServicesByParent(sortedArray as any);
        return groupedSvcs;
      }

    }

  }

  static getServiceSpeedbyId(Id: string) {
    return createSelector([CoreState], (state: CoreStateModel) => {
      const policy = state.servicePolicies?.find(service => service.service_id === Id);
      if(policy) return policy.policy;

      return null
    });
  }

  static getBucketbyId(Id: string) {
    return createSelector([CoreState], (state: CoreStateModel) => {
      if(state.buckets !== null) {
        const bucket = state.buckets[Id];
        if(bucket) return bucket;
        return null
      }

    });
  }

  static getServicebyMSISDN(msisdn: string) {
    return createSelector([CoreState], (state: CoreStateModel) => {
      const services = state?.services;
  
      if (msisdn && services !== null) {
        const fSvcs = Utils.Helpers.FlattenHashMapArrays(services as any);
        const svc = fSvcs.find((svc: any) => svc.msisdn === msisdn);
  
        return svc
      }

    });
  }
  static getServicebyID(id: string) {
    return createSelector([CoreState], (state: CoreStateModel) => {
      const services = state?.groupedServices;
  
      if (id && services !== null) {
  
        return services[id]
      }

    });
  }


  @Selector()
  static getServiceByQParamID(state: CoreStateModel) {
    const services = state?.services;
    const id = window.location.pathname.split('/services/')[1];

    if (id && services !== null) {
      const fSvcs = Utils.Helpers.FlattenHashMapArrays(services as any);
      const svc = fSvcs.find((svc: any) => svc.id === id);

      return svc
    }

  }

  @Selector()
  static ServicesLoaded(state: CoreStateModel) {
    return state.servicesLoaded;
  }

  @Selector()
  static getUsersServices(state: CoreStateModel) {
    const services = state?.services;
    return services;
  }

  @Selector([AuthState.isAuthed])
  static getMobileServices(state: CoreStateModel, isAuthed: boolean) {
    const services = [];
    const { TLSService } = state ?? null;

    if (TLSService) {
      services?.push(TLSService);
    }

    (state?.services?.Mobile as any)?.forEach((service: ServiceObject) =>
      services?.push(service)
    );
    (state?.services?.Assigned as any)?.forEach((service: ServiceObject) =>
      services?.push(service)
    );

    const decodeIdentifier = state.decodeIdentifier;
    if (!isAuthed && decodeIdentifier) {
      const creds = Utils.Helpers.decodeJWT(decodeIdentifier.tokenCredentialDto?.tokenCredential);
      // return services.filter((svc) => svc.msisdn === creds.mobileNumber);
    }

    return services;
  }

  @Selector([CoreState.getMobileServices])
  static shopHeaderServices(state: CoreStateModel, services: ServiceObject[]) {
    return paginateServices(services);
  }

  @Selector([CoreState.getUserPurchaseHistory])
  static getTopupOptions(
    state: CoreStateModel,
    topupHistory: PurchaseHistoryItem[]
  ) {
    const topupOptions: any[] = [];
    (state?.services?.Mobile as any)?.forEach((service: ServiceObject) =>
      topupOptions?.push(service)
    );
    (state?.services?.Assigned as any)?.forEach((service: ServiceObject) =>
      topupOptions?.push(service)
    );

    const lastFiveOptions = topupHistory?.filter((th) => th.msisdn.length <= 11).slice(-6).map(
      (i) => {
        if (!i.name) {
          const oneWithName = topupHistory.find((th) => th.msisdn === i.msisdn && th.name);
          if (oneWithName) return oneWithName;
          return i;
        }
        return i;
      }
    );

    const { TLSService } = state ?? null;

    if (TLSService) {
      topupOptions?.push(TLSService);
    }

    if (!topupOptions?.length) {
      return [];
    }

    if (lastFiveOptions?.length) {
      const historyOptions: PurchaseHistoryItem[] = [];
      lastFiveOptions?.forEach((option) => {
        if (!historyOptions?.find((op) => option?.msisdn === op?.msisdn)) {
          historyOptions?.push(option);
        }
      });

      historyOptions?.forEach((option) => {
        const found = topupOptions?.find(
          (service: ServiceObject) => service?.msisdn === option?.msisdn
        );
        if (!found) {
          topupOptions?.push({
            sim_name: option?.name,
            msisdn: option?.msisdn,
          });
        }
      });
    }

    return topupOptions;
  }
  @Selector()
  static getActiveBreakpoint(state: CoreStateModel) {
    if(state.layout && state.layout !== undefined) {
      return state.layout;
      }
    }

  @Selector()
  static isLoaderOpen(state: CoreStateModel) {
    return state.show_loader;
  }
  @Selector()
  static getWallet(state: CoreStateModel) {
    return state.data;
  }
  @Selector()
  static checkTransactionEnd(state: CoreStateModel) {
    return state.reachedEnd;
  }
  @Selector()
  static checkoutSelected(state: CoreStateModel) {
    return state.checkedOut;
  }
  @Selector()
  static getUserServices(state: CoreStateModel) {
    return state.services;
  }

  @Selector()
  static isFormattingServices(state: CoreStateModel) {
    return state.isFormattingServices;
  }

  @Selector()
  static getUser4GServices(state: CoreStateModel) {
    return state.services?.Voice;
  }
  @Selector()
  static getUserPurchaseHistory(state: CoreStateModel) {
    return state.purchaseHistory;
  }

  @Selector()
  static isIdentifiedUser(state: CoreStateModel) {
    return state.isIdentifiedUser;
  }
  @Selector()
  static isPrimaryUser(state: CoreStateModel) {
    return state.isPrimaryUser;
  }

  @Selector()
  static getMessageBarConfig(state: CoreStateModel) {
    return state.messageBarConfig;
  }
  @Selector()
  static getOTPConfig(state: CoreStateModel) {
    return state.optConfig;
  }

  @Selector()
  static IsFirstTimeLanding(state: CoreStateModel) {
    return state.firstTimeLanding;
  }

  @Selector()
  static RegisterLoading(state: CoreStateModel) {
    return state.registerLoading;
  }

  @Selector([CoreState.getMobileServices, AuthState.GetConsumerUserID])
  static getConsumerServices(state: CoreStateModel, services: ServiceObject[], userId: string) {

    const consumerServices = services?.filter(service => (service?.account_type?.toLowerCase() === "consumer" || service?.account_type === null )&& service.product?.id !== '6cf4e840-00e3-4f8c-a0a0-d3baacc95fac' && service.product?.id !== '1174f6df-0dbd-4ad7-ad9f-0da20ce866fb');
    return consumerServices;
  }

  @Selector([AuthState.GetSMEUserID])
  static getWorkServices(state: CoreStateModel, userId: string) {
    if(state.services !== null) {
      // @ts-ignore
      const services = Utils.Helpers.FlattenHashMapArrays(state.services) as ServiceObject[];

      const workServices = services.filter(service => service?.account_type?.toLowerCase() === "sme");
      
      return workServices;
    }

  }

  @Selector([CoreState.getWorkServices])
  static getPaginatedWorkServices(state: CoreStateModel, workServices: ServiceObject[]) {
    return paginateServices(workServices);
  }

  @Selector([CoreState.getConsumerServices])
  static getPaginatedConsumerServices(state: CoreStateModel, consumerServices: ServiceObject[]) {
    return paginateServices(consumerServices);
  }

  @Selector([CoreState.getConsumerServices, CoreState.getWorkServices, CoreState.GetAllRainMobileSims])
  static hasWorkAndConsumerServices(state: CoreStateModel, consumerServices: ServiceObject[], workServices: ServiceObject[], mobileSIms: ServiceObject[]) {
    return {
      hasConsumerSVCs: !!consumerServices?.length,
      hasWorkSVCs: !!workServices?.length,
      hasMobileSVCs: !!mobileSIms?.length
    };
  }

  @Selector([AuthState.isAuthed, CoreState.hasWorkAndConsumerServices])
  static showWorkHomeToggle(state: CoreStateModel, isAuthed: boolean, hasWorkAndConsumerServices: {hasConsumerSVCs: boolean,
    hasWorkSVCs: boolean,
    hasMobileSVCs: boolean}) {
      const check = Object.values(hasWorkAndConsumerServices).filter(a=>a===true).length;
      
    return !!isAuthed && check >= 2;
  }

  @Selector()
  static getTLSorError(state: CoreStateModel) {
    return {
      tls_data: state.testTLSDecryptedResponse,
      error: state.error,
    };
  }

  @Selector()
  static getOTPCreateResponse(state: CoreStateModel) {
    return state.otpResponse;
  }

  @Selector()
  static getTestWalletBalance(state: CoreStateModel) {
    return state.testWalletBalance;
  }

  @Selector()
  static GetBuckets(state: CoreStateModel): { [serviceId: string]: Bucket[] } {
    return state.buckets;
  }
  @Selector()
  static getTestSvcRes(state: CoreStateModel) {
    return state.svcRes;
  }

  @Selector()
  static GetOpenOTPs(state: CoreStateModel) {
    return state.openOtps as OpenOtp[];
  }

  @Selector()
  static isSimAssigned(state: CoreStateModel) {
    if (state.decodeIdentifier as TLSDecryptedResponse) {
      return state.decodeIdentifier?.status === 'Active';
    }

    return false;
  }

  @Selector()
  static GetCreateAccountError(state: CoreStateModel) {
    return state.createAccountError;
  }
  @Selector()
  static GetAssignedSimsList(state: CoreStateModel) {
    return state.assignedSimsList;
  }

  @Selector()
  static GetAssignedServices(state: CoreStateModel) {
    return state.services?.Assigned;
  }

  @Selector()
  static HasPayAsYouUseFlag(state: CoreStateModel) {
    return state.prepaid_5g.hasService;
  }
  @Selector()
  static Prepaid5GConfig(state: CoreStateModel) {
    return state.prepaid_5g;
  }

  @Selector()
  static GetInsertedSimMSISDN(state: CoreStateModel) {
    return state.insertedSim;
  }

  @Selector()
  static GetInsertedSimService(state: CoreStateModel) {
    const msisdn = state.insertedSim;

    if (msisdn && state.services !== null) {
      return Utils.Helpers.FlattenHashMapArrays<ServiceObject>(state.services as any).find((svc) => svc.msisdn === msisdn)
    }
  }

  @Selector()
  static Get5GPrepaid(state: CoreStateModel): FiveGPrepaidService[] | null {
    if (state.services && state.prepaid_5g && state.prepaid_5g.hasService) {
      const service = state.services.Fixed?.find((svc) => svc.id === state.prepaid_5g.serviceID);

      return [Object.assign({ bucket: state.prepaid_5g.bucket }, service)] as FiveGPrepaidService[];
    }

    return null;
  }

  @Selector()
  static WifiServices(state: CoreStateModel) {
    return [
      {
        "type": "Mobile",
        "id": "005fce51-a4fb-4d05-9bd4-63c9d5d8305b",
        "msisdn": "27696119755",
        "sim_name": "SIM 4",
        "user_id": "47b74bdb-f047-48d2-8405-b297f455ce66",
        "account_type": null,
        "status": 0,
        "balances": {
          "data": "75.98"
        }
      },
    ]
  }

  @Selector()
  static HideHeader(state: CoreStateModel) {
    return state.hideHeader;
  }

  @Selector()
  static GetAllRainOneMobileSims(state: CoreStateModel): ServiceObject[] | undefined {
    if (state.services?.Mobile) {
      //@ts-ignore
      const flattenedSvcs = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(state.services)

      return flattenedSvcs.filter((svc) => svc.product?.name === 'standalone 4G generic consumer SIM AU');
    }
  }

  @Selector()
  static GetAllRainMobileSims(state: CoreStateModel): ServiceObject[] | undefined {
    if (state.services?.Mobile || state.services?.Assigned) {
      //@ts-ignore
      const flattenedSvcs = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(state.services)

      return flattenedSvcs.filter((svc) => (svc.product?.id === '6cf4e840-00e3-4f8c-a0a0-d3baacc95fac' || svc.product?.id === '1174f6df-0dbd-4ad7-ad9f-0da20ce866fb'));
    }
  }

  @Selector()
  static hasPostPaid(state: CoreStateModel) {
    if(state.services !== null) {
      const allSvcs = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(state.services! as any)
      const activeServices = allSvcs.filter(service => service.status === ServiceStatuses.Active);
      
      return Boolean((activeServices.length > 0 && activeServices.some(service => service.type === "Legacy")));
    }
  }

  @Selector()
  static GetPromoConfig(state: CoreStateModel) {
    if(state.promoConfig) return state.promoConfig;
  }



  @Action(CoreActions.RegisterUser)
  AssignUser(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.RegisterUser
  ) {
    ctx.patchState({
      registerLoading: true,
    });

    return this.userService.registerUser(action.payload).pipe(
      tap({
        next: (res: AxiomRegistrationResponse) => {
          const { email, password } = action?.payload?.user;
          ctx.patchState({
            registerLoading: false,
          });

          localStorage.setItem('reg_first_name', action.payload.user.first_name);
          return ctx.dispatch([new SignIn(email, password)]);
        },
        error: (err: HttpErrorResponse) => {
          const error = err.error;

          const errorString = error.message.includes('FullCircle') ? error.message.split('FullCircle ')[1] : error.message.split('AllUncaughtException ')[1];
          const parsedError = errorString.includes('{') ? JSON.parse(errorString) : errorString;

          ctx.patchState({
            registerLoading: false,
            createAccountError: {
              status: error.status,
              code: error.code,
              message: parsedError,
            }
          });
        },
      })
    );
  }

  @Action(CoreActions.FetchData)
  fetchData(ctx: StateContext<CoreStateModel>) {
    ctx.dispatch([
      new CoreActions.AuthWithTLS(),
      new AuthActions.UpdateSignedInStatus(),
      new AuthActions.GetUserDetails(),
      new CoreActions.FetchTopupHistory(),
      new CoreActions.FetchServices('fetchData')
    ])
  }

  @Action(CoreActions.AuthWithTLS)
  AuthWithTLS(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.AuthWithTLS
  ) {
    ctx.patchState({ tlsloaded: false });
    const state = ctx.getState();
    const signedIn = this.store.selectSnapshot(AuthState.isSignedIn);

    if ((state.TLSIdentifiers.loaded) && !action?.assignSimJourney && !signedIn) {
      ctx.dispatch([
        new AuthActions.GetUserDetails(),
        new CoreActions.FetchTopupHistory(),
        new CoreActions.FetchServices('AuthWithTLS')
      ])

      return ctx.patchState({ tlsloaded: true });
    };

    return this.authService.getEncryptedTLSInfo().pipe(
      tap({
        next: (res: TLSEncryptedResponse) => {
          ctx.patchState({
            error: null,
            TLSIdentifiers: {
              loaded: true,
              identifier: res.identifiers
            },
            isIdentifiedUser: Boolean(res?.identifiers),
            whoamiTest: {
              res: res,
              error: null
            },
          });
          ctx.dispatch(new CoreActions.RequestToDecodeIdentifier(res, action?.assignSimJourney));
        },
        error: (err: unknown) => {
          if (location.href.includes('10.60')) {
            alert('@getEncryptedTLSInfo error');
          }

          ctx.patchState({
            error: err,
            decodeIdentifier: null,
            TLSIdentifiers: {
              loaded: false,
              identifier: null,
            },
            whoamiTest: {
              res: null,
              error: err,
            },
          });

          const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage) ?? null;

          if (action?.assignSimJourney || (assignSimStage && parseInt(assignSimStage) >= 1)) {
            setTimeout(() => {
              ctx.dispatch(new NextStep('4'))
            }, 500)
          }
          ctx.dispatch(new EndHomeLoader)
          if (!signedIn) ctx.dispatch([new AuthUserWithSessionId(uuidv4()), new EndHomeLoader]);

          return ctx.patchState({ tlsloaded: true });
        },
      })
    );
  }

  @Action(CoreActions.RequestToDecodeIdentifier)
  RequestToDecodeIdentifier(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.RequestToDecodeIdentifier
  ) {
    const { payload, assignSimJourney } = action ?? {};
    const signedIn = this.store.selectSnapshot(AuthState.isAuthed);
    const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage) ?? null;

    this.authService.authUserByIdentifier(payload.identifiers).subscribe({
      next: (res: TLSDecryptedResponse) => {
        if (res?.udg?.msisdn?.length > 11) {

          if (!assignSimJourney && !assignSimStage && !signedIn) {
            ctx.dispatch(new AuthUserWithSessionId(uuidv4()));
          }

          if (assignSimJourney || (assignSimStage && parseInt(assignSimStage) >= 1)) {
            setTimeout(() => { ctx.dispatch([new NextStep('4'), new RetryLoading(false)]) }, 500)
          }

          return ctx.patchState({ tlsloaded: true });
        };

        return ctx.dispatch(new CoreActions.RequestToDecodeIdentifierSuccess(res, action?.assignSimJourney))
      },
      error: (err: unknown) => {
        if (assignSimJourney || (assignSimStage && parseInt(assignSimStage) >= 1)) {
          setTimeout(() => { ctx.dispatch(new NextStep('4')) }, 500)
        }

        if (!signedIn) ctx.dispatch(new AuthUserWithSessionId(uuidv4()));
        return ctx.patchState({ tlsloaded: true });
      }
    });
  }

  @Action(CoreActions.RequestToDecodeIdentifierSuccess)
  RequestToDecodeIdentifierSuccess(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.RequestToDecodeIdentifierSuccess
  ) {
    const payload = action?.payload as TLSDecryptedResponse;
    const state = ctx.getState();

    if (payload.status === 'Inactive') ctx.dispatch(new SetCanAssign());

    const actions = [];
    const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
    const isSSOLogin = this.store.selectSnapshot(AuthState.isSSOLogin);
    const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage);

    actions.push(
      new AuthActions.UpdateSignedInStatus(),
      new AuthActions.GetUserDetails(),
      new CoreActions.CheckIfIsPrimaryUser(),
      // new ProductActions.FetchProducts(),
      new CoreActions.FetchTopupHistory(),
      new CoreActions.FetchServices('RequestToDecodeIdentifierSuccess')
    );

    if ((!isSignedIn && !isSSOLogin)) {
      actions?.unshift(
        new AuthActions.SetAuthTokens({ token: payload.tokenCredentialDto.tokenCredential })
      );
    }
    const creds = Utils.Helpers.decodeJWT(payload.tokenCredentialDto.tokenCredential);

    ctx.patchState({
      decodeIdentifier: payload,
      TLSIdentifiers: {
        loaded: true,
        identifier: state.TLSIdentifiers.identifier,
      },
      insertedSim: creds.sub
    });

    if (actions?.length) ctx.dispatch(actions);

    if (payload.status === 'Inactive' && (assignSimStage && parseInt(assignSimStage) <= 4)) {
      ctx.dispatch([new RetryLoading(false), new BottomSheetActions.ChangeOpenSheet('assign_sim_wizard')]);
    };
    return ctx.patchState({ tlsloaded: true });
  }

  @Action(CoreActions.FetchTlsServiceObject)
  FetchTlsServiceObject(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchTlsServiceObject
  ) {
    return this.topupService.getServiceObjectByMsisdn(action?.msisdn).pipe(
      tap({
        next: (res: ServiceObject) => {
          ctx.patchState({
            TLSService: res,
          });

          return ctx.dispatch(new CoreActions.GetWalletData(res?.id, true));
        },
        error: (err) => console.log("couldn't find msisdn")
      })
    );
  }


  @Action(CoreActions.ResetRedirectCounter)
  ResetRedirectCounter(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ResetRedirectCounter
  ) {
    ctx.patchState({
      redirectCounter: 0,
    });
  }
  @Action(CoreActions.StartHomeLoader)
  StartHomeLoader(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CheckIfRainOneMobileFailure
  ) {
    ctx.patchState({
      homeLoader: true,
    });
  }
  @Action(CoreActions.EndHomeLoader)
  EndHomeLoader(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CheckIfRainOneMobileFailure
  ) {
    ctx.patchState({
      homeLoader: false,
    });
  }

  @Action(CoreActions.FetchServices)
  FetchServices(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchServices
  ) {
    ctx.dispatch(new ClearServicePolicies)
    ctx.patchState({
      isFormattingServices: true,
      homeLoader: true
    });
    
    return this.topupService.getServices().pipe(
      tap({
        next: (res: IServicesGrouping) => {
          if(res) ctx.dispatch(new CoreActions.FetchServicesSuccess(res));
          else if(res === null)ctx.dispatch(new EndHomeLoader());
        },
        error: (err) => {
          ctx.patchState({
            isFormattingServices: false,
          });
          ctx.dispatch(new EndHomeLoader());
          if(err.status === 500) {
            const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
            
            if(isAuthed && err.error?.message?.toLocaleLowerCase('expired')) {
              ctx.dispatch(new ShowBottomSheet('session-expired'))
            }
          }
        },
        complete: () => {
          const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage) ?? null;
          if (assignSimStage && parseInt(assignSimStage) === 4) {
            ctx.dispatch(new RetryLoading(false));
            ctx.dispatch(new BottomSheetActions.ChangeOpenSheet('assign_sim_wizard'));
          };
        }
      })
    );
  }
  @Action(CoreActions.FetchServicesSuccess)
  FetchServicesSuccess(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchServicesSuccess
  ) {
    const state = ctx.getState();
    const { payload: res } = action ?? {};
    const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);
    
    if (isSignedIn) {
      const user = this.store.selectSnapshot(AuthState.getAxiomUser);
      const mode = this.store.selectSnapshot(ClientModeState.GetClientMode) as string;
      const relPartyId = user?.relatedParty?.find((rp) => rp?.role?.toLocaleLowerCase() === mode)?.id;
      ctx.dispatch([new CoreActions.GetAssignedSimList(relPartyId as string), new CoreActions.FetchServicePolicies({mode})]);


      if(mode === 'consumer') {
        const smeToken = this.store.selectSnapshot(AuthState.getTokenSme);

        if(smeToken) ctx.dispatch(new CoreActions.FetchServicePolicies({mode: 'sme'}))
      } else if(mode === 'sme') {
        const consumerToken = this.store.selectSnapshot(AuthState.getToken);
        if(consumerToken) ctx.dispatch(new CoreActions.FetchServicePolicies({mode: 'consumer'}))
      }
    }

    if (!res) {
      return;
    }

    const decodedCreds = state?.decodeIdentifier as TLSDecryptedResponse;
    const whoamiMsisdn = decodedCreds?.udg.msisdn;
    const isPrimiaryUserByMsisdn = Boolean(res?.Mobile?.find((svc) => svc.msisdn === whoamiMsisdn));

    ctx.patchState({
      svcRes: res,
      isPrimaryUser: isPrimiaryUserByMsisdn,
    });

    ctx.dispatch(new CoreActions.CheckIfIsPrimaryUser());

    if (
      state.services == null ||
      !state.services['Fixed'] ||
      !state.services['Mobile'] ||
      !state.services['Voice'] ||
      !state.services['Legacy'] ||
      !state.services?.['Assigned']
    ) {

      let services = {};
      Object.keys(res).forEach((key: string) => {
        // @ts-ignore
        const group: ServiceObject[] = res[key] as ServiceObject[];
        const nGroup = group.map((svc) => {
          const product = this.store.selectSnapshot(CatelogueState.GetProductById(svc.product_id));
          return Object.assign({ product }, svc)
        });

        services = Object.assign({ [key]: nGroup }, services);
      });

      const flattenedSvcs = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(services)
      const numRainMobileSims = flattenedSvcs.filter((svc) => svc.product?.name === 'standalone 4G generic consumer SIM AU').length;
      ctx.patchState({
        services: services,
        numRainMobileSims,
      });
    }

    ctx.dispatch(new CoreActions.GetWalletData('', false, res));

    setTimeout(() => {
      ctx.patchState({
        isFormattingServices: false,
      });
    }, 0);

  }

  @Action(CoreActions.FetchTopupHistory)
  FetchTopupHistory(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchTopupHistory
  ) {
    return this.topupService.getPurchaseHistory().pipe(
      tap({
        next: (res: PurchaseHistoryItem[]) => {
          if (!res) {
            return;
          }
          return ctx.patchState({
            purchaseHistory: res,
          });
        },
        error: (err) => { },
      })
    );
  }

  @Action(CoreActions.AttemptTLSIndentity)
  AttemptTLSIndentity(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.AttemptTLSIndentity
  ) {
    return this.topupService.tlsIdentityCheck().pipe(
      tap({
        next: (res: PurchaseHistoryItem[]) => {
          if (!res) {
            return;
          }
          return ctx.patchState({
            purchaseHistory: res,
          });
        },
        error: (err) => { },
      })
    );
  }

  @Action(AssignDeviceLayout)
  assignDeviceLayout(
    ctx: StateContext<CoreStateModel>,
    action: AssignDeviceLayout
  ) {
    if(action.payload) {
      ctx.patchState({
        layout: action.payload,
      });
    }
  }

  @Action(GetWalletData)
  getWalletData(ctx: StateContext<CoreStateModel>, action: GetWalletData) {
    const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    const check = this.store.selectSnapshot(CoreState.hasWorkAndConsumerServices);

    ctx.patchState({
      servicesLoaded: false
    })
    
    if(check.hasConsumerSVCs && !check.hasWorkSVCs && !check.hasMobileSVCs && mode !== 'consumer') {
      ctx.dispatch(new SetClientMode('consumer'))
    }

    if(!check.hasConsumerSVCs && check.hasWorkSVCs && !check.hasMobileSVCs && mode !== 'sme') {
        ctx.dispatch(new SetClientMode('sme'))
      }
    if(!check.hasConsumerSVCs && !check.hasWorkSVCs && check.hasMobileSVCs && mode !== 'mobile') {
      ctx.dispatch(new SetClientMode('mobile'))
    }

    this.topupService.GetAllBalances().subscribe({
      next: (res: BalancesManagerResponse) => {
        if (!res) return;
        const state = ctx.getState();
        const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
        const isInArrears = this.store.selectSnapshot(AuthState.isInArrears);

        const prepaid5GBucket: Bucket = res.buckets.find((b) => b.name === '5G-Prepaid') as Bucket;
        
        ctx.patchState(
          {
            freeGIGTest: { isAuthed: isAuthed, log: 'check if authed' }
          }
        )

        if(res.promoServiceIds) {
          ctx.patchState(
            {
              promoConfig: { promoServiceIds: res.promoServiceIds }
            }
          )
        }
        if (!isAuthed && res.freeGigServiceIds?.length > 0) {
          const whoamiMsisdn = state.insertedSim;
          if (whoamiMsisdn !== null) {
            ctx.patchState(
              {
                freeGIGTest: { wMsisdn: whoamiMsisdn, isAuthed: false, log: 'whoamiMsisdn !== null' }
              }
            )
            const whoamiSVC = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(state.services as any).find((svc) => svc.msisdn === whoamiMsisdn);

            if (whoamiSVC !== undefined) {
              ctx.patchState(
                {
                  freeGIGTest: { wMsisdn: whoamiMsisdn, isAuthed: isAuthed, wSVC: whoamiSVC, log: 'whoamiSVC !== undefined' }
                }
              )
              if (!res.freeGigServiceIds?.includes(whoamiSVC.id)) {
                res.freeGigServiceIds = [];
                res.products.DATA = res.products.DATA.filter((p) => p.amount !== 0);
                ctx.patchState(
                  {
                    freeGIGTest: { wMsisdn: whoamiMsisdn, isAuthed: isAuthed, wSVC: whoamiSVC, log: '!res.freeGigServiceIds.includes(whoamiSVC.id)', ids: 'none' }
                  }
                )
              } else {
                res.freeGigServiceIds = [whoamiSVC.id];
                ctx.patchState(
                  {
                    freeGIGTest: { wMsisdn: whoamiMsisdn, isAuthed: isAuthed, wSVC: whoamiSVC, log: 'has res.freeGigServiceIds.includes(whoamiSVC.id)', ids: whoamiSVC.id }
                  }
                )
              }
            } else {
              res.freeGigServiceIds = [];
              res.products.DATA = res.products.DATA.filter((p) => p.amount !== 0);
            }
          } else {
            res.freeGigServiceIds = [];
            res.products.DATA = res.products.DATA.filter((p) => p.amount !== 0);
          }
        }
        const hMap = Utils.Helpers.ToSortedHashMap(res.buckets, {}, 'logicalResource', 'id');

        if (prepaid5GBucket && isInArrears) {
          ctx.patchState({
            prepaid_5g: {
              hasService: true,
              serviceID: prepaid5GBucket.logicalResource.id,
              bucketID: prepaid5GBucket.id,
              bucket: prepaid5GBucket,
              loaded: true
            }
          })
        } else {
          ctx.patchState({
            prepaid_5g: {
              hasService: false,
              serviceID: null,
              bucketID: null,
              bucket: null,
              loaded: true
            }
          })
        }

        ctx.patchState({
          testWalletBalance: hMap,
          buckets: hMap
        });

        ctx.dispatch(new FetchProductsSuccess({ res: res.products }))
        if (res.freeGigServiceIds && res.freeGigServiceIds.length > 0) {
          ctx.patchState({
            freeGiGEligbleServices: res.freeGigServiceIds
          })
        } else {
          ctx.patchState({
            freeGiGEligbleServices: null
          })
        }

        Object.keys(hMap).forEach(
          (serviceID: string) => {
            {
              ctx.patchState({
                testWalletBalance: res,
              });
              const wallet: Bucket[] = hMap[serviceID] as Bucket[];

              const primaryBucket = wallet?.find((bal: Bucket) =>
                bal?.name === 'primary'
              );
              
              const filteredData = wallet?.filter((bal: Bucket) =>
                bal?.name?.includes('data') && !bal?.name?.includes('free')
              );
              const filteredSMS = wallet?.filter((bal: Bucket) =>
                bal?.name?.includes('sms')
              );
              const filteredVoice = wallet?.filter((bal: Bucket) =>
                bal?.name?.includes('voice')
              );
              let data: {value: string, total: string};
              let sms: {value: string, total: string};
              let minutesAndSeconds: {
                value: { minutes: string; seconds: string },
                total: { minutes: string; seconds: string }
              };
              
              data = {
                value: this.formatWalletDataService.formatCombinedData(filteredData, 'remainingValue') || '0',
                total: this.formatWalletDataService.formatCombinedData(filteredData, 'allocatedValue', 'gb')
              };
              sms = {
                value: this.formatWalletDataService.formatCombinedSMS(filteredSMS, 'remainingValue') || '0',
                total: this.formatWalletDataService.formatCombinedSMS(filteredSMS, 'allocatedValue'),
              }
              
              minutesAndSeconds = {
                value: this.formatWalletDataService.formatCombinedVoice(filteredVoice, 'remainingValue'),
                total: this.formatWalletDataService.formatCombinedVoice(filteredVoice, 'allocatedValue'),
              };
    
              if(serviceID === 'af5d64bd-3a0f-4194-a699-361f37fb6568') {
                console.log('filteredVoice:', filteredVoice);
                
              }
              const formattedWallet: WalletObject = {
                data: data || '0',
                sms: sms,
                voice: minutesAndSeconds,
                int_dialing: {
                  value: primaryBucket?.remainingValue.toString()!,
                  total: primaryBucket?.allocatedValue?.toString()! || primaryBucket?.remainingValue.toString()!
                }
              };
              
              if (action.isTLSService) {
                const service = ctx.getState()?.TLSService!;

                ctx.patchState({
                  TLSService: {
                    ...service,
                    balances: formattedWallet
                  },
                });
              } else {
                const storeSvcs = ctx.getState().services
                  ? ctx.getState().services
                  : (action.svcs as IServicesGrouping);

                const services = {
                  Mobile: storeSvcs?.Mobile?.map((service) => {
                    if (service.id !== serviceID) {
                      return service;
                    }
                    return {
                      ...service,
                      balances: formattedWallet
                    };
                  }),
                  Fixed: storeSvcs?.Fixed?.map((service) => {
                    if (service.id !== serviceID) {
                      return service;
                    }
                    return {
                      ...service,
                      balances: formattedWallet
                    };
                  }),
                  Voice: storeSvcs?.Voice?.map((service) => {
                    if (service.id !== serviceID) {
                      return service;
                    }
                    return {
                      ...service,
                      balances: formattedWallet
                    };
                  }),
                  Legacy: storeSvcs?.Legacy?.map((service) => {
                    if (service.id !== serviceID) {
                      return service;
                    }
                    return {
                      ...service,
                      balances: formattedWallet
                    };
                  }),
                  Assigned: storeSvcs?.Assigned?.map((service) => {
                    if (service.id !== serviceID) {
                      return service;
                    }
                    return {
                      ...service,
                      balances: formattedWallet
                    };
                  }),
                };

                if(isInArrears) {
                  ctx.patchState({
                    services: {
                      Mobile: services?.Mobile as ServiceObject[],
                      Fixed: services?.Fixed as ServiceObject[],
                      Voice: services?.Voice as ServiceObject[],
                      Legacy: services?.Legacy as ServiceObject[],
                      Assigned: services?.Assigned as ServiceObject[],
                    },
                    servicesLoaded: true,
                    hasReceivedDataAmounts: true,
                    groupedServices: Utils.Helpers.sortServicesByParent(Utils.Helpers.FlattenHashMapArrays(services as any), prepaid5GBucket)
                  });
                } else {
                  ctx.patchState({
                    services: {
                      Mobile: services?.Mobile as ServiceObject[],
                      Fixed: services?.Fixed as ServiceObject[],
                      Voice: services?.Voice as ServiceObject[],
                      Legacy: services?.Legacy as ServiceObject[],
                      Assigned: services?.Assigned as ServiceObject[],
                    },
                    servicesLoaded: true,
                    hasReceivedDataAmounts: true,
                    groupedServices: Utils.Helpers.sortServicesByParent(Utils.Helpers.FlattenHashMapArrays(services as any))
                  });
                }
              }
            }
          }
        );
        ctx.dispatch(new AuthActions.HideLoader);
        ctx.dispatch(new CoreActions.EndHomeLoader());
      },
      error: (err) => {
        if(err.status === 500) {
          const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
          if(isAuthed && err.error?.message?.toLocaleLowerCase('expired')) {
            ctx.dispatch(new ShowBottomSheet('session-expired'))
          }
          ctx.dispatch(new CoreActions.EndHomeLoader());
        }
      }
    })

  }

  @Action(CoreActions.ResetWallet)
  resetWallet(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      data: defaultWallet(),
    });
  }

  @Action(CoreActions.TurnOffFirstTimeLanding)
  isFirstTimeLanding(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      firstTimeLanding: false,
    });
  }

  @Action(CoreActions.RedirectToAuthURL)
  RedirectToAuthURL(ctx: StateContext<CoreStateModel>) {
    return (window.location.href = environment.tokenUrl);
  }

  @Action(CoreActions.ShowLoader)
  showLoader(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ShowLoader
  ) {
    ctx.patchState({
      show_loader: true,
    });
  }

  @Action(CoreActions.HideLoader)
  hideLoader(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.HideLoader
  ) {
    ctx.patchState({
      show_loader: false,
    });
  }
  @Action(CoreActions.TransactionEnd)
  transactionEnd(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.TransactionEnd
  ) {
    ctx.patchState({
      reachedEnd: true,
    });
  }
  @Action(CoreActions.ClearTransaction)
  clearTransaction(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ClearTransaction
  ) {
    ctx.patchState({
      reachedEnd: false,
    });
  }
  @Action(CoreActions.CheckedOut)
  checkedOut(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CheckedOut
  ) {
    ctx.patchState({
      checkedOut: true,
    });

    return ctx.dispatch([
      new Navigate(['/payment-options']),
      new BottomSheetActions.CloseBottomSheet(),
    ]);
  }
  @Action(CoreActions.ResetCheckOut)
  resetCheckedOut(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ResetCheckOut
  ) {
    ctx.patchState({
      checkedOut: false,
    });
  }

  @Action(CoreActions.DismissMessageBar)
  DismissMessageBar(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.DismissMessageBar
  ) {
    ctx.patchState({
      messageBarConfig: {
        show: false,
        message: '',
      },
    });
  }

  @Action(CoreActions.ShowMessageBar)
  ShowMessageBar(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.ShowMessageBar
  ) {
    const { message } = action.payload;
    ctx.patchState({
      messageBarConfig: {
        show: true,
        message,
      },
    });
  }

  @Action(CoreActions.InitiateAssignSimOTP)
  InitiateAssignSimOTP(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.InitiateAssignSimOTP
  ) {
    const payload = action.payload;
    this._assignSimSvc.InitiateOTP(payload).subscribe({
      next: (res: OTPCreateResponse) => {
        if (res && res.otpResponse?.otp?.length > 0) {
          ctx.patchState({
            otpResponse: res,
            messageBarConfig: {
              show: true,
              message: '<strong>assign SIM</strong> pending',
              code: res.otpResponse.otp
            },
            assignSimPayload: payload
          });
        }
      },
    });
  }

  @Action(CoreActions.AcceptAssignSimOTP)
  AcceptAssignSimOTP(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.AcceptAssignSimOTP
  ) {
    const payload = action.payload;
    this._assignSimSvc.AcceptOTP(payload).subscribe({
      next: (res: any) => {
        if (res) {
          ctx.patchState({
            acceptedOTPResponse: { res, payload },
          });

          ctx.dispatch(new Navigate(['/assign-sim/success']));
        }
      },
      error: (res: any) => {
        if (res) {
          ctx.patchState({
            acceptedOTPResponse: { res, payload },
          });
        }
      },
    });
  }



  @Action(CoreActions.GetAssignedSimList)
  GetAssignedSimList(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.GetAssignedSimList
  ) {
    const id = action.payload;
    if (!id) return;

    return this.topupService.getAssignSimsByPrimaryID(id).pipe(
      tap({
        next: (res) => {

          if (res && res.length > 0) {
            ctx.patchState({
              assignedSimsList: res
            })
          } else {
            ctx.patchState({
              assignedSimsList: null
            })
          }
        },
        error: (err) => {

        }
      })
    );
  }
  @Action(CoreActions.CheckIfIsPrimaryUser)
  CheckIfIsPrimaryUser(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.CheckIfIsPrimaryUser
  ) {
    const state = ctx.getState();

    const services = state.services as any;
    const decodedCreds = state.decodeIdentifier as TLSDecryptedResponse;

    const whoamiMsisdn = decodedCreds?.udg.msisdn;

    const isPrimiaryUserByMsisdn = services?.Mobile?.find((svc: ServiceObject) => svc.msisdn === whoamiMsisdn);

    ctx.patchState({
      isPrimaryUser: isPrimiaryUserByMsisdn !== undefined
    })
  }

  @Action(CoreActions.RemoveFreeGigSVCID)
  RemoveFreeGigSVCID(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.RemoveFreeGigSVCID
  ) {
    const state = ctx.getState();
    const ids = state.freeGiGEligbleServices;
    const id = action.payload;

    ctx.patchState({
      freeGiGEligbleServices: ids?.filter((i) => i !== id)
    })
  }

  @Action(BACK)
  BACK(
    ctx: StateContext<CoreStateModel>,
    action: BACK
  ) {
    window.history.back();
  }

  @Action(HideHeader)
  HideHeader(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.HideHeader
  ) {
    const payload = action.payload;

    ctx.patchState({
      hideHeader: payload
    })
  }

  @Action(fromSvcActions.SaveServiceName)
  SaveServiceName(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.SaveServiceName
  ) {
    const payload = action.payload;
    return this._servicsSvc.saveName(payload.name, payload.serviceId).subscribe({
      next: (res) => {
        if (res) ctx.dispatch([new fromSvcActions.SaveServiceNameSuccess(res), new SetPageTitle(payload.name)])
      },
      error: (err) => ctx.dispatch(new fromSvcActions.SaveServiceNameFail(err))
    })
  }

  @Action(fromSvcActions.SaveServiceNameSuccess)
  SaveServiceNameSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.SaveServiceNameSuccess
  ) {
    const payload = action.payload;
    ctx.dispatch(new CoreActions.FetchServices('SaveServiceNameSuccess'))
  }

  @Action(fromSvcActions.setAddonType)
  SetAddonType(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.SaveServiceNameSuccess
  ) {
    const state = ctx.getState();
    ctx.patchState({
      addonCompleteModal: {
        ...state.addonCompleteModal,
        type: action.payload
      }
    })
  }

  @Action(fromSvcActions.ToggleIntDialing)
  ToggleIntDialing(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.ToggleIntDialing
  ) {
    const payload = action.payload;
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    const selectedTopUp = this.store.selectSnapshot(TopupState.getSelectedTopup)!;
    
    return this._servicsSvc.toggleInternationalCalling(payload.serviceID, payload.enable).subscribe({
      next: (res) => {
        if (res) {
          ctx.dispatch([new fromSvcActions.ToggleIntDialingSuccess({response: res, enabled: payload.enable}), new BottomSheetActions.CloseBottomSheet()])
          if(payload.isPurchasing) {
            ctx.dispatch(new TopUp({option: selectedTopUp, msisdn: selectedSvc.msisdn}));
          }
        }
      },
      error: (err) => ctx.dispatch(new fromSvcActions.ToggleIntDialingFail(err))
    })
  }

  @Action(fromSvcActions.ToggleIntDialingSuccess)
  ToggleIntDialingSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.ToggleIntDialingSuccess
  ) {
    const payload = action.payload;
    // ctx.dispatch(new CoreActions.FetchServices())
  }

  @Action(fromSvcActions.ToggleIntDialingFail)
  ToggleIntDialingFail(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.ToggleIntDialingFail
  ) {
    const payload = action.payload;
    ctx.dispatch(new BottomSheetActions.ShowBottomSheet('toggle-int-dialing-fail'));
  }


  @Action(CoreActions.FetchServicePolicies)
  FetchServicePolicies(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchServicePolicies
  ) {
    const {mode} = action.payload;
    ctx.patchState({
      servicePoliciesLoaded: false
    })
    return this._servicsSvc.FetchServicePolicySpeed(mode).subscribe({
      next: (res) => {
        if (res) {
          ctx.dispatch(new CoreActions.FetchServicePoliciesSuccess(res))
        }
      },
      error: (err) => ctx.dispatch(new CoreActions.FetchServicePoliciesSuccess(err))
    })
  }

  @Action(CoreActions.FetchServicePoliciesSuccess)
  FetchServicePoliciesSuccess(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchServicePoliciesSuccess
  ) {
    const state = ctx.getState();
    const payload = action.payload;
    let allPolicies: any[] = [];

    if(state.servicePolicies !== null) {

      state.servicePolicies?.forEach((sp: IServicePolicy) => {
        if(allPolicies.length > 0 && allPolicies.find((s: IServicePolicy) => s.service_id === sp.service_id)) return;
        allPolicies.push(sp)
      });

      payload.result?.forEach((sp: IServicePolicy) => {
        if(allPolicies.length > 0 && allPolicies.find((s: IServicePolicy) => s.service_id === sp.service_id)) return;
        allPolicies.push(sp)
      });

    } else {
      payload.result?.forEach((sp: IServicePolicy) => allPolicies.push(sp))
    }

    ctx.patchState({
      servicePolicies: [...allPolicies] as Array<IServicePolicy>,
      servicePoliciesLoaded: true
    })
  }
  
  @Action(CoreActions.ClearServicePolicies)
  ClearServicePolicies(
    ctx: StateContext<CoreStateModel>,
    action: CoreActions.FetchServicePoliciesSuccess
  ) {

    ctx.patchState({
      servicePolicies: null,
      servicePoliciesLoaded: false
    })
  }

  @Action(fromSvcActions.SpeedDownService)
  SpeedDownService(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.SpeedDownService
  ) {
    const { speed } = action.payload;
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);

    let index = 0;
    if(speed.includes('60')) index = 1;
    else if(speed.includes('100')) index = 2;

    const speedId = selectedSvc.product?.config?.add_ons![index]?.id;
    const user = this.store.selectSnapshot(AuthState.getAxiomUser) as DigitalIdentityClientObject;
    
    
    this._servicsSvc.ChangeServiceSpeed(speedId, selectedSvc.id).subscribe({
      next: (res) => {
        if(res) ctx.dispatch(new fromSvcActions.ChangeServiceSpeedSuccess(res))
      },
      error: (err) => ctx.dispatch(new fromSvcActions.ChangeServiceSpeedFail(err))
    })
  }

  @Action(fromSvcActions.ChangeServiceSpeed)
  ChangeServiceSpeed(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.ChangeServiceSpeed
  ) {
    const { speed } = action.payload;
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);

    let index = 0;
    if(speed.includes('60')) index = 1;
    else if(speed.includes('100')) index = 2;

    const speedId = selectedSvc.product?.config?.add_ons![index]?.id;
    const user = this.store.selectSnapshot(AuthState.getAxiomUser) as DigitalIdentityClientObject;
    
    const data = {
      msisdn: selectedSvc.msisdn,
      service_id: selectedSvc.id,
      product_id: speedId
    }

    return ctx.dispatch(new SpeedUpMigration(data as any))
  }

  @Action(fromSvcActions.ChangeServiceSpeedSuccess)
  ChangeServiceSpeedSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.ChangeServiceSpeedSuccess
  ) {
    const response = action.payload.result;
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    const paymentType = selectedSvc?.product?.config?.paymentType;
    const speedUpConfig = this.store.selectSnapshot(DashboardState.GetSpeedUpConfig)!;
    ctx.dispatch(new ClearServicePolicies);
    if(response.order === null) {
      if(paymentType === 'upfront') {
        ctx.patchState({
          addonCompleteModal: {
            show: true,
            type: 'scheduled',
            newSpeed: speedUpConfig.selectedSpeed!,
            customerType: 'au',
            currentSpeed: speedUpConfig.currentSpeed
          }
        })
      } else {
        ctx.patchState({
          addonCompleteModal: {
            show: false,
            type: ctx.getState().addonCompleteModal.type === 'imediate' ? 'imediate' : 'scheduled',
            newSpeed: '',
            currentSpeed: speedUpConfig.currentSpeed,
            customerType: 'pp'
          },
        })
      }
      ctx.dispatch([new BottomSheetActions.ChangeOpenSheet('speed-down-success'), new FetchServices('ChangeServiceSpeedSuccess'), new Navigate(['/services'])]);
    } else {
      ctx.dispatch(new fromSvcActions.GetCheckoutId({order_id: response.order.order_id, amount: response.order.total_price}))
    }
  }

  @Action(fromSvcActions.GetCheckoutId)
  GetCheckoutId(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.GetCheckoutId
  ) {
    const payload = action.payload;

    this._servicsSvc.GetCheckoutDetails(payload.order_id, payload.amount).subscribe({
      next: (res) => {
        if(res) ctx.dispatch(new fromSvcActions.GetCheckoutIdSuccess({res: res, order_id: payload.order_id}))
      },
      error: (err) => ctx.dispatch(new fromSvcActions.GetCheckoutIdFail(err))
    })
  }
 
  @Action(fromSvcActions.GetCheckoutIdSuccess)
  GetCheckoutIdSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.GetCheckoutIdSuccess
  ) {
    const payload = action.payload;

    if(payload.res.checkout) {
      const checkoutPayload = {
        checkout_id: payload.order_id,
        checkout: payload.res
      };
      
      ctx.dispatch(new TopUpSuccess(checkoutPayload))
    }
  }

  @Action(fromSvcActions.RevokeSpeedDown)
  RevokeSpeedDown(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.RevokeSpeedDown
  ) {
    const payload = action.payload;

    this._servicsSvc.RevokeSpeedDownRequest(payload.serviceId).subscribe({
      next: (res) => ctx.dispatch(new fromSvcActions.RevokeSpeedDownSuccess(res)),
      error: (err) => ctx.dispatch(new fromSvcActions.RevokeSpeedDownFail(err))
    })
  }

  @Action(fromSvcActions.RevokeSpeedDownSuccess)
  RevokeSpeedDownSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.RevokeSpeedDownSuccess
  ) {
    ctx.dispatch([new FetchServices('RevokeSpeedDownSuccess'), new CloseBottomSheet(), new Navigate(['/services'])]);
  }

  @Action(fromSvcActions.RevokeMigration)
  RevokeMigration(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.RevokeMigration
  ) {
    const payload = action.payload;

    this._servicsSvc.RevokeLevelMigration(payload)
    .subscribe({
      next: (res) => ctx.dispatch(new fromSvcActions.RevokeMigrationSuccess(res)),
      error: (err) => ctx.dispatch(new fromSvcActions.RevokeMigrationFail(err))
    })
  }

  @Action(fromSvcActions.RevokeMigrationSuccess)
  RevokeMigrationSuccess(
    ctx: StateContext<CoreStateModel>,
    action: fromSvcActions.RevokeMigrationSuccess
  ) {
    ctx.dispatch([new FetchServices('RevokeMigrationSuccess'), new CloseBottomSheet(), new Navigate(['/services'])]);
  }

  @Action(AuthActions.SignOut)
  SignOut(ctx: StateContext<CoreStateModel>, action: AuthActions.SignOut) {
    ctx.patchState({
      assignedSimsList: null,
      registerLoading: false,
      isFormattingServices: false,
      homeLoader: false,
      layout: '',
      show_loader: false,
      data: defaultWallet(),
      clientDetails: emptyClientDetails(),
      reachedEnd: false,
      checkedOut: false,
      redirectCounter: 0,
      services: null,
      servicesLoaded: false,
      nickname: null,
      purchaseHistory: null,
      TLSIdentifiers: {
        loaded: false,
        identifier: null,
      },
      decodeIdentifier: null,
      TLSDecryptedResponse: null,
      testTLSDecryptedResponse: null,
      TLSService: null,
      isIdentifiedUser: false,
      isPrimaryUser: false,
      error: null,
      messageBarConfig: {
        show: false,
        message: '',
      },
      acceptedOTPResponse: null,
      optConfig: {
        sent: false,
        verified: false,
        otp: '',
      },
      otpResponse: null,
      oTpConfigToAccept: null,
      firstTimeLanding: true,
      whoamiTest: {
        res: null,
        error: null,
      },
      testWalletBalance: null,
      buckets: null,
      svcRes: null,
      openOtps: null,
      assignSimPayload: null,
      createAccountError: {
        status: null,
        code: null,
        message: null,
      },
      testingCheck: '',
      tlsloaded: false,
      prepaid_5g: {
        hasService: false,
        serviceID: null,
        bucketID: null,
        bucket: null,
        loaded: false
      },
      insertedSim: null,
      freeGiGEligbleServices: null,
      freeGIGTest: {
        wMsisdn: null,
        wSVC: null,
        isAuthed: false,
        ids: null,
        log: null
      },
      hideHeader: false,
      primaryMsisdn: null,
      numRainMobileSims: null,
      groupedServices: null,
      servicePolicies: null,
      servicePoliciesLoaded: false,
      addonCompleteModal: {
        show: false,
        type: 'none',
        newSpeed: '',
        currentSpeed: '',
        customerType: ''
      },
      hasReceivedDataAmounts: false,
      promoConfig: null
    });
  }

}
