import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-port-cancellation-success',
  templateUrl: './port-cancellation-success.component.html',
  styleUrl: './port-cancellation-success.component.scss'
})
export class PortCancellationSuccessComponent {
  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch(new CloseBottomSheet())
  }


}
