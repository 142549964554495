import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-upgrade-service',
  // standalone: true,
  // imports: [],
  templateUrl: './upgrade-service.component.html',
  styleUrl: './upgrade-service.component.scss'
})
export class UpgradeServiceComponent {

  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id


  constructor(private store: Store) { }

  onConfirm() {
    this.store.dispatch([
      new CloseBottomSheet(),
      new Navigate([`services/${this.serviceId}/manage/upgrade`])
    ])

  }

}
