import { Utils } from "src/app/utils";
import { PortInRequest } from ".";

export class PortSim {
  public static type = Utils.Helpers.Type('[Number Porting] Associates sim that will be ported');
  constructor(public payload: PortInRequest) { }
}
export class GetPortStatusById {
  public static type = Utils.Helpers.Type('[Number Porting] Gets the port status of service by service ID');
  constructor(public serviceId: string) { }
}
export class CancelPortById {
  public static type = Utils.Helpers.Type('[Number Porting] Cancells a port with a port status by service ID');
  constructor(public serviceId: string) { }
}
