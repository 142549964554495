import { Utils } from "@Utils";
import { FiveGCancellationRequest, LegacyFourGCancellationRequest } from ".";

export class CancelFourGService {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Cancels a legacy 4G service');
  constructor(public request: LegacyFourGCancellationRequest) { }
}

export class RevokeFourGCancellation {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Revokes a legacy 4G service cancellation');
  constructor(public serviceId: string) { }
}
export class CancelFiveGService {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Precancels a 5G service ');
  constructor(public request: FiveGCancellationRequest) { }
}
export class RevokeFiveGCancellation {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Revokes a 5G cancellation');
  constructor(public serviceId: string) { }
}
export class GetCancellationDate {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Gets the cancellation date of a service to be cancelled');
  constructor(public serviceId: string) { }
}
export class SetCancellationReasons {
  static readonly type = Utils.Helpers.Type('[Services: Cancellations] Sets the cancellation reasons for a service to be cancelled');
  constructor(public reason: string) { }
}

