import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { FetchServices } from 'src/app/store/Core/actions/core.actions';

@Component({
  selector: 'raingo-revoke-cancellation-success',
  templateUrl: './revoke-cancellation-success.component.html',
  styleUrl: './revoke-cancellation-success.component.scss'
})
export class RevokeCancellationSuccessComponent {
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id

  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch([new CloseBottomSheet(), new FetchServices(), new Navigate([`services/${this.serviceId}`])])
  }
}
