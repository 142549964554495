import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-revoke-cancellation-failure',
  templateUrl: './revoke-cancellation-failure.component.html',
  styleUrl: './revoke-cancellation-failure.component.scss'
})
export class RevokeCancellationFailureComponent {

  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch([new CloseBottomSheet()])
  }

}
