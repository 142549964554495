<app-bottom-sheet-content-template>
  <h1 Header class="port-header">need <strong>assistance</strong>? </h1>

  <div Content>
    <div class="message-container">
      <p>please confirm your details below and we will get in contact to process your request as soon as possible.</p>
    </div>
    <form [formGroup]="contactForm">
      <div class="input-container">
        <div class="input-field">
          <input formControlName="name" type="text" placeholder="name">
        </div>
        <div class="input-field">
          <input formControlName="email" type="email" placeholder="email">
        </div>
        <div class="input-field">
          <input formControlName="number" type="number" placeholder="number">
        </div>
        <div class="input-field">
          <input formControlName="alternativeNumber" type="number" placeholder="alternative number">
        </div>
      </div>

    </form>


    <div class="button-container">
      <button class="port-again-btn" (click)="submit()">submit</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
