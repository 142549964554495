import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent {
  @Input() title: string = '';

  goBack() {
    history.go(-1);
  }
}
