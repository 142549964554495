import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/store/Auth/auth.state';


@Injectable()
export class SignedInCheckService {
  constructor(private store: Store, public router: Router) {}

  canActivate(): boolean {
    if (this.store.selectSnapshot(AuthState.isAuthed)) {
        this.router.navigate(['/services']);
      return false;
    }
    
    return true;
  }
}

export const SignInPageGuard: CanActivateFn = (route, state) => {
  return inject(SignedInCheckService).canActivate();
};
