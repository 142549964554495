import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { IPinPukResponse } from 'src/app/interfaces/pinkpuk.interface';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceManagerService {

  constructor(private _http: HttpClient, private readonly store: Store) {}

  public GetPinPuk(msisdn: string): Observable<IPinPukResponse> {
    const token = this.store.selectSnapshot(AuthState.getTauToken)!;
    let url: string = `${environment.RAIN_WEB_API}/resource-manager/pinpuk?iccid=${msisdn}`;

    const httpOptions = {
        headers: new HttpHeaders({
            'accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'ApiKey': 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
            'Authorization': `${token}`,
        })
    };

    
    return this._http.get<IPinPukResponse>(url, httpOptions);
  }
}
