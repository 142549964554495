import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { SetSelectedTopup, TopUp } from 'src/app/store/topup/actions/topup.actions';
import { TopUpOption } from 'src/app/interfaces/interfaces';
import { CoreState } from 'src/app/store/Core/core.state';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { Observable } from 'rxjs';
import { TopupState } from 'src/app/store/topup/topup.state';
import { SetPageTitle, SetSelectedSpeedChange } from 'src/app/pages/Dashboard/store/actions/dashboard.actions';
import { Navigate } from '@ngxs/router-plugin';
import { SetStep } from 'src/app/pages/Dashboard/store/actions/level-up.actions';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CatelogueState } from 'src/app/store/Catelogue/catelogue.state';
import { RainOneProduct } from 'src/app/interfaces/product.interface';
import { DetailedService } from 'src/app/interfaces/services.interface';
import { setAddonType } from 'src/app/store/Core/actions/service.actions';
import { IPromoConfig } from 'src/app/interfaces/promo-config.interface';

export type TabsType = 'data' | 'mins' | 'sms' | 'more';

@Component({
  selector: 'app-purchase-tabs',
  templateUrl: './purchase-tabs.component.html',
  styleUrls: ['./purchase-tabs.component.scss']
})
export class PurchaseTabsComponent implements OnChanges {
  @Input() products: {[key:string] : TopUpOption[]};
  @Input() setSelectedTab: string;
  @Input() isBuyMoreJourney: boolean = false;
  @Output() onSelectedTab: EventEmitter<string> = new EventEmitter();
  @Select(CoreState.GetFreeGIGEligbleSvcs) eligbleFreeGigServices$: Observable<string[]>;
  @Select(TopupState.getSelectedTopup) selectedTopUp$: Observable<TopUpOption>;
  @Select(DashboardState.GetSelectedService) selectedSvc$: Observable<DetailedService>;
  @Select(AuthState.isAuthed) isAuthed$: Observable<boolean>;
  public selectedTab: TabsType = 'data';
  public tabTypes: Array<TabsType>;
  public productsByType: {[type: string]: any} = {};
  public speedUps: Array<RainOneProduct>;
  private currentSpeedUp = '30';
  
  public promoConfig: IPromoConfig;

  constructor(private readonly store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(changes['setSelectedTab'] && changes['setSelectedTab'].currentValue !== changes['setSelectedTab'].previousValue) {
        this.selectedTab =  this.setSelectedTab as TabsType;
      }
      if(changes['products'] && changes['products'].currentValue !== changes['products'].previousValue) {
        const productTypes: any[] = [];
        Object.keys(this.products)?.forEach((t) => {
          productTypes.push(t.toLocaleLowerCase());
          this.buildProductsObject(t.toLocaleLowerCase(), this.products[t]);
          
          if(t === 'speed up') this.getSpeedUps();
        });
        this.tabTypes = [...productTypes] as any;
        
        if(!this.tabTypes.includes('data')) this.selectedTab = this.tabTypes[0];
        this.promoConfig = this.store.selectSnapshot(CoreState.GetPromoConfig)!;
      }
    }
  }

  public selectTab(tab: TabsType) {
    this.onSelectedTab.emit(tab);
    return this.selectedTab = tab;
  }

  openSheet(option: any) {
    if(this.isBuyMoreJourney) {
      return this.continueBuyMoreJourney(option);
    }
    const isInArrears = this.store.selectSnapshot(AuthState.isInArrears);
    const isPrepaid = this.store.selectSnapshot(CoreState.HasPayAsYouUseFlag);
    const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);

    if (!/.*(iPad|iPhone|iPod).*/.test(window.navigator.userAgent)) {
      window.navigator.vibrate(50);
    }

    if(isInArrears && !isPrepaid) {

      return this.store.dispatch([new BottomSheetActions.ShowBottomSheet('in_arrears')]);
    }

    this.redirectToPaymentPage(option, selectedSvc.msisdn);
  }

  public isEligbleForPromo(selectedSvc: DetailedService) {
    
    if(!this.promoConfig) return false;
    
    return this.promoConfig.promoServiceIds?.includes(selectedSvc?.id);
  }

  public openPurchaseIntSheet(selectedSvc: DetailedService) {
    const authed = this.store.selectSnapshot(AuthState.isAuthed);
    if(!authed && selectedSvc.international_access === 'disabled') return;
    if (!/.*(iPad|iPhone|iPod).*/.test(window.navigator.userAgent)) {
      window.navigator.vibrate(50);
    }
    this.store.dispatch([new BottomSheetActions.ShowBottomSheet('buy-international-minutes')]);
  }

  private buildProductsObject(type: string, products: any[]) {
    return this.productsByType[type] = products;
  }

  public SetSelectedSpeedChange(option: any) {
    if(this.isCurrentSpeedPolicy(option.id)) return;

    const isInArrears = this.store.selectSnapshot(AuthState.isInArrears);

    if (!/.*(iPad|iPhone|iPod).*/.test(window.navigator.userAgent)) {
      window.navigator.vibrate(50);
    }
    if(isInArrears) {
      return this.store.dispatch([new BottomSheetActions.ShowBottomSheet('in_arrears_speed_up')]);
    }

    this.setSelectedOption(option);
    const sSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    const currentSpeed = sSvc.policy?.policy!;
    
    let type: any;
    if(currentSpeed.includes('High')) type = 'scheduled';
    if(currentSpeed.includes('30')) type = 'imediate';
    if(currentSpeed.includes('60')) {
       type = option.name.includes('30') ? 'scheduled' : 'imediate';
    }

    const sheet = type === 'scheduled' ? 'speed-down-confirm' : 'speed-up-approval';
    
    return this.store.dispatch([new setAddonType(type), new SetSelectedSpeedChange(option), new BottomSheetActions.ShowBottomSheet(sheet)]);
  }

  public setSelectedOption(option: TopUpOption, isIntDialling: boolean = false) {
    if(isIntDialling) {
      const authed = this.store.selectSnapshot(AuthState.isAuthed);
      const selectedSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
      if(!authed && selectedSvc.international_access === 'disabled') return;
    };
    this.store.dispatch(new SetSelectedTopup(option));
  }

  public formatTabName(type: string) {
    return type.replace(' ', '-');
  }

  public goToLevelUpPage() {
    const pathname = `${window.location.pathname}/level-up`;
    if(pathname.includes('/services/')) {
      this.store.dispatch([new SetStep(1), new SetPageTitle('Level up'), new Navigate([pathname])]);
    }
  }

  private getSpeedUps() {
    let sSvc = this.store.selectSnapshot(DashboardState.GetSelectedService);
    const policies = this.store.selectSnapshot(CoreState.GetServicePolicies)
    
    if(!sSvc.policy) {
      const policy = policies?.find((p) => p.service_id === sSvc.id);
      if(policy) {
        sSvc = Object.assign({policy: policy}, sSvc)
      } else {
        return;
      }
    }
    
    this.currentSpeedUp = sSvc.policy!.policy;
    const ids: Array<{id: string}> = sSvc.product?.config.add_ons!;
    const products: any[] = [];

    if(this.currentSpeedUp.includes('30')) this.currentSpeedUp = "30";
    else if(this.currentSpeedUp.includes('60')) this.currentSpeedUp = "60";
    
    ids.forEach((i) => {
      const product = this.store.selectSnapshot(CatelogueState.GetProductById(i.id));
      products.push(product)
    })
    
    return this.speedUps = products;
  }

  public formatSpeedUpName(name: string) {
    if(name.includes('30')) return '30 Mbps';
    if(name.includes('60')) return '60 Mbps';
    if(name.includes('100')) return '100+ Mbps';
    
    return name;
  }

  public isCurrentSpeedPolicy(id: string) {
    const product = this.store.selectSnapshot(CatelogueState.GetProductById(id));
    return (product?.name.includes(this.currentSpeedUp) || this.currentSpeedUp.includes('High') && product?.name.includes('100'))
  }

  private continueBuyMoreJourney(option: any) {
    const msisdn = this.store.selectSnapshot(TopupState.GetBuyMoreMsisdn)!;

    this.redirectToPaymentPage(option, msisdn);
  }

  public redirectToPaymentPage(selectedTopUp: TopUpOption, topupNumber: string, name?: string) {
    
    this.store.dispatch(new TopUp({option: selectedTopUp, msisdn: topupNumber, name}));
  }
}
