<app-bottom-sheet-content-template>
  <h1 Header class="port-header">need <strong>assistance</strong>? </h1>

  <div Content>
    <div class="message-container">
      <p>The process of porting your number is still pending. Porting may take up to 24 hours to complete. If it has
        been more than 24 hours, would you like a support agent to assist you?.</p>
    </div>

    <div class="button-container">
      <button class="port-again-btn" (click)="requestAssistance()">request a call</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
