import { Component, Input } from '@angular/core';

@Component({
  selector: 'raingo-manage-card',
  standalone: true,
  imports: [],
  templateUrl: './manage-card.component.html',
  styleUrl: './manage-card.component.scss'
})
export class ManageCardComponent {
  @Input() title: string;
  @Input() description: string;

}
