import { Component } from '@angular/core';

@Component({
  selector: 'raingo-port-success-sheet',
  templateUrl: './port-success-sheet.component.html',
  styleUrl: './port-success-sheet.component.scss'
})
export class PortSuccessSheetComponent {

}
