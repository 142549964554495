import { Injectable } from "@angular/core";
import { Action, Select, State, StateContext } from "@ngxs/store";
import { NumberPortingService } from "src/app/core/services/number-porting.service";
import * as NumberPortingActions from "./actions/number-porting.actions"
import { PortInRequest } from "./actions";
import { catchError, of, tap } from "rxjs";

export interface NumberPortingStateModel {
  portStatus: string
}

@State<NumberPortingStateModel>({
  name: 'NumberPortingState',
  defaults: {
    portStatus: ''
  },
})
@Injectable()

export class NumberPortingState {

  constructor(
    private numberPortingService: NumberPortingService
  ) { }

  static GetPortStatus(state: NumberPortingStateModel) {
    return state.portStatus;
  }

  @Action(NumberPortingActions.PortSim)
  PortSim(ctx: StateContext<NumberPortingStateModel>, action: NumberPortingActions.PortSim) {

    const body: PortInRequest = {
      serviceId: action.payload.serviceId,
      fromMisidn: action.payload.fromMisidn,
      serviceType: action.payload.serviceType,
      accountType: action.payload.accountType,
      accountId: action.payload.accountId,
      accountNumber: action.payload.accountNumber,
      company_registration_number: action.payload.company_registration_number,
      contact_person: action.payload.contact_person,
      contact_number: action.payload.contact_number,
      account_category: action.payload.account_category
    }
    return this.numberPortingService.portIn(body);
  }

  @Action(NumberPortingActions.GetPortStatusById)
  GetPortStatusById(ctx: StateContext<NumberPortingStateModel>, action: NumberPortingActions.GetPortStatusById) {
    return this.numberPortingService.getCurrentPortStatusByServiceId(action.serviceId).pipe(
      tap(res => {
        if (res) {
          ctx.patchState({
            portStatus: res?.result?.port_response?.status
          });
        }
      }),
      catchError((error) => {
        console.error('Error fetching port status:', error);
        return of(null);
      })
    );
  }


  @Action(NumberPortingActions.CancelPortById)
  CancelPortById(ctx: StateContext<NumberPortingStateModel>, action: NumberPortingActions.CancelPortById) {
    return this.numberPortingService.cancelPortInById(action.serviceId).pipe(
      tap(res => {
        if (res) {
          ctx.patchState({
            portStatus: res?.result?.port_response?.status
          });
        }
      }),
      catchError((error) => {
        console.error('Error fetching port status:', error);
        return of(null);
      })
    );
  }


}
