import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError, timeout } from 'rxjs';
import { Result } from '../../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/Auth/auth.state';
import { ServiceHelper } from '../../services/serviceHelper';
import { IAddTicketRequest } from '../../interfaces/ticket.interface';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {
    
    constructor(private http: HttpClient, private store: Store) {  }

    public addTicket(request: IAddTicketRequest): Observable<Result<any>> {
        const token = this.store.selectSnapshot(AuthState.getToken) ?? '';
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            Apikey: 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH',
          })
        };
    
        const requestPath = `${environment.Notify_Url}/notify/support/ticket`;
        const body = {
          name: request.name,
          email: request.email,
          subject: request.subject,
          body: request.body,
          referenceId: request.referenceId,
          ga_tag: request?.ga_tag
        };
    
      
        return this.http.post(requestPath, body, httpOptions).pipe(
          timeout(environment.API_TIMEOUT),
          map((response: any) => {
            return (response);
          }),
          catchError(result => ServiceHelper.handleError<boolean>(result))
        );
      }
}




