import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AxiomRegistrationResponse, DigitalIdentityClientObject, RegistrationPayload } from '../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '../store/Auth/auth.state';
import { IInvoice, IInvoiceDetail, IUsersFCDetails } from '../interfaces/account.interfaces';
import { ClientModeState } from '../store/Client Mode/client-mode.state';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    
    constructor(private http: HttpClient, private store: Store) {  }

    registerUser(registrationPayload:RegistrationPayload): Observable<AxiomRegistrationResponse> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/create`

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<AxiomRegistrationResponse>(url, registrationPayload , httpOptions);
    }

    assignUser(registrationPayload:RegistrationPayload): Observable<AxiomRegistrationResponse> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/assign`
        const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                apiKey: apiKey,
            })
        };
        return this.http.post<AxiomRegistrationResponse>(url, registrationPayload , httpOptions);
    }

    getUserDetails(userId: string): Observable<DigitalIdentityClientObject> {
        const url = `${environment.DIGITAL_IDENTITY}/digitalIdentity/${userId}`
        const token = this.store.selectSnapshot(AuthState.getToken);

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                "Authorization": token!
            })
        };
        return this.http.get<DigitalIdentityClientObject>(url , httpOptions);
    }

    public getBillCycleDate(): Observable<{user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period: {start: number}}}}> {
        const url = `${environment.AXIOM_RAINGO}/user/bill-cycle`
        const token = this.store.selectSnapshot(AuthState.getToken);

        const httpOptions = {
            headers: new HttpHeaders({
                'accept': '*/*',
                'Content-Type': 'application/json',
                "Authorization": token!
            })
        };
        return this.http.get<{user: {bill_cycle_spec_detail: {bill_cycle_spec: string, cycle_period: {start: number}}}}>(url , httpOptions);
    }
    
    public getInvoices() {
        const url: string = `${environment.FullCIRCLE_API}/billing/bill`;
        let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
        let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
        let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
        
        const apiKey = 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: userToken!,
                apiKey: apiKey,
                Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
            })
        };

        return this.http.get<IInvoice[]>(url, httpOptions);
    }

    public getInvoiceByNumber(billingNumber: string): Observable<IInvoiceDetail> {

        let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
        let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
        let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    
        const requestPath = `${environment.FullCIRCLE_API}/billing/bill/${billingNumber}`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: userToken!,
            apiKey: apiKey,
            Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
          })
        };
        return this.http.get<IInvoiceDetail>(requestPath, httpOptions);
    }

    public getUserFCDetails(){
        const url: string = `${environment.FULLCIRCLE_API_V5}/profile_summary`;
        let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
        let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
        let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    
        const apiKey = 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: userToken!,
                apiKey: apiKey,
                Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
            })
        };

        return this.http.get<IUsersFCDetails>(url, httpOptions);
    }

}
