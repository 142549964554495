<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>we're sorry to see you go</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>We've received your cancellation request. Please note that this cancellation will only be finalised once your
        free-to-use 5G router has been returned to rain.</p>
    </div>

    <div class="button-container">
      <button class="cancel-btn" (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>