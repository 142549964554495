import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TLSDecryptedResponse } from 'src/app/interfaces/interfaces';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { BottomSheetActions } from 'src/app/store/BottomSheet/actions';
import { CoreState } from 'src/app/store/Core/core.state';
import { ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { Observable } from 'rxjs';
import { UnselectSelectedSvc } from 'src/app/pages/Dashboard/store/actions/dashboard.actions';
import { SetStep } from 'src/app/pages/Dashboard/store/actions/level-up.actions';

export interface SvcActionsConfig {
  show_assign_sim: boolean;
  show_level_up: boolean;
  show_buy_more: boolean;
}

@Component({
  selector: 'raingo-svc-actions',
  templateUrl: './svc-actions.component.html',
  styleUrls: ['./svc-actions.component.scss']
})
export class SvcActionsComponent {
  @Input() config: SvcActionsConfig = {
    show_assign_sim: true,
    show_level_up: true,
    show_buy_more: true
  }
  public tlsLoaded = this.store.selectSnapshot(CoreState.tlsloaded);
  @Select(AuthState.isAuthed) isAuthed$: Observable<boolean>;
  
  constructor(private readonly store: Store) {}

  openAssignSimBottomSheet(tlsLoaded?: boolean) {
    const isSimAssigned = this.store.selectSnapshot(CoreState.isSimAssigned);
    const isPrimaryUser = this.store.selectSnapshot(CoreState.isPrimaryUser);
    const isAuthed = this.store.selectSnapshot(AuthState.isAuthed);
    const formattingServices = this.store.selectSnapshot(CoreState.isFormattingServices);

    const decodedCreds = this.store.selectSnapshot(CoreState.getDecodedTLSCredentials) as TLSDecryptedResponse;
    if (!tlsLoaded) return;

    if(decodedCreds) {
      if(isAuthed && isPrimaryUser) {
        this.store.dispatch(new BottomSheetActions.ShowBottomSheet("assign_sim_primary_message"));
        return
      };
      const assignedServices = this.store.selectSnapshot(CoreState.GetAssignedServices);
      const alreadyAssigned = assignedServices?.find((as) => as.msisdn === decodedCreds?.udg.msisdn);
      if(alreadyAssigned) {
        return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("sim_already_assigned"));
      }
    }

    if(isSimAssigned || formattingServices) {
      if(isSimAssigned) return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("sim_already_assigned"));
      else return;
    }
    return this.store.dispatch(new BottomSheetActions.ShowBottomSheet("assign_sim_wizard"));
  }


  public initMultlineLevelUp() {
    this.store.dispatch(new SetStep(2));
  }


  public buyForOthers() {
    return this.store.dispatch([new UnselectSelectedSvc(), new ShowBottomSheet('select_topup_number')]);
  }

}
