@if ((OpenSheet$ | async); as openSheet) {
<div>
  @switch (openSheet) {
  @case ('select_topup_number') {
  <app-select-top-up-number></app-select-top-up-number>
  }
  @case ('register') {
  <app-register></app-register>
  }
  @case ('sign_in') {
  <app-sign-in></app-sign-in>
  }
  @case ('purchase_result') {
  <app-payment-result-new></app-payment-result-new>
  }
  @case ('assign_sim') {
  <app-assign-sim></app-assign-sim>
  }
  @case ('assign_sim_enter_name') {
  <app-assign-sim-enter-name></app-assign-sim-enter-name>
  }
  @case ('assign_sim_primary_message') {
  <app-primary-user-message-sheet></app-primary-user-message-sheet>
  }
  @case ('assign_sim_unidentified') {
  <app-assign-sim-unidentified></app-assign-sim-unidentified>
  }
  @case ('assign_sim_verification_success') {
  <app-assign-sim-verification-success></app-assign-sim-verification-success>
  }
  @case ('assign_sim_reqest_sheet') {
  <app-assign-sim-request-sheet></app-assign-sim-request-sheet>
  }
  @case ('in_arrears') {
  <app-in-arrears></app-in-arrears>
  }
  @case ('in_arrears_level_up') {
  <raingo-arrears-level-up-sheet></raingo-arrears-level-up-sheet>
  }
  @case ('in_arrears_speed_up') {
  <raingo-arrears-speed-up-sheet></raingo-arrears-speed-up-sheet>
  }
  @case ('forgot_password') {
  <app-forgot-password></app-forgot-password>
  }
  @case ('password_reset_successful') {
  <app-password-reset-successful></app-password-reset-successful>
  }
  @case ('assign_sim_wizard') {
  <app-assign-sim-wizard></app-assign-sim-wizard>
  }
  @case ('assign_sim_register_or_sign_in') {
  <app-assign-sim-register-or-sign-in></app-assign-sim-register-or-sign-in>
  }
  @case ('ask_assign_sim') {
  <app-ask-assign-sim-sheet></app-ask-assign-sim-sheet>
  }
  @case ('ask_assign_sim_success') {
  <app-assign-sim-verification-success></app-assign-sim-verification-success>
  }
  @case ('sim_already_assigned') {
  <app-sim-already-assigned-sheet></app-sim-already-assigned-sheet>
  }
  @case ('topup-free-result') {
  <app-topup-free-result></app-topup-free-result>
  }
  @case ('non-eligible-top-up-error-sheet') {
  <raingo-non-eligible-top-up-error-sheet></raingo-non-eligible-top-up-error-sheet>
  }
  @case ('sim-details') {
  <raingo-sim-details-sheet></raingo-sim-details-sheet>
  }
  @case ('speed-up-approval') {
  <raingo-speed-up-approval-sheet></raingo-speed-up-approval-sheet>
  }
  @case ('buy-international-minutes') {
  <raingo-buy-int-minutes-sheet></raingo-buy-int-minutes-sheet>
  }
  @case ('level-up-success') {
  <raingo-level-up-success-sheet></raingo-level-up-success-sheet>
  }
  @case ('session-expired') {
  <raingo-session-expired-sheet></raingo-session-expired-sheet>
  }
  @case ('level-change-expired') {
  <raingo-level-change-failed></raingo-level-change-failed>
  }
  @case ('speed-down-success') {
  <raingo-speed-down-success-sheet></raingo-speed-down-success-sheet>
  }
  @case ('speed-down-confirm') {
  <raingo-confirm-speed-down-sheet></raingo-confirm-speed-down-sheet>
  }
  @case ('revoke-speed-down-success') {
  <raingo-reverse-speed-down-success-sheet></raingo-reverse-speed-down-success-sheet>
  }
  @case ('revoke-speed-down-confirm') {
  <raingo-reverse-speed-down-confirm-sheet></raingo-reverse-speed-down-confirm-sheet>
  }
  @case ('revoke-level-migration-confirm') {
  <raingo-reverse-level-migration-confirm-sheet></raingo-reverse-level-migration-confirm-sheet>
  }
  @case ('top-up-fail') {
  <raingo-top-up-failed-sheet></raingo-top-up-failed-sheet>
  }
  @case ('toggle-int-dialing-fail') {
  <raingo-toggle-int-dialing-failed-sheet></raingo-toggle-int-dialing-failed-sheet>
  }
  @case ('ask-rain-widget') {
  <raingo-askrain-widget-sheet></raingo-askrain-widget-sheet>
  }
  @case ('port-request-success') {
  <raingo-port-request-success-sheet></raingo-port-request-success-sheet>
  }
  @case ('port-request-failure') {
  <raingo-port-request-failure-sheet></raingo-port-request-failure-sheet>
  }
  @case ('port-pending') {
  <raingo-port-pending-sheet></raingo-port-pending-sheet>
  }
  @case ('port-failed') {
  <raingo-port-failed-sheet></raingo-port-failed-sheet>
  }
  @case ('port-success') {
  <raingo-port-success-sheet></raingo-port-success-sheet>
  }
  @case ('port-cancellation') {
  <raingo-port-cancellation-sheet></raingo-port-cancellation-sheet>
  }
  @case ('port-cancellation-success') {
  <raingo-port-cancellation-success></raingo-port-cancellation-success>
  }
  @case ('port-cancellation-failure') {
  <raingo-port-cancellation-failure></raingo-port-cancellation-failure>
  }
  @case ('request-a-call') {
  <raingo-request-a-call-sheet></raingo-request-a-call-sheet>
  }
  @case ('4G-cancellation') {
  <raingo-cancellation-sheet></raingo-cancellation-sheet>
  }
  @case ('4G-cancellation-failure') {
  <raingo-four-g-cancellation-failure-sheet></raingo-four-g-cancellation-failure-sheet>
  }
  @case ('4G-cancellation-success') {
  <raingo-four-g-cancellation-success-sheet></raingo-four-g-cancellation-success-sheet>
  }
  @case ('4G-revoke-cancellation-success') {
  <raingo-revoke-cancellation-success></raingo-revoke-cancellation-success>
  }
  @case ('4G-revoke-cancellation-failure') {
  <raingo-revoke-cancellation-failure></raingo-revoke-cancellation-failure>
  }
  @case ('upgrade-service') {
  <raingo-upgrade-service></raingo-upgrade-service>
  }
  @case ('5G-cancellation') {
  <raingo-five-g-cancellation-sheet></raingo-five-g-cancellation-sheet>
  }
  @case ('5G-cancellation-success') {
  <raingo-five-g-cancellation-success-sheet></raingo-five-g-cancellation-success-sheet>
  }
  @case ('5G-cancellation-failure') {
  <raingo-five-g-cancellation-failure-sheet></raingo-five-g-cancellation-failure-sheet>
  }
  @case ('5G-revoke-cancellation-success') {
  <raingo-revoke-five-g-cancellation></raingo-revoke-five-g-cancellation>
  }

  }
</div>
}