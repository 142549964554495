<app-bottom-sheet-content-template [fullWidth]="true" [hasNoTitle]="false">
  <h1 Header><strong>heads up!</strong> </h1>

  <div Content>
    <div class="content-container">
      <p>Your number porting to rain has failed. Please consult your old network provider.</p>
    </div>
    <div class="button-container">
      <button (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
