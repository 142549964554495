<app-bottom-sheet-content-template>
  <h1 Header class="port-header">port <strong>cancellation successful</strong></h1>

  <div Content>
    <div class="message-container">
      <p>Your porting request was cancelled successfully.</p>
    </div>

    <div class="button-container">
      <button class="port-again-btn" (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
