export enum NotificationChannels { //TODO The API needs not depend on these seemingly autogenerated primary Keys
    EMAIL = 3,
    WHATSAPP = 5,
    SMS = 4,
    PUSH_NOTIFICATION = 2
}

export enum NotificationCategories { //TODO The API needs not depend on these seemingly autogenerated primary Keys
    PROMO = 3,
    BILLING = 1,
    NETWORK = 2,
    ALL = -1,
    SETTINGS = -2
  }