<app-bottom-sheet-content-template>
  <h1 Header>confirm <b>contact details</b> </h1>

  <div Content>
      <p>
        Please confirm the number we have for you is correct. Alternatively, please update.
      </p>

      <input placeholder="contact number" type="text">

    <div class="button-container">
      <button (click)="onConfirm()">confirm</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
