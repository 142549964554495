import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { IAccountStatusDetail, IBillCyclePayload, IOptInFetchResponse, IPaymentMethod } from '../interfaces/account.interfaces';
import { AuthState } from '../store/Auth/auth.state';
import { ClientModeState } from '../store/Client Mode/client-mode.state';
import { UserUpdateRequest } from '../core/models/user-address.model';
import { NotificationCategories, NotificationChannels } from '../core/enums/account.enum';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  
  constructor(private http: HttpClient, private store: Store) {}

  public getAccountStatus(email: string): Observable<IAccountStatusDetail> {
    const idmToken = this.store.selectSnapshot(AuthState.getTauToken);
    const requestPath = `${environment.ACCOUNT_STATUS}`;
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        apiKey: apiKey,
        Authorization: 'Bearer ' + idmToken,
      }),
    };

    const body = {
      email
    };
    return this.http.post<IAccountStatusDetail>(requestPath, body, httpOptions);
  }

  GetOptInChannelDetails(): Observable<IOptInFetchResponse> {
    const requestPath = `${environment.CONSENT_URL}/rain-web/customer-consent-service/consent/channel`;
    const idmToken = this.store.selectSnapshot(AuthState.getTauToken);
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            apiKey: apiKey,
            Authorization: 'Bearer ' + idmToken
        })
    };

    return this.http.get<IOptInFetchResponse>(requestPath, httpOptions);
  }

  GetOptInCategoryDetails(): Observable<IOptInFetchResponse> {
    const requestPath = `${environment.CONSENT_URL}/rain-web/customer-consent-service/consent/category`;
    const idmToken = this.store.selectSnapshot(AuthState.getTauToken);
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;

    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          apiKey: apiKey,
          Authorization: 'Bearer ' + idmToken
      })
    };

    return this.http.get<IOptInFetchResponse>(requestPath, httpOptions);
  }

  GetActivePaymentMethod(): Observable<IPaymentMethod> {
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    const requestPath = `${environment.FullCIRCLE_API}/billing/payment_method`;

    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            apiKey: apiKey,
            Authorization: userToken!,
            Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
        })
    };

    return this.http.get<IPaymentMethod>(requestPath, httpOptions);
  }

  public UpdatePaymentDate(date: any) {

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;

    const apiKey = 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
    const requestPath = `${environment.AXIOM_IDM_URL}/addpaymentdate?recurringPaymentDate=${date}&futurePaymentDate=${date}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken!,
        apiKey: apiKey,
        Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
      })
    };
    return this.http.put(requestPath, '', httpOptions);
  }

  public SetBillCycleOption(payload: IBillCyclePayload): Observable<any> {
    const url: string = `${environment.RAIN_WEB_API}/billing/set-billcycle`;
    const apiKey = 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: userToken!,
        apiKey: apiKey,
        Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
      })
    };
    return this.http.post(url, payload, httpOptions);

  }

     //TODO remove when axiom endpoint is ready
  public UpdateUserDetailsOld(request: UserUpdateRequest, token?: string): Observable<any> {
      const requestPath = `${environment.FullCIRCLE_API}/users/${request.id}`;

      const apiKey = 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
      let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
      let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
      let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;

      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: userToken!,
              apiKey: apiKey,
              Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
          })
      };

      let dirty = false;

      const user: any = {};

      if (request.address) {
          user.ricaAddress = {
              street_number: request.address.streetNumber,
              street_name: request.address.streetName,
              building_name: request.address.buildingName,
              floor_level: request.address.floorLevel,
              unit_number: request.address.unitNumber,
              suburb: request.address.suburb,
              city: request.address.city,
              province: request.address.province,
              postal_code: request.address.postalCode
          };

          dirty = true;
      }

      if (request.firstName) {
          user.first_name = request.firstName;
          dirty = true;
      }

      if (request.lastName) {
          user.last_name = request.lastName;
          dirty = true;
      }

      if (request.email) {
          user.email = request.email;
          dirty = true;
      }

      if (request.phone) {
          user.phone = request.phone;
          dirty = true;
      }

      if (request.idNumber) {
          user.id_number = request.idNumber;
          dirty = true;
      }

      if (request.company_name) {
          user.company_name = request.company_name;
          dirty = true;
      }

      if (request.company_number) {
          user.company_number = request.company_number;
          dirty = true;
      }
      if (request.vat_number) {
          user.vat_number = request.vat_number;
          dirty = true;
      }

      if (dirty === false) {
          return of({status: 'Nothing to update'});
      }

      const body = {
          user
      };

      return this.http.patch(requestPath, body, httpOptions);
  }

  public SetOptInChannel(accepted: boolean) {

    const requestPath = `${environment.CONSENT_URL}/rain-web/customer-consent-service/consent/channel`;
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;

    const data = {
      channelId: NotificationChannels.WHATSAPP,
      accepted: accepted
    };

    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userToken,
            apiKey: apiKey,
            Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
        })
    };

    return this.http.post(requestPath, data, httpOptions);
}

public SetOptInCategory(accepted: boolean) {

    const requestPath = `${environment.CONSENT_URL}/rain-web/customer-consent-service/consent/category`;
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let userToken = mode === 'consumer' ? this.store.selectSnapshot(AuthState.getTauToken): smeToken;
    const apiKey = `LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH`;

    const data = {
      categoryId: NotificationCategories.PROMO,
      accepted: accepted
  };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
        apiKey: apiKey,
        Key: '6f4962c7-0706-4cf7-b7ce-0b7dc75fcc57'
      })
    };

    return this.http.post(requestPath, data, httpOptions);
}
}
