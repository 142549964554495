<div class="svc-actions-container">
  <ul>
    <li (click)="openAssignSimBottomSheet(tlsLoaded)">
      <raingo-card type="6" >
        <div class="card" [class.is-disabled]="!tlsLoaded">
          <h3><strong>assign</strong> a SIM</h3>
        </div>
      </raingo-card>
    </li>
    <li (click)="buyForOthers()">
      <raingo-card type="6">
        <div class="card" >
          @if(isAuthed$ | async) {
            <h3>buy for <strong>someone else</strong></h3>
          } @else {
            <h3>or <strong>but more</strong></h3>
          }
        </div>
      </raingo-card>
    </li>
  </ul>
</div>
