<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>Oops </strong> </h1>

  <div Content>
    <div class="message-container">
      <p>Your request failed , please try again</p>
    </div>

    <div class="button-container">
      <button (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>