import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageOptionsPageComponent } from './manage-options-page.component';

const routes: Routes = [
  {
    path: '', component: ManageOptionsPageComponent
  },
  {
    path: 'porting',
    loadChildren: () => import('../../pages/number-porting-page/number-porting-page.module').then(m => m.NumberPortingPageModule),
  },
  {
    path: 'sim-swap',
    loadChildren: () => import('../../pages/sim-swap-page/sim-swap-page.module').then(m => m.SimSwapPageModule),
  },
  {
    path: 'cancellation-survey',
    loadChildren: () => import('../../pages/cancellation-survey-page/cancellation-survey-page.module').then(m => m.CancellationSurveyPageModule),
  },
  {
    path: 'upgrade',
    loadChildren: () => import('../../pages/upgrade-service/upgrade-service-page.module').then(m => m.UpgradeServicePageModule),
  },
  {
    path: 'pre-cancel',
    loadChildren: () => import('../../pages/precancel-page/precancel-page.module').then(m => m.PrecancelPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageOptionsPageRoutingModule { }
