<div class="number-porting-page-container">
  @if(!hidePortContent){
  <div class="port-details">
    <ng-container>
      @for (section of sections; track section) {
      <div class="content" [ngClass]="section.className">
        <p class="content-text">
          <span class="sub-title bold-text rain-blue">{{ section.title }}</span>
        </p>
        <ul class="list-items">
          @for(item of section.items; track item) {
          <li>{{ item }}</li>
          }
        </ul>
      </div>
      }
    </ng-container>

    <div class="port-button-container">
      <button class="port-button" (click)="port()">port my number</button>
    </div>

  </div>
  }
  @if(hidePortContent){
  <raingo-number-porting-form></raingo-number-porting-form>
  }
</div>