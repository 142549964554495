<app-bottom-sheet-content-template>
    
    <h1 Header><strong>SIM details</strong></h1>
    
    <div Content>
        @if(isAuthed$ | async) {
            @if(pinPukDetails$ | async; as pinpuk) {
                <div class="info-lockup">
                    <p class="sim-name" *ngIf="!isEditing else showForm"><span>SIM name: <strong>{{selectedSvc.sim_name}}</strong></span>
                        <svg (click)="editInfo()" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4481 0.468L14.5268 1.548C15.158 2.172 15.158 3.188 14.5268 3.812L3.95565 14.396H0.615723V11.052L8.9256 2.724L11.1868 0.468C11.8101 -0.156 12.8249 -0.156 13.4481 0.468ZM2.21378 12.796L3.3404 12.844L11.1868 4.98L10.0602 3.852L2.21378 11.708V12.796Z" fill="#0077C8"/>
                        </svg>
                    </p>
                    <ng-template #showForm>
                        <div class="form-row">
                            <form [formGroup]="form">
                                <input type="text" [formControlName]="'simName'">
                            </form>
                            <svg (click)="saveName()" *ngIf="isDirty" width="12px" height="10px" viewBox="0 0 12 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs></defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polyline id="Path-2" stroke="#0283CF" stroke-width="2" points="1 4.85766816 3.99629512 7.85396329 10.8502584 1"></polyline>
                                </g>
                            </svg>
                        </div>
                    </ng-template>
                    <p>SIM ID number (ICCID): <strong>{{GenerateShortCode(pinpuk.iccid)}}</strong></p>
                    <p>PIN: <strong>{{pinpuk.pin}}</strong></p>
                    <p>PUK: <strong>{{pinpuk.puk}}</strong></p>

                    <!-- <div *ngIf="selectedSvc.product.category === '4G'" class="int-dialing-container">
                        <span>International calling</span>
                        <raingo-toggle-button [checked]="intDialingEnabled" (onToggle)="toggleIntDialing($event)"></raingo-toggle-button>
                    </div> -->
                </div>
        } @else {
            <div class="info-lockup">
                <p>We were unable to get you pin puk details for this sim.</p>
            </div>
        }
        } @else {
            <div class="info-lockup">
                <p>Please sign in to see your sim details.</p>
            </div>
        }
            
            <div class="button-container" [class.is-editing]="isDirty">
                @if(!isDirty) {
                    <button class="solid" (click)="close()">close</button>
                } @else {
                    <button class="outlined cancel-btn" (click)="close()">cancel</button>
                    <button (click)="saveName()" class="solid" (click)="close()">save</button>
                }
            </div>
        </div>
    
    </app-bottom-sheet-content-template>