import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageOptionsPageRoutingModule } from './manage-options-page-routing.module';
import { ManageOptionsPageComponent } from './manage-options-page.component';


@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    ManageOptionsPageRoutingModule,
    ManageOptionsPageComponent,
  ]
})
export class ManageOptionsPageModule { }
