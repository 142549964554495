import { Injectable } from '@angular/core';

const CONSTANTS = {
  PREPAID_PRODUCT_ID: 'a911af35-30d0-4e0d-a407-633d27ad8f3a',
  CUSTOMER_A_PRODUCT_ID: '89dd4305-8f41-4d86-a097-416e1046e865',
  CUSTOMER_B_PRODUCT_ID: '12bf79dc-f05b-46cf-a91c-d28b79ed84e1',
};

@Injectable({
  providedIn: 'root',
})
export class FormatWalletDataService {
  formatData(bytes: number) {
    if (!bytes) return '0';
    const formattedData = (Number(bytes) / 1024).toFixed(2) ?? 0;

    return formattedData;
  }

  formatCombinedData(buckets: any[], key: string, toType: 'mb' | 'gb' = 'mb') {
    if (!buckets) return '0';
    const gbFormulae = 9.3132257461548e-10;

    let total = 0;
    buckets.forEach((b) => (total = total + b[key]));
    if (toType === 'gb') {
      return (Number(total) * gbFormulae).toFixed(2) ?? 0;
    }


    return (Number(total) / 1024).toFixed(2) ?? 0;
  }

  formatCombinedSMS(buckets: any[], key: string) {
    if (!buckets) return '0';

    let total = 0;
    buckets.forEach((b) => (total = total + b[key]));

    return String(total);
  }

  formatVoice(minutes1: number, minutes2: number, minutes3?: number) {
    const minutesData1 = Number(minutes1) ? Number(minutes1) : 0;
    const minutesData2 = Number(minutes2) ? Number(minutes2) : 0;
    const minutesData3 = Number(minutes3) ? Number(minutes3) : 0;

    const minsAndSecs = String(
      Number(minutesData1 + minutesData2 + (minutesData3 ?? 0))
    ).split('.');
    const minutes = minsAndSecs[0];
    let seconds: string;
    if (minsAndSecs[1]) {
      seconds = isNaN(Math.round(Number(`0.${minsAndSecs[1]}`) * 60))
        ? '0'
        : String(Math.round(Number(`0.${minsAndSecs[1]}`) * 60));
    } else {
      seconds = '0';
    }

    return { minutes, seconds };
  }

  formatCombinedVoice(
    buckets: any[],
    key: string
  ): { minutes: string; seconds: string } {
    if (!buckets) return { minutes: '0', seconds: '0' };

    let total = 0;

    buckets.forEach((b) => (total = total + b[key]));
    if (key === 'allocatedValue') {
      total = total / 60;
    }
    const minsAndSecs = String(Number(total)).split('.');

    const minutes = minsAndSecs[0];
    let seconds: string;
    if (minsAndSecs[1]) {
      seconds = isNaN(Math.round(Number(`0.${minsAndSecs[1]}`) * 60))
        ? '0'
        : String(Math.round(Number(`0.${minsAndSecs[1]}`) * 60));
    } else {
      seconds = '0';
    }
    return { minutes, seconds };
  }
}
