import { Utils } from "src/app/utils";

export class FetchPromoDetails {
    static readonly type = Utils.Helpers.Type('[Firestore: Fetch] Fetch promo details');
}

export class FetchWebsiteConfig {
    static readonly type = Utils.Helpers.Type('[Firestore: Fetch] Fetch website config details');
}

export class FetchLevelsConfig {
    static readonly type = Utils.Helpers.Type('[Firestore: Fetch] Fetch levels config details');

}
export class FetchBillCycleDates {
    static readonly type = Utils.Helpers.Type('[Firestore: Fetch] Fetch bill cycle dates');
}