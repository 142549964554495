import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-port-request-failure-sheet',
  templateUrl: './port-request-failure-sheet.component.html',
  styleUrl: './port-request-failure-sheet.component.scss'
})
export class PortRequestFailureSheetComponent {

  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch(new CloseBottomSheet())
  }
}
