import { Utils } from "@Utils";
import { IOptInFetchResponse, IPaymentMethod, IUserAddress } from "src/app/interfaces/account.interfaces";

export class FetcOptInValues {
    static readonly type = Utils.Helpers.Type('[account: GET] Get opt in values');
}

export class FetcOptInValuesSuccess {
    static readonly type = Utils.Helpers.Type('[account: GET] Get opt in values success');
    constructor(public readonly payload: IOptInFetchResponse){}
}

export class FetcOptInValuesFail {
    static readonly type = Utils.Helpers.Type('[account: GET] Get opt in values fail');
    constructor(public readonly payload: any){}
}

export class FetcOptInCategroryValues {
    static readonly type = Utils.Helpers.Type('[account: GET] Get opt in categrory values');
}

export class FetchActivePaymentMethod {
    static readonly type = Utils.Helpers.Type('[account: payment-method] Fetch active payment method');
}
export class FetchActivePaymentMethodSuccess {
    static readonly type = Utils.Helpers.Type('[account: payment-method] Fetch active payment method success');
    constructor(public readonly payload: IPaymentMethod){}
}
export class FetchActivePaymentMethodFail {
    static readonly type = Utils.Helpers.Type('[account: payment-method] Fetch active payment method fail');
    constructor(public readonly payload: any){}
}

export class UpdatePaymentDate {
    static readonly type = Utils.Helpers.Type('[account: payment-date] Update payment date');
    constructor(public readonly payload: {id: string, end_day: string | number, start_day: number}){}
}
export class UpdatePaymentDateSuccess {
    static readonly type = Utils.Helpers.Type('[account: payment-date] Update payment date success');
    constructor(public readonly payload: string){}
}
export class UpdatePaymentDateFail {
    static readonly type = Utils.Helpers.Type('[account: payment-date] Update payment date fail');
    constructor(public readonly payload: any){}
}

export class UpdateBillCycleDateSuccess {
    static readonly type = Utils.Helpers.Type('[account: payment-date] Update bill cycle date success');
    constructor(public readonly payload: any){}
}
export class UpdateBillCycleDateFail {
    static readonly type = Utils.Helpers.Type('[account: payment-date] Update bill cycle date fail');
    constructor(public readonly payload: any){}
}

export class UpdateAccountDetails {
    static readonly type = Utils.Helpers.Type('[account: update] Update account details');
    constructor(public readonly payload: IUserAddress){}
}
export class UpdateAccountDetailsSuccess {
    static readonly type = Utils.Helpers.Type('[account: update] Update account details success');
    constructor(public readonly payload: any){}
}
export class UpdateAccountDetailsFail {
    static readonly type = Utils.Helpers.Type('[account: update] Update account details fail');
    constructor(public readonly payload: any){}

}
export class SetOptInChannel {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification channel opt-in');
    constructor(public readonly payload: boolean){}
}
export class SetOptInChannelSuccess {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification channel opt-in success');
    constructor(public readonly payload: any){}
}
export class SetOptInChannelFail {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification channel opt-in fail');
    constructor(public readonly payload: any){}
}

export class SetOptInCategory {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification category opt-in');
    constructor(public readonly payload: boolean){}
}
export class SetOptInCategorySuccess {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification category opt-in success');
    constructor(public readonly payload: any){}
}
export class SetOptInCategoryFail {
    static readonly type = Utils.Helpers.Type('[account: notifications] Set notification category opt-in fail');
    constructor(public readonly payload: any){}
}
