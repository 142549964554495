<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>hey it’s good to have you back</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>Your request cancellation will be reversed right now.
        Just so you know, cancellation reversals can only happen while your SIM is still scheduled for cancellation, not
        once it has been cancelled.</p>
    </div>

    <div class="button-container">
      <button class="cancel-btn" (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>