
export interface PortMyNumberInfo {
  notes: string[];
  disclaimer: string[];
}

export const portMyNumberInfo: PortMyNumberInfo = {
  notes: [
    "Porting may take up to 24 hours to complete",
    "You'll receive an OTP from your current service provider after submitting your details",
    "Keep your old SIM in your phone to receive the OTP and validate the port",
    "If the OTP is not submitted within 4 hours, your port request will be rejected",
    "Once you have ported successfully, you won't be able to port to a different network operator within 30 days from requested port date.",
  ],
  disclaimer: [
    "Benefits (airtime/minutes, data, SMS), unused credits or 3rd party services on your old network will end.",
    "Information stored on the SIM (contacts) may be lost.",
    "All costs incurred are for your account."
  ]
};


export enum PortStatus {
  PORT_REQUEST_INITIALIZE = "PORT_REQUEST_INITIALIZE",
  PORT_REQUEST = "PORT_REQUEST",
  PORT_RESPONSE = "PORT_RESPONSE",
  PORT_NOTIFICATION = "PORT_NOTIFICATION",
  PORT_ACTIVATED_PENDING = "PORT_ACTIVATED_PENDING",
  PORT_ACTIVATED = "PORT_ACTIVATED",
  PORT_DEACTIVATED = "PORT_DEACTIVATED",
  PORT_PROCESS_CANCELLED = "PORT_PROCESS_CANCELLED",
  PORT_REQUEST_REJECTED_MESSAGE_SENT = "PORT_REQUEST_REJECTED_MESSAGE_SENT",
  PORT_COMPLETED = "PORT_COMPLETED",// completed - cant port again
  PORT_REQUEST_REJECTED = "PORT_REQUEST_REJECTED",// failed , can  port again
  PORT_PROCESS_REVERSED = "PORT_PROCESS_REVERSED" //  completed
}

export function mapPortStatus(status: string): 'pending' | 'failed' | 'complete' | 'cancel' | '' {

  switch (status) {
    case PortStatus.PORT_REQUEST_INITIALIZE:
    case PortStatus.PORT_REQUEST:
    case PortStatus.PORT_RESPONSE:
    case PortStatus.PORT_NOTIFICATION:
      return 'pending';

    case PortStatus.PORT_ACTIVATED_PENDING:
      return 'cancel'

    case PortStatus.PORT_REQUEST_REJECTED_MESSAGE_SENT:
    case PortStatus.PORT_REQUEST_REJECTED:
      return 'failed';

    case PortStatus.PORT_ACTIVATED:
    case PortStatus.PORT_COMPLETED:
    case PortStatus.PORT_PROCESS_REVERSED:
    case PortStatus.PORT_PROCESS_CANCELLED:
    case PortStatus.PORT_DEACTIVATED:
      return 'complete';

    default:
      return '';
  }
}
