import { Component } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-cancellation-sheet',
  templateUrl: './cancellation-sheet.component.html',
  styleUrl: './cancellation-sheet.component.scss'
})
export class CancellationSheetComponent {
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id

  date = ''

  constructor(
    private store: Store
  ) { }
  cancel() {
    this.store.dispatch(new CloseBottomSheet())
    this.store.dispatch(new Navigate([`services/${this.serviceId}/manage/cancellation-survey`]))
  }

  close() {
    this.store.dispatch(new CloseBottomSheet())
  }

}
