import { State, Selector, Store, Action, StateContext, createSelector } from '@ngxs/store';
import { Injectable, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import * as dashboardActions from '../actions/dashboard.actions';
import { CoreState } from 'src/app/store/Core/core.state';
import { IServicesGrouping } from 'src/app/interfaces/services.interface';
import { Utils } from '@Utils';
import { FiveGPrepaidService, ServiceObject } from 'src/app/interfaces/interfaces';
import { IHashMap } from 'src/app/interfaces/hash-map.inteface';
import { IDeviceInfo } from 'src/app/interfaces/device-info.interface';
import { CoreActions } from 'src/app/store/Core/actions';
import { DeviceService } from 'src/app/core/services/device.service';
import { BACK } from 'src/app/store/Core/actions/router.actions';
import { ClearLevelsConfig, ClearMigrations, DecrementLines, IncrementLines, InitMigrations, InitMigrationsFail, InitMigrationsSuccess, NextStep, SetCurrentLevel, SetSelectedLevel, SetStep } from '../actions/level-up.actions';
import { ILevel, ILevelUpTotal } from '../../interfaces/level.interface';

import { Navigate } from '@ngxs/router-plugin';
import { CatelogueState } from 'src/app/store/Catelogue/catelogue.state';
import { FetchPinPukDetails, FetchPinPukDetailsFail, FetchPinPukDetailsSuccess } from '../actions/pin-puk.actions';
import { ResourceManagerService } from 'src/app/core/services/resource-manager.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { FirestoreState } from 'src/app/store/FireStore/firestore.state';
import { ToggleIntDialingSuccess } from 'src/app/store/Core/actions/service.actions';
import { SignOut } from 'src/app/store/Auth/actions/auth.actions';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { IAddTicketRequest } from 'src/app/interfaces/ticket.interface';
import { TicketsService } from 'src/app/core/services/ticket.service';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';

export interface DashboardStateModel {
  selectedSVC: ServiceObject | null,
  deviceMap: IHashMap<IDeviceInfo> | null,
  pageTitle: string | null,
  previousPageTitle: string | null,
  level_up_config: {
    step: 1 | 2 | 3 | null,
    selectedLevel: ILevel | null,
    currentLevel: ILevel | null,
  },
  lineSelectorConfig: {
    min: number,
    current: number,
    max: number | null
  },
  pin_puk_details: {
    pin: string,
    puk: string,
    iccid: string
  } | null,
  migration: {
    isMigrating: boolean,
    success: boolean,
    error: boolean,
    err_msg?: string | null,
    config: {
      type: 'up' | 'down',
      amount: number
    } | null
  },
  speed_up_config: {
    canChangeSpeed: boolean,
    currentSpeed: string,
    selectedSpeed: any | null
  } | null
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    selectedSVC: null,
    deviceMap: null,
    pageTitle: null,
    previousPageTitle: null,
    level_up_config: {
      step: 1,
      selectedLevel: null,
      currentLevel: null,
    },
    lineSelectorConfig: {
      min: 1,
      current: 1,
      max: 4
    },
    pin_puk_details: null,
    migration: {
      isMigrating: false,
      success: false,
      error: false,
      err_msg: null,
      config: null
    },
    speed_up_config: null,
  }
})
@Injectable()
export class DashboardState {
  constructor(
    private store: Store,
    private router: Router,
    private readonly _deviceDetails: DeviceService,
    private readonly _resourceManagerSvc: ResourceManagerService,
    private readonly _servicesSvc: ServicesService,
    private readonly ticketSvc: TicketsService
  ) { }

  @Selector([forwardRef(() => CoreState.getServices)])
  static GetServiceDeviceDetails(state: DashboardStateModel, serviceGroups: IServicesGrouping) {
    const services = Utils.Helpers.FlattenHashMapArrays(serviceGroups as any) as ServiceObject[];
    const selectedSVC = services.find((svc) => svc.id === state.selectedSVC!.id);

    if (selectedSVC) return selectedSVC;

    return;
  }

  static GetSVCDeviceInfo(id: string) {
    return createSelector([DashboardState], (state: any) => {
      return state.deviceMap[id];
    });
  }

  @Selector()
  static GetSelectedService(state: DashboardStateModel) {
    console.log({selectedSVC:state.selectedSVC});

    return Object.assign({ device_details: state.deviceMap![state.selectedSVC!.id] }, state.selectedSVC);
  }

  @Selector()
  static GetSpeedUpConfig(state: DashboardStateModel) {
    return state.speed_up_config;
  }
  @Selector()
  static GetPortStatus(state: DashboardStateModel) {
    return state.selectedSVC?.port_status;
  }

  @Selector()
  static GetPageTitle(state: DashboardStateModel) {
    if (state.pageTitle) return state.pageTitle;
  }

  @Selector()
  static GetLevelUpConfig(state: DashboardStateModel) {
    if (state.level_up_config) return state.level_up_config;
  }

  @Selector()
  static GetLineSelectorConfig(state: DashboardStateModel) {
    if (state.lineSelectorConfig) return state.lineSelectorConfig;
  }

  @Selector()
  static GetSelectedLevel(state: DashboardStateModel) {
    if (state.level_up_config && state.level_up_config.selectedLevel) return state.level_up_config.selectedLevel;
  }

  @Selector()
  static GetCurrentLevel(state: DashboardStateModel) {
    if (state.level_up_config && state.level_up_config.currentLevel) return state.level_up_config.currentLevel;
  }

  @Selector()
  static GetPinPukDetails(state: DashboardStateModel) {
    if (state.pin_puk_details) return state.pin_puk_details;
  }

  @Selector()
  static GetLevelUpTotal(state: DashboardStateModel): ILevelUpTotal {
    const selectedLevel = state.level_up_config.selectedLevel;
    const numLines = state.lineSelectorConfig.current;
    const currentLevel = state.level_up_config.currentLevel as ILevel;
    if (currentLevel?.id === selectedLevel?.id) {
      return {
        level: selectedLevel?.level,
        lines: numLines,
        total: 0,
        current: currentLevel,
        selectedLevel: selectedLevel
      }
    } else if (currentLevel?.level! > selectedLevel!.level) {
      return {
        level: selectedLevel!.level,
        lines: numLines,
        total: selectedLevel!.price,
        current: currentLevel,
        selectedLevel: selectedLevel!
      }
    }
    return {
      level: selectedLevel!.level,
      lines: numLines,
      total: selectedLevel!.price,
      current: currentLevel,
      selectedLevel: selectedLevel!
    }
  }

  @Selector()
  static GetMigrationsConfig(state: DashboardStateModel) {
    if (state.migration) return state.migration;
  }

  @Action(dashboardActions.SetSelectedService)
  SetSelectedService(ctx: StateContext<DashboardStateModel>, action: dashboardActions.SetSelectedService) {
    const state = ctx.getState();
    const svc = action.payload;
    const product = this.store.selectSnapshot(CatelogueState.GetProductById(svc.product_id));
    const service = Object.assign({ product }, svc);
    const groupedService = service.parent_service_id ? this.store.selectSnapshot(CoreState.GetGroupedServices)![service.parent_service_id] : this.store.selectSnapshot(CoreState.GetGroupedServices)![service.id];
    ctx.patchState({
      selectedSVC: service,
      previousPageTitle: service.sim_name,
      pageTitle: service.sim_name,
      lineSelectorConfig: {
        min: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
        current: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
        max: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
      },
      pin_puk_details: null
    });

    if (service.policy) {
      ctx.patchState({
        speed_up_config: {
          canChangeSpeed: true,
          currentSpeed: service.policy.policy,
          selectedSpeed: null
        }
      })
    }
    const isRainMobile = Boolean(service.product?.name === 'standalone 4G generic consumer SIM AU');
    let currentLevel

    if (isRainMobile) {
      currentLevel = this.determineLevel(service);
    } else {
      currentLevel = service.product?.config?.level ? service.product?.config?.level : 0;
    }

    ctx.dispatch([new SetCurrentLevel(currentLevel), new FetchPinPukDetails(service.iccid)]);

    if (service.product?.category === '5G' && service.is5GPrepaid) return this.router.navigate([`services/${service.id}/wifi`]);
    return this.router.navigate([`services/${service.id}`]);
  }

  @Action(dashboardActions.SetSelectedServiceById)
  SetSelectedServiceById(ctx: StateContext<DashboardStateModel>, action: dashboardActions.SetSelectedServiceById) {
    const id = action.payload;
    const services = this.store.selectSnapshot(CoreState.getServices);
    const Prepaid = this.store.selectSnapshot(CoreState.Get5GPrepaid);
    const state = ctx.getState();

    if (services !== null) {
      const svc = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(services as any)?.find((s) => s.id === id)!;
      const product = this.store.selectSnapshot(CatelogueState.GetProductById(svc?.product_id));
      let service = Object.assign({ product }, svc);
      if (Prepaid !== null && Prepaid[0] as FiveGPrepaidService) {
        if (svc.id === Prepaid[0].id || svc.parent_service_id === Prepaid[0].id) {
          service = Object.assign({ is5GPrepaid: true }, service)
        }
      }

      const groupedService = service?.parent_service_id ? this.store.selectSnapshot(CoreState.GetGroupedServices)![service.parent_service_id] : this.store.selectSnapshot(CoreState.GetGroupedServices)[service?.id];

      if (svc) {
        ctx.patchState({
          selectedSVC: service,
          pageTitle: service.sim_name,
          lineSelectorConfig: {
            min: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
            current: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
            max: groupedService.isParentSvc ? Object.keys(groupedService.subServices).length : 1,
          },
          pin_puk_details: null
        });

        if (service.policy) {
          ctx.patchState({
            speed_up_config: {
              canChangeSpeed: true,
              currentSpeed: service.policy.policy,
              selectedSpeed: null
            }
          })
        }
        const isRainMobile = Boolean(service?.product?.name === 'standalone 4G generic consumer SIM AU');
        let currentLevel

        if (isRainMobile) {
          currentLevel = this.determineLevel(service);

        } else {
          currentLevel = service.product?.config?.level ? service.product?.config?.level : 0;
        }

        ctx.dispatch([new SetCurrentLevel(currentLevel), new FetchPinPukDetails(service.iccid)]);
      }
    }
  }

  @Action(CoreActions.FetchServicesSuccess)
  GetAllServiceDeviceInfo(ctx: StateContext<DashboardStateModel>, action: CoreActions.FetchServicesSuccess) {
    const services = Utils.Helpers.FlattenHashMapArrays<ServiceObject>(action.payload as any);

    return services.forEach((svc) => ctx.dispatch(new dashboardActions.GetDeviceInfo(svc)))
  }

  @Action(dashboardActions.GetDeviceInfo)
  GetDeviceInfo(ctx: StateContext<DashboardStateModel>, action: dashboardActions.GetDeviceInfo) {
    const state = ctx.getState();
    const svc = action.payload;
    const hMap = state.deviceMap || {};
    if (hMap[svc.id]) return;

    this._deviceDetails.GetDeviceDetails(svc.msisdn).subscribe(
      {
        next: (res) => ctx.dispatch(new dashboardActions.GetDeviceInfoSuccess({ id: svc.id, result: res })),
        error: (err) => ctx.dispatch(new dashboardActions.GetDeviceInfoFail(err))
      }
    )
  }

  @Action(dashboardActions.GetDeviceInfoSuccess)
  GetDeviceInfoSuccess(ctx: StateContext<DashboardStateModel>, action: dashboardActions.GetDeviceInfoSuccess) {
    const state = ctx.getState();
    let hMap = state.deviceMap || {};
    const payload = action.payload;

    if (payload.result.error_description !== "success") return;

    hMap = Object.assign({ [payload.id]: payload.result.response }, hMap);
    ctx.patchState({
      deviceMap: hMap
    });
  }

  @Action(dashboardActions.SetPageTitle)
  SetPageTitle(ctx: StateContext<DashboardStateModel>, action: dashboardActions.SetPageTitle) {
    const state = ctx.getState();
    const payload = action.payload;

    ctx.patchState({
      previousPageTitle: state.pageTitle,
      pageTitle: payload,
    });
  }

  @Action(BACK)
  onBack(ctx: StateContext<DashboardStateModel>, action: BACK) {
    const state = ctx.getState();

    ctx.patchState({
      previousPageTitle: state.pageTitle,
      pageTitle: state.previousPageTitle,
    });
  }

  @Action(SetSelectedLevel)
  SetSelectedLevel(ctx: StateContext<DashboardStateModel>, action: SetSelectedLevel) {
    const state = ctx.getState();
    const level = action.payload;
    ctx.patchState({
      level_up_config: {
        ...state.level_up_config,
        selectedLevel: level
      }
    });
  }

  @Action(IncrementLines)
  IncrementLines(ctx: StateContext<DashboardStateModel>, action: IncrementLines) {
    const state = ctx.getState();
    const minMax = state.lineSelectorConfig as {
      min: number,
      current: number,
      max: number
    }

    if (minMax) {
      if (minMax.current < minMax.max) {
        ctx.patchState({
          lineSelectorConfig: {
            ...state.lineSelectorConfig,
            current: minMax.current + 1
          }
        });
      }
    }
  }

  @Action(DecrementLines)
  DecrementLines(ctx: StateContext<DashboardStateModel>, action: DecrementLines) {
    const state = ctx.getState();
    const minMax = state.lineSelectorConfig as {
      min: number,
      current: number,
      max: number
    }

    if (minMax) {
      if (minMax.current > minMax.min) {
        ctx.patchState({
          lineSelectorConfig: {
            ...state.lineSelectorConfig,
            current: minMax.current - 1
          }
        });
      }
    }
  }

  @Action(NextStep)
  NextStep(ctx: StateContext<DashboardStateModel>, action: NextStep) {
    const state = ctx.getState();
    const currStep = state.level_up_config.step;

    switch (currStep) {
      // single level up
      case 1: {
        const path = window.location.pathname;
        return ctx.dispatch(new Navigate([`${path}/sim-select`]));
      }

      // multiline level up
      case 2: {
        const path = window.location.pathname;
        return ctx.dispatch(new Navigate([`${path}/level-up`]));
      }

      case 3: {
        //make purchase api call
        return;
      }

      default:
        break;
    }
  }

  @Action(SetStep)
  SetStep(ctx: StateContext<DashboardStateModel>, action: SetStep) {
    const state = ctx.getState();
    const step: any = action.step;

    ctx.patchState({
      level_up_config: {
        ...state.level_up_config,
        step: step
      }
    });

    if (step === 1) {
      // ctx.patchState({
      //     lineSelectorConfig: {
      //         min: 1,
      //         max: 1,
      //         current: 1
      //     }
      // });
    }
    if (step === 2) {
      ctx.dispatch(new NextStep());
    }
  }

  @Action(SetCurrentLevel)
  SetCurrentLevel(ctx: StateContext<DashboardStateModel>, action: SetCurrentLevel) {
    const state = ctx.getState();
    let levelNum = action.payload;

    const LevelsConfig = this.store.selectSnapshot(FirestoreState.GetLevelsConfig)!
    const isRainMobile = Boolean(state.selectedSVC?.product?.name === 'standalone 4G generic consumer SIM AU');
    let levels
    if (isRainMobile) {
      levels = LevelsConfig['mobile']
    } else {
      const mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
      levels = mode === 'sme' ? LevelsConfig['sme'] : LevelsConfig['rainOne'];
    }

    const level = levels.find((l) => l.level == levelNum)!;

    ctx.patchState({
      level_up_config: {
        ...state.level_up_config,
        selectedLevel: level,
        currentLevel: level
      }
    });
  }

  @Action(FetchPinPukDetails)
  FetchPinPukDetails(ctx: StateContext<DashboardStateModel>, action: FetchPinPukDetails) {
    const msisdn = action.payload;
    if (!msisdn) return;
    this._resourceManagerSvc.GetPinPuk(msisdn).subscribe({
      next: (res) => ctx.dispatch(new FetchPinPukDetailsSuccess(res)),
      error: (err) => ctx.dispatch(new FetchPinPukDetailsFail(err))
    })
  }

  @Action(FetchPinPukDetailsSuccess)
  FetchPinPukDetailsSuccess(ctx: StateContext<DashboardStateModel>, action: FetchPinPukDetailsSuccess) {
    const response = action.payload;

    ctx.patchState({
      pin_puk_details: {
        pin: response.pin2,
        puk: response.puk2,
        iccid: response.iccid
      }
    });
  }
  @Action(FetchPinPukDetailsFail)
  FetchPinPukDetailsFail(ctx: StateContext<DashboardStateModel>, action: FetchPinPukDetailsFail) {

    ctx.patchState({
      pin_puk_details: null
    });
  }

  @Action(InitMigrations)
  InitMigrations(ctx: StateContext<DashboardStateModel>, action: InitMigrations) {
    const state = ctx.getState();
    const sSvc = state.selectedSVC!;
    const sLevel = state.level_up_config.selectedLevel!;
    const currentLevel = state.level_up_config.currentLevel!;
    const isRainMobile = Boolean(sSvc.product?.name === 'standalone 4G generic consumer SIM AU');
    let option;
    if (isRainMobile) {
      option = sSvc.product!.config.migration![sLevel.level] || sSvc.product!.config.migrations[sLevel.level] as { name: string, id: string, config: any };
    } else {
      const parentProduct = this.store.selectSnapshot(CoreState.getServicebyID(sSvc.parent_service_id!))!;
      if (currentLevel.level > sLevel.level) {
        option = parentProduct.serviceObject.product!.config.migration![sLevel.level] || parentProduct.serviceObject.product!.config.migrations[sLevel.level] as { name: string, id: string, config: any };
      } else {
        option = parentProduct.serviceObject.product!.config.migration![sLevel.level - 1];
      }
    }

    const type: 'up' | 'down' = currentLevel.level < sLevel.level ? 'up' : 'down';
    const amount = type === 'down' ? (currentLevel.price - sLevel.price) : (sLevel.price - currentLevel.price);

    const successSheetVars = {
      type,
      amount
    }
    ctx.patchState({
      migration: {
        config: successSheetVars,
        isMigrating: true,
        success: false,
        error: false,
        err_msg: null
      }
    });

    const payload: { serviceId: string; product_id: string, msisdn: string } = {
      serviceId: isRainMobile ? sSvc.id : sSvc.parent_service_id!,
      product_id: option.id,
      msisdn: sSvc.msisdn
    }

    this._servicesSvc.levelMigrate(payload, isRainMobile).subscribe({
      next: (res) => {
        if (res) {
          ctx.dispatch([new InitMigrationsSuccess({ res: res, config: successSheetVars }), new ShowBottomSheet('level-up-success')]);
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err) {
          if (err.status === 200 && err.error.text.includes('Service Leveled Up')) {
            return ctx.dispatch([new InitMigrationsSuccess({ res: err, config: successSheetVars }), new ShowBottomSheet('level-up-success')]);
          }

          const userDetails = this.store.selectSnapshot(AuthState.getAxiomUser)!;
          const fullName = userDetails.individualIdentified!.name;
          let ticketRequest: IAddTicketRequest = {
            name: fullName,
            email: userDetails.loginId!,
            subject: `rainOne migration`,
            referenceId: userDetails.loginId!,
            body: {
              msisdn: sSvc.msisdn
            },
            captcha: '-'
          };

          this.ticketSvc.addTicket(ticketRequest).subscribe({
            next: (res) => {
              if (res) {
                this.store.dispatch(new ShowBottomSheet('level-change-expired'));
              }
            }
          });
          ctx.dispatch(new InitMigrationsFail(err.error?.message || err.error?.errors[0]));
        }
      }
    })
  }

  @Action(InitMigrationsSuccess)
  InitMigrationsSuccess(ctx: StateContext<DashboardStateModel>, action: InitMigrationsSuccess) {
    const state = ctx.getState();
    const { res, config } = action.payload;
    ctx.dispatch([new CoreActions.FetchServices('InitMigrationsSuccess'), new Navigate(['/services'])]);
    ctx.patchState({
      migration: {
        config: config,
        isMigrating: false,
        success: true,
        error: false,
        err_msg: null
      }
    });

  }

  @Action(InitMigrationsFail)
  InitMigrationsFail(ctx: StateContext<DashboardStateModel>, action: InitMigrationsFail) {
    const err = action.payload;
    ctx.patchState({
      migration: {
        isMigrating: false,
        success: false,
        error: true,
        err_msg: err,
        config: null
      }
    });
  }

  @Action(ClearMigrations)
  ClearMigrations(ctx: StateContext<DashboardStateModel>, action: ClearMigrations) {
    ctx.patchState({
      migration: {
        isMigrating: false,
        success: false,
        error: false,
        err_msg: null,
        config: null
      }
    });
  }

  @Action(ClearLevelsConfig)
  ClearLevelsConfig(ctx: StateContext<DashboardStateModel>, action: ClearLevelsConfig) {
    ctx.patchState({
      level_up_config: {
        step: 1,
        selectedLevel: null,
        currentLevel: null,
      },
    });
  }

  @Action(dashboardActions.UnselectSelectedSvc)
  UnselectSelectedSvc(ctx: StateContext<DashboardStateModel>, action: dashboardActions.UnselectSelectedSvc) {
    ctx.patchState({
      selectedSVC: null,
      previousPageTitle: null,
      pageTitle: null
    });
  }

  @Action(dashboardActions.SetSelectedSpeedChange)
  SetSelectedSpeedChange(ctx: StateContext<DashboardStateModel>, action: dashboardActions.SetSelectedSpeedChange) {
    const state = ctx.getState();
    const selectedSpeed: string = action.payload!;

    ctx.patchState({
      speed_up_config: {
        ...state.speed_up_config!,
        selectedSpeed: selectedSpeed as string
      }
    });
  }

  @Action(ToggleIntDialingSuccess)
  onFetchProductsSuccess(ctx: StateContext<DashboardStateModel>, action: ToggleIntDialingSuccess) {
    const state = ctx.getState();
    const payload = action.payload;
    const international_access = payload.enabled ? 'enabled' : 'disabled';
    const selectedService = state.selectedSVC;
    const updatedSvc = { ...selectedService, international_access } as any;

    ctx.patchState({
      selectedSVC: updatedSvc
    });
  }

  @Action(SignOut)
  onSignOut(ctx: StateContext<DashboardStateModel>, action: SignOut) {

    ctx.setState({
      selectedSVC: null,
      deviceMap: null,
      pageTitle: null,
      previousPageTitle: null,
      level_up_config: {
        step: 1,
        selectedLevel: null,
        currentLevel: null,
      },
      lineSelectorConfig: {
        min: 1,
        current: 1,
        max: 4
      },
      pin_puk_details: null,
      migration: {
        isMigrating: false,
        success: false,
        error: false,
        err_msg: null,
        config: null
      },
      speed_up_config: null,
    });
  }

  public determineLevel(service: ServiceObject) {
    if (service.product!.config.level) {
      return service.product!.config.level
    }
    const isRainMobile = service.product!.name.includes('standalone 4G');

    if (isRainMobile) {
      const bucket = this.store.selectSnapshot(CoreState.getBucketbyId(service.id))[0];
      return bucket.vas?.level
    } else {
      if (isRainMobile) return '0';
    }
  }
}
