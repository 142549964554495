<div class="card-container" [class.is-prepaid]="is5GPrepaid">
  <!-- @if (type === '1' || type === '7' || type === '8') {
    <div class="border"></div>
  } -->
  @if (type === '9') {
    <div class="spacer"></div>
  }
  <div class="card-content has-radius">
    <ng-content></ng-content>
  </div>
</div>