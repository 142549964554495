import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './pages/home/home.module';
import { NgxsModule } from '@ngxs/store';
import { UtilsModule } from './utils/utils.module';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { CoreState } from './store/Core/core.state';
import { TopupState } from './store/topup/topup.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { PaymentOptionsModule } from './pages/payment-options/payment-options.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomSheetState } from './store/BottomSheet/bottom-sheet.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductState } from './store/Product/state/product.state';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ErrorModule } from './pages/error/error.module';
import { FirestoreState } from './store/FireStore/firestore.state';
import { PaymentState } from './store/Payment/payment.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { services } from './services';
import { ForgotPasswordState } from './store/ForgotPassword/forgot-password.state';
import { ClientModeState } from './store/Client Mode/client-mode.state';
import { AssignSimState } from './store/AssignSim/assign-sim.state';
import { AuthState } from './store/Auth/auth.state';
import { PWAState } from './store/pwa/state/pwa.store';

import { ServiceWorkerModule } from '@angular/service-worker';
import { Params, RouterStateSnapshot } from '@angular/router';
import { CoreModule } from './core/core.module';
import { CatelogueState } from './store/Catelogue/catelogue.state';
import { Loader } from '@googlemaps/js-api-loader';
import { AnalyticsState } from './core/store/analytics.state';
import { ManageOptionsPageModule } from './shared/manage-options-page/manage-options-page.module';
import { NumberPortingPageModule } from './pages/number-porting-page/number-porting-page.module';
import { NumberPortingState } from './store/NumberPorting/number-porting.state';
import { CancellationsState } from './store/Cancellations/cancellations.state';

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
}

// Map the router snapshot to { url, params, queryParams }
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams }
    } = routerState;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params } = route;

    return { url, params, queryParams };
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    UtilsModule,
    SharedModule,
    HomeModule,
    ErrorModule,
    HttpClientModule,
    PaymentOptionsModule,
    ManageOptionsPageModule,
    NumberPortingPageModule,
    NgxsModule.forRoot(
      [
        BottomSheetState,
        AuthState,
        CoreState,
        TopupState,
        ProductState,
        FirestoreState,
        PaymentState,
        ForgotPasswordState,
        ClientModeState,
        AssignSimState,
        PWAState,
        CatelogueState,
        AnalyticsState,
        NumberPortingState,
        CancellationsState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'catelogue',
        'auth_state',
        'core_state',
        'Topup_state',
        'bottom_sheet_state',
        'product_state',
        'firestore_state',
        'payment_state',
        'client_mode_state',
        'assignSimState',
        'PWA_State',
        'dashboard',
        'analytics',
        'NumberPortingState',
        'CancellationsState'
      ],
    }),
    BrowserAnimationsModule,
    MatBottomSheetModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'rain-web'),
    AngularFirestoreModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    ...services,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyAUKK1ckJNssAk7waDZ370xKh6iPUlylbM',
        libraries: ['places']
      })
    },
    provideClientHydration()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
