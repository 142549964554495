<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>are you sure you want to cancel your 4G SIM?</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>Your SIM cancellation will only happen at the end of your billing {{date}} cycle and your services will be
        available until then No payment is scheduled to be collected.</p>
    </div>

    <div class="button-container">
      <button class="cancel-btn" (click)="cancel()">cancel SIM</button>
      <button (click)="close()">no</button>
    </div>
  </div>
</app-bottom-sheet-content-template>