import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { AuthState } from 'src/app/store/Auth/auth.state';
import { ChangeOpenSheet, ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { ClientModeState } from 'src/app/store/Client Mode/client-mode.state';
import { PortInRequest, PortResponse } from 'src/app/store/NumberPorting/actions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NumberPortingService {

  constructor(
    private store: Store,
    private http: HttpClient,
  ) { }


  portIn(requestBody: PortInRequest) {
    const url: string = `${environment.MNP_SERVICE}/port-in/create-request`;
    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      }),
    };

    return this.http.post(url, requestBody, httpOptions as any).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ShowBottomSheet("port-request-success"))
        },
        error: () => {
          this.store.dispatch(new ShowBottomSheet("port-request-failure"))
        }
      })
    )
  }

  getCurrentPortStatusByServiceId(id: string): Observable<PortResponse> {
    const url: string = `${environment.MNP_SERVICE}/port-in/${id}/service`;

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      })
    };

    return this.http.get<PortResponse>(url, httpOptions);
  }

  //cancel number port in using id
  reversePortById(id: string): Observable<PortResponse> {
    const url: string = `${environment.MNP_SERVICE}/mnp-service/port-in/${id}/cancel`;

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      })
    };

    return this.http.post<PortResponse>(url, httpOptions);
  }



  //cancel number port in using id

  cancelPortInById(id: string): Observable<PortResponse> {
    const url: string = `${environment.MNP_SERVICE}/port-in/${id}/cancel`;

    let smeToken = this.store.selectSnapshot(AuthState.getTokenSme);
    let mode = this.store.selectSnapshot(ClientModeState.GetClientMode);
    let userToken = ((mode !== 'consumer') && smeToken) ? smeToken : this.store.selectSnapshot(AuthState.getTauToken)


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken
      })
    };

    const portCancelation = {
      reason: 'Cancel Port In Request',
      ported_in_error: true
    };

    return this.http.post<PortResponse>(url, portCancelation, httpOptions).pipe(
      tap({
        next: () => {
          this.store.dispatch(new ChangeOpenSheet("port-cancellation-success"))
        },
        error: () => {
          this.store.dispatch(new ChangeOpenSheet("port-cancellation-failure"))

        }
      })
    )
  }
}
