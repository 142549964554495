import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from './services/layout.service';
import { Select, Store } from '@ngxs/store';
import { Meta } from '@angular/platform-browser';
import { Observable, Subject, Subscription, filter, takeUntil } from 'rxjs';
import { CoreState } from './store/Core/core.state';
import { environment } from 'src/environments/environment';
import { AuthState } from './store/Auth/auth.state';
import { AuthIfNotAuthed, ShowLoader } from './store/Auth/actions/auth.actions';
import { HideHeader, TurnOffFirstTimeLanding } from './store/Core/actions/core.actions';
import { NavigationEnd, Router } from '@angular/router';
import { FireStoreActions } from './store/FireStore/actions';
import { PWAState } from './store/pwa/state/pwa.store';
import { PwaService } from './services/pwa.service';
import { WebServiceWorker } from './services/web-service-worker.service';
import { FetchCatelogueProducts } from './store/Catelogue/actions/catelogue.actions';
import { SSOService } from './services/sso.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(CoreState.getActiveBreakpoint) ap$: Observable<string>;
  @Select(AuthState.isSignedIn) isAuthed$: Observable<boolean>;
  @Select(PWAState.OpenInstaller) open$: Observable<boolean>;
  @Select(CoreState.HideHeader) hideHeader$: Observable<boolean>;
  @Select(AuthState.ShowExpiredModal) showExpiredModal$: Observable<boolean>;

  isNewAppVersionAvailable: boolean = false;
  newAppUpdateAvailableSubscription: Subscription;

  ngDestroy$ = new Subject()

  constructor(
    private layoutService: LayoutService,
    private store: Store,
    private meta: Meta,
    private router: Router,
    private pwaSVC: PwaService,
    private webSVCWorker: WebServiceWorker,
    private sso: SSOService
  ) {}

  ngOnInit(): void {
    console.log('app init version:', environment.version);
    this.pwaSVC.initPwaPrompt();
    this.checkIfAppUpdated();
    this.store.dispatch([new FireStoreActions.FetchWebsiteConfig(), new FireStoreActions.FetchLevelsConfig(), new FireStoreActions.FetchPromoDetails, new FetchCatelogueProducts()]);
    
    this.layoutService.subscribeToLayoutChanges().subscribe();
    this.meta.updateTag({ content: 'white' }, 'name=theme-color');

    const isSignedIn = this.store.selectSnapshot(AuthState.isAuthed);

    if (isSignedIn) this.store.dispatch(new TurnOffFirstTimeLanding());

    this.watchForRouteChanges();
    this.watchForTokenExpiry();

    const payloadGo = 'https://www.sit.raingo.co.za/?sheet=sign_in&sso=U2FsdGVkX1%2BDclkW1z9BGTRUhqDvbTU6YtXp6XxPoCNn8BvIy99FI5JqVNAtgeRUq3zI1NscGZ1IBYrVYnrEKO%2F1gmzc89XnhH1b5LttwZFXnW7ebbu612zMm98k0OemRkHcWfXaB2sec%2BISWb%2BDpWYnqP6zsTSrAgJnNaiDBVHkOw3E4L3k48Pwp%2BTIkpcHxN4xAGkh%2FtT2dVbNni9yIaxNQb%2B09yK%2FytPfjUl7YThX8uk%2FuVtSsBhqBTyZ73tO5MuLK43TSBLbW1iWcVkg0AdW5dK%2BlJCDFp%2Fn%2BMLNA7egKGMTMowy9M0v5MgFLax2zyYF%2FQa6MmdyPp%2F4VRDV8Rij1ZJN0MWdIJ4xJ3l%2FHkf2t3dHydmsQwAfttUEm0zaSMjGvqDkumy07g%2BpY%2B47IpszKI2C93wNib5lTsOU6A68h8%2BovF9GbmcX%2FeFObFjG0l3TSJakSXbXSLIdYB6v1bDgu1efk5JeBP2ElzHNAZhJ7blltIvtzxwz6gN1Vu41kZFQe5yzIB9tiV%2FzrMS%2BK8T5ois9F%2F5nqDkxUW3ojtRY4g%2BCAiK3YvQMf1ZFOYWglkr92%2F%2BSwnNcmC1OWk8fgd7XT0kwrChQIp%2BFF5K9%2FXuELc8ZgO6agPOsHFSKsYQ6nbmfdwF6D7M1%2BpVqCEpG9Oy9ymmRTEKx9TWgqJdXBB%2FAbzOsXgupINApto9%2Bpah7utvbzLPcBe9hSIuef4Pzpvyu2pb5p0zbazPedXX5VaPDGitiibwJhbx1HHTPNtAwRfYHBGorZRUaHbB%2BTSNOULkUp3B8BA%2FAPK%2FJW6QlvOd0XzEIWQrnzpuD9%2BU8672XtqxPOoQ6gWHcBRpATPv8dCPddFSiGNHZKCiImWPGwjUNILZ1dTCaztKGs3jknNd5sZkFkDA%2BJLyggrU%2FFBxujrETRWhYl75KrtnNrGtRNfpWTmUKKKSceKiz3dxiAX6wrFD9QtHKgh%2FEOH9tgf1CdG3L4V8R3Er3HVF9HbdiKdCsHweYzEDEkYShBAgWZIKG8caG5wHWaCpIkYS8rCTf3V%2BZuuMqO8qgHyg%2B9Admp82rofMZFoj%2BzqKwM5D3eyEFK0C3Z%2FfQJom4Aguv78wFMT%2FKEKtvCqHx%2ByuhQbU3GAUD3mYdobo8tZBqXmhrRE9NYUKXXoX2EzXdS7tDR6M8LxMX7UpK8C0pN4wri8wY6JuzPlf4UoguMw3B7DSq9tfIqUAeMxzjhVyEwiOFZR9iyT8gJzMzyTS9efJCu7FGTCjr7ZMlwTMIvsyM5ShSQJGAkv9kq72UDKP1e7LpC8DmkSIgrbfZEhHQeKZtVpgUv%2F0%3D';
    // this.sso.decodeUrlParams(payloadGo).then((res) => console.log(JSON.parse(res)));

  }

  watchForTokenExpiry() {
    this.store.select(AuthState.isTokenExpired)
      .pipe(
      takeUntil(this.ngDestroy$)    
    ).subscribe(res => {
      if (res) {
        this.store.dispatch([new AuthIfNotAuthed()]);
      }
    })
  }

  watchForRouteChanges() {
    this.router.events
    .pipe( 
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.ngDestroy$)
     )
    .subscribe((res) => {
      const event = res as NavigationEnd;
      const refreshDataRoutes = ['/', '/services', '/wifi'];

      if(res instanceof NavigationEnd) {
        if(res.urlAfterRedirects === '/services' || !res.urlAfterRedirects?.includes('/services')) {
          this.store.dispatch(new HideHeader(false));
        }

      }
      
      if (refreshDataRoutes.includes(event?.urlAfterRedirects)) {
        setTimeout(() => {
          this.store.dispatch([new AuthIfNotAuthed()]);
        }, 500);
      }
    });
  }

  checkIfAppUpdated() {
    this.newAppUpdateAvailableSubscription = this.webSVCWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
      this.isNewAppVersionAvailable = versionAvailableFlag;
    });
  }

  refreshApp() {
    window.location.reload();
  }

  ngOnDestroy(): void {
      this.ngDestroy$.next(null);
      this.ngDestroy$.complete();
      this.newAppUpdateAvailableSubscription?.unsubscribe();
  }
}
