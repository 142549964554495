import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsService } from './guards/auth.guard';
import { FetchAllService } from './guards/fetch-services.guard';
import { services } from '../services';
import { SignedInCheckService, SignInPageGuard } from './guards/sign-in-page.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    PermissionsService,
    FetchAllService,
    SignedInCheckService,
    ...services
  ]
})
export class CoreModule { }
