<app-bottom-sheet-content-template>
  <h1 Header class="page-header"><strong>your SIM cancellation is successful</strong></h1>

  <div Content>
    <div class="message-container">
      <p>Cancellation is scheduled for the end of this month and your final payment for this SIM will be processed on
        your selected date next month</p>
    </div>

    <div class="button-container">
      <button (click)="close()">got it</button>
    </div>
  </div>
</app-bottom-sheet-content-template>