import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChangeOpenSheet, ShowBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-port-pending-sheet',
  templateUrl: './port-pending-sheet.component.html',
  styleUrl: './port-pending-sheet.component.scss'
})
export class PortPendingSheetComponent {

  constructor(
    private store: Store
  ) { }

  requestAssistance() {
    this.store.dispatch(new ChangeOpenSheet("request-a-call"))
  }

}
