import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionEndCheckResolver } from './resolvers/transaction-end-check.resolver';
import { FetchServicesGuard } from './core/guards/fetch-services.guard';
import { SignInPageGuard } from './core/guards/sign-in-page.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
    pathMatch: 'full',
    canActivate: [SignInPageGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/Dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [FetchServicesGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'assign-sim',
    loadChildren: () => import('./pages/assign-sim-page/assign-sim-page.module').then(m => m.AssignSimPageModule),
  },
  {
    path: 'unavailable',
    loadChildren: () => import('./pages/arrears-page/arrears-page.module').then(m => m.ArrearsPageModule),
  },
  {
    path: 'payment-options',
    loadChildren: () => import('./pages/payment-options/payment-options.module').then(m => m.PaymentOptionsModule),
    resolve: [TransactionEndCheckResolver]
  },
  {
    path: 'payment-result',
    loadChildren: () => import('./pages/payment-result/payment-result.module').then(m => m.PaymentResultModule),
  },
  {
    path: 'dev-testing',
    loadChildren: () => import('./pages/testing/testing.module').then(m => m.TestingModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'account-details',
    loadChildren: () => import('./pages/account-details-page/account-details-page.module').then(m => m.AccountDetailsPageModule)
  },
  {
    path: '**', redirectTo: "/", pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
