import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { DashboardState } from 'src/app/pages/Dashboard/store/state/dashboard.state';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';
import { CancelPortById } from 'src/app/store/NumberPorting/actions/number-porting.actions';

@Component({
  selector: 'raingo-port-cancellation-sheet',
  templateUrl: './port-cancellation-sheet.component.html',
  styleUrl: './port-cancellation-sheet.component.scss'
})
export class PortCancellationSheetComponent {
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id
  constructor(
    private store: Store
  ) { }

  close() {
    this.store.dispatch(new CloseBottomSheet())
  }
  cancelPort() {

    this.store.dispatch(new CancelPortById(this.serviceId))
  }

}
