<app-bottom-sheet-content-template>
  <h1 Header class="port-header">need <strong>assistance</strong>? </h1>

  <div Content>
    <div class="message-container">
      <p>Your number porting to rain has failed. Would you like a support agent to assist you?.</p>
    </div>

    <div class="button-container">
      <button class="port-again-btn" (click)="goToPortInPage()">try again</button>
    </div>
  </div>
</app-bottom-sheet-content-template>
