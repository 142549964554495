import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseBottomSheet } from 'src/app/store/BottomSheet/actions/bottom-sheet-actions.actions';

@Component({
  selector: 'raingo-five-g-cancellation-failure-sheet',
  templateUrl: './five-g-cancellation-failure-sheet.component.html',
  styleUrl: './five-g-cancellation-failure-sheet.component.scss'
})
export class FiveGCancellationFailureSheetComponent {
  constructor(
    private store: Store
  ) { }
  close() {
    this.store.dispatch([new CloseBottomSheet()])
  }
}
