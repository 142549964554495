import { Component, Input } from '@angular/core';
import { RainGoCardTypes } from '../../../pages/Dashboard/ui-components/types/raingo-card.type';

@Component({
  selector: 'raingo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() type: RainGoCardTypes = '2';
  @Input() is5GPrepaid: boolean = false;

}
