<div class="manage-options-page-container">
  <!-- 4G check -->
  @if (serviceType?.product?.category === "4G") {

  <raingo-manage-card [title]="'need help with your SIM'"
    [description]="'SIM not working or has the SIM been lost or stolen?'" (click)="goToSimSwap()">
  </raingo-manage-card>

  @if(serviceType?.type !== "Legacy"){
  <raingo-manage-card [title]="portTitle" [description]="portDescription" (click)="portNumber()">
  </raingo-manage-card>
  }

  @if(serviceType?.product?.config?.legacy){
  <raingo-manage-card title="upgrade to rainOne" (click)="upgradeService()"
    description="You've unlocked a FREE upgrade to rainone with FREE calls & data.">
  </raingo-manage-card>
  }

  @if(serviceType?.type === "Legacy" && !(serviceType?.scheduled_actions[0]?.action_type === 'Cancellation')){
  <raingo-manage-card [title]="'thinking of cancelling?'" (click)="cancel4GService()"
    [description]="'We hope not, but if you are, you can request a cancellation here.'">
  </raingo-manage-card>
  }

  @if(serviceType?.type === "Legacy" && serviceType?.scheduled_actions[0]?.action_type === 'Cancellation'){
  <raingo-manage-card [title]="'reverse cancellation'" (click)="reverse4GCancellation()"
    [description]="'You can change your mind anytime before your cancellation date.'">
  </raingo-manage-card>
  }

  <raingo-manage-card [title]="'SIM details'" (click)="showSimDetails()"
    [description]="'Change your SIM name & see your SIM info'">
  </raingo-manage-card>
  }

  @else if (serviceType?.product?.category === "5G") {

  <raingo-manage-card [title]="'SIM details'" (click)="showSimDetails()"
    [description]="'Change your SIM name & see your SIM info'">
  </raingo-manage-card>

  @if(serviceType?.product?.config?.legacy){
  <raingo-manage-card title="upgrade to rainOne" (click)="upgradeService()"
    description="You've unlocked a FREE upgrade to rainone with FREE calls & data.">
  </raingo-manage-card>
  }

  @if(!serviceType?.scheduled_actions[0]){
  <raingo-manage-card [title]="'thinking of cancelling?'" (click)="cancel5GService()"
    [description]="'We hope not, but if you are, you can request a cancellation here.'">
  </raingo-manage-card>
  }



  @if(serviceType?.scheduled_actions[0]?.action_type === 'Cancellation'){
  <raingo-manage-card [title]="'reverse cancellation'" (click)="reverse5GCancellation()"
    [description]="'You can change your mind anytime before your cancellation date.'">
  </raingo-manage-card>
  }

  }
</div>