import { Injectable, OnDestroy } from '@angular/core';
import { Action, Actions, Selector, State, StateContext, Store, ofActionCompleted } from '@ngxs/store';
import { AxiomAuthResponse, DigitalIdentityClientObject } from 'src/app/interfaces/interfaces';
import { Subject, tap } from 'rxjs';
import { IAccountStatusDetail, AccountStates, IInvoice, IUsersFCDetails, INotificationChannel, IPaymentMethod, IBillCyclePayload } from 'src/app/interfaces/account.interfaces';
import { AuthIfNotAuthed, AuthUserWithSessionId, GetUserDetails, HideLoader, HideTokenExpiredModal, SetAuthTokens, ShowLoader, ShowTokenExpiredModal, SignIn, SignInFail, SignInWithToken, SignOut, UpdateSignedInStatus } from './actions/auth.actions';
import jwtDecode from 'jwt-decode';
import { AssignSimState } from '../AssignSim/assign-sim.state';
import { AuthService } from 'src/app/services/auth.service';
import { AuthWithTLS, FetchAccountStatus, FetchAccountStatusFail, FetchAccountStatusSuccess } from '../Core/actions/core.actions';
import { ProductActions } from '../Product/actions';
import { CoreActions } from '../Core/actions';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { AccountService } from 'src/app/services/account.services';
import { BottomSheetActions } from '../BottomSheet/actions';
import { Utils } from '@Utils';
import { UserService } from 'src/app/services/user.service';
import { CoreState } from '../Core/core.state';
import { jwtExpired } from 'src/app/utils/helpers/auth-helper';
import { FetchAccountInvoices, FetchAccountInvoicesFail, FetchAccountInvoicesSuccess, FetchUsersFCDetails, FetchUsersFCDetailsFail, FetchUsersFCDetailsSuccess, GetUsersBillCycleDate, GetUsersBillCycleDateFail, GetUsersBillCycleDateSuccess } from './actions/user.actions';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { FetchActivePaymentMethod, FetchActivePaymentMethodFail, FetchActivePaymentMethodSuccess, FetcOptInCategroryValues, FetcOptInValues, FetcOptInValuesFail, FetcOptInValuesSuccess, SetOptInCategory, SetOptInCategoryFail, SetOptInCategorySuccess, SetOptInChannel, SetOptInChannelFail, SetOptInChannelSuccess, UpdateAccountDetails, UpdateBillCycleDateFail, UpdateBillCycleDateSuccess, UpdatePaymentDate, UpdatePaymentDateFail, UpdatePaymentDateSuccess } from './actions/account.actions';
import { NotificationChannels } from 'src/app/core/enums/account.enum';
import { SetClientMode } from '../Client Mode/actions/client-mode.actions';
import { FetchBillCycleDates } from '../FireStore/actions/firestore.actions';
const moment = _rollupMoment || _moment;

function loadDefault():AuthStateModel {
    return {
        signInLoading: false,
        registerLoading: false,
        token: null,
        signedIn: false,
        isAuthed: false,
        axiomUser: null,
        tauToken: null,
        tokenSME: null,
        nickname: null,
        isSSOLogin: false,
        accountStatus: null,
        hasPrepaid: false,
        authError: {
            message: null,
            hasError: false
        },
        primaryMsisdn: null,
        bill_cycle_date: null,
        showExpiredModal: false,
        show_loader: false,
        invoices: null,
        fc_user_details: null,
        opt_in_options: null,
        active_payment_method: null
    }
}

interface AuthStateModel {
    signInLoading: boolean;
    registerLoading: boolean;
    token: string | null;
    signedIn: boolean;
    isAuthed: boolean;
    axiomUser: DigitalIdentityClientObject | null;
    tauToken: string | null;
    tokenSME: string | null;
    nickname: string | null;
    isSSOLogin: boolean;
    accountStatus: IAccountStatusDetail | null;
    hasPrepaid: boolean;
    authError: {
        message: string | null;
        hasError: boolean;
    };
    primaryMsisdn: string | null;
    bill_cycle_date: string | null;
    showExpiredModal: boolean,
    show_loader: boolean,
    invoices: IInvoice[] | null,
    fc_user_details: IUsersFCDetails | null,
    opt_in_options: INotificationChannel[] | null,
    active_payment_method: IPaymentMethod | null
}

@State<AuthStateModel>({
    name: 'auth_state',
    defaults: loadDefault()
})

@Injectable()
export class AuthState implements OnDestroy {

    @Selector()
    static SignInLoading(state: AuthStateModel) {
      return state.signInLoading;
    }

    @Selector()
    static getToken(state: AuthStateModel) {
      return state.token;
    }
    @Selector()
    static getTokenSme(state: AuthStateModel) {
      return state.tokenSME;
    }
    @Selector()
    static getTauToken(state: AuthStateModel) {
      return state.tauToken;
    }
    @Selector()
    static isSignedIn(state: AuthStateModel) {
      return state.signedIn;
    }

    @Selector()
    static isAuthed(state: AuthStateModel) {
      return state.isAuthed;
    }
    @Selector()
    static isTokenExpired(state: AuthStateModel) {
      return jwtExpired(state.token);
    }
  
    @Selector()
    static isR1Mobi(state: AuthStateModel) {
      return state.token;
    }

    @Selector()
    static getNickname(state: AuthStateModel) {
      if(state.nickname) return state.nickname;
      else if(state.axiomUser) return state.axiomUser.nickname;
  
      return null;
    }

    @Selector()
    static getAxiomUser(state: AuthStateModel) {
      return state.axiomUser;
    }
  

    @Selector()
    static UserIsAssigned(state: AuthStateModel) {
      if(state.axiomUser) return state.axiomUser.status === 'Active';
      return;
    }
  
    @Selector()
    static getAuthError(state: AuthStateModel) {
      return state.authError;
    }
    @Selector()
    static isInArrears(state: AuthStateModel) {
      const isSignedIn = state.isAuthed;
      if (!isSignedIn) return false;
  
      const status = state.accountStatus;
      if (status as IAccountStatusDetail) {
        return ['GRACE', 'OVERDUEHIGHSPEED', 'OVERDUE', 'SUSPENDED'].includes(
          status?.accountState as AccountStates
        );
      }
  
      return false;
    }

    @Selector()
    static getAccountStatus(state: AuthStateModel) {
      return state.accountStatus;
    }

    @Selector()
    static isSSOLogin(state: AuthStateModel) {
      return state.isSSOLogin;
    }

    @Selector()
    static hasPrepaid(state: AuthStateModel) {
      return state.hasPrepaid;
    }

    @Selector()
    static GetBillCycleDate(state: AuthStateModel): Date {
      const date = new Date(state.bill_cycle_date!);
      return date;
    }
    
    @Selector()
    static ShowExpiredModal(state: AuthStateModel) {
      return state.showExpiredModal;
    }

    @Selector()
    static GetConsumerUserID(state: AuthStateModel) {
      if(state.token) {
        const decoded = Utils.Helpers.decodeJWT(state.token);
        if(decoded) return decoded.user_id;
      }
    }

    @Selector()
    static GetSMEUserID(state: AuthStateModel) {
      if(state.tokenSME) {
        const decoded = Utils.Helpers.decodeJWT(state.tokenSME);
        if(decoded) return decoded.user_id;
      }
    }

    @Selector()
    static GetLoader(state: AuthStateModel) {
      if(state.show_loader) {

        return state.show_loader;
      }
    }

    @Selector()
    static GetInvoices(state: AuthStateModel) {
      return state.invoices;
    }

    @Selector()
    static GetFCUserDetails(state: AuthStateModel) {
      return state.fc_user_details;
    }

    @Selector()
    static GetWhatsAppOptIn(state: AuthStateModel) {
      if(state.opt_in_options) {
        const wOptIn = state.opt_in_options.find((o: INotificationChannel) => o.channelId === NotificationChannels.WHATSAPP);
        if(wOptIn) return wOptIn;
      }
    }

    @Selector()
    static GetCategoryOptIn(state: AuthStateModel) {
      if(state.opt_in_options) {
        const cOptIn = state.opt_in_options.find((o: INotificationChannel) => o.categoryId === NotificationChannels.EMAIL);
        if(cOptIn) return cOptIn;
      }
    }

    @Selector()
    static GetActivePaymentMethod(state: AuthStateModel) {
      return state.active_payment_method;
    }

    private ngDestroy$: Subject<any> = new Subject();

    constructor(
        private readonly store: Store,
        private readonly authService: AuthService,
        private readonly _accSvc: AccountService,
        private readonly _userService: UserService,
        private readonly actions: Actions
    ) {}
  
    @Action(SetAuthTokens)
    SetAuthTokens(
      ctx: StateContext<AuthStateModel>,
      action: SetAuthTokens
    ) {
      const token = action.payload.token;
      if (token) {

        ctx.patchState({
          token: token
        });
      }
    }

  @Action(SignIn)
  SignIn(ctx: StateContext<AuthStateModel>, action: SignIn) {
    ctx.patchState({
      signInLoading: true,
    });
    const routerState = this.store.selectSnapshot(RouterState.state);
    return this.authService.auth(action.email.trim(), action.password).pipe(
      tap({
        next: (res: AxiomAuthResponse) => {
          
          ctx.patchState({
            token: res?.tokenCredential ?? null,
            tauToken: res?.token ?? null,
            tokenSME: res?.tokenSme ?? null,
            signInLoading: false,
            isSSOLogin: false,
            nickname: res?.nickname,
            signedIn: Boolean(res?.token),
            isAuthed: Boolean(res?.token),
            authError: {
              message: '',
              hasError: false,
            },
            hasPrepaid: res.hasPrepaid,
            showExpiredModal: false
          });

          ctx.dispatch([
            new FetchBillCycleDates(),
            new UpdateSignedInStatus(res?.tokenCredential),
            new GetUserDetails(),
            // new ProductActions.FetchProducts(),
            new CoreActions.FetchServices('SignIn'),
            new CoreActions.AuthWithTLS(),
            new CoreActions.FetchTopupHistory(),
            new FetchUsersFCDetails(),
            new FetchAccountInvoices(),
            new FetcOptInCategroryValues(),
            new FetcOptInValues(),
            new FetchActivePaymentMethod()
          ]);

          this.actions.pipe(ofActionCompleted(CoreActions.CheckIfIsPrimaryUser))
          .subscribe(
            () => {
              const dispatches: any = [];
              const navigatingToWifiPage = routerState?.url === '/services';
              setTimeout(() => {
                const assignSimStage = this.store.selectSnapshot(AssignSimState.GetStage);
                const decodedToken = Utils.Helpers.decodeJWT(res.tokenCredential);
                dispatches.push(new FetchAccountStatus({ email: decodedToken.sub, ignoreCache: true }));
                const isPrimaryUser = this.store.selectSnapshot(CoreState.isPrimaryUser);
                const location = window.location;
                
                if(assignSimStage === '2' || assignSimStage === '3') {
                  if(isPrimaryUser) {
                    dispatches.push(new BottomSheetActions.ChangeOpenSheet("assign_sim_primary_message"));
                  } else {
                    dispatches.push(new BottomSheetActions.ChangeOpenSheet('ask_assign_sim'));
                  }
                } else {
                  dispatches.push(new BottomSheetActions.CloseBottomSheet());
                  if (action.redirectToUrl) {
                    dispatches.push(new Navigate([`/${action.redirectToUrl}`]));
                  } else if(!navigatingToWifiPage && location.pathname === '/' && !assignSimStage) {
                    // dispatches.push(new Navigate([`/home`]));
                  }
                }
      
                return ctx.dispatch(dispatches);
              }, 500);
            }
          )

        },
        error: (err: unknown) => {
          ctx.dispatch(new SignInFail);
          ctx.patchState({
            signInLoading: false,
            authError: {
              message: 'Invalid email/password combination',
              hasError: true,
            },
          });
        },
      })
    );
  }

  @Action(UpdateSignedInStatus)
  UpdateLoggedInStatus(
    ctx: StateContext<AuthStateModel>,
    action: UpdateSignedInStatus
  ) {
    const aToken = action.token;
    const token = aToken ? aToken: ctx.getState().token || '';
    
    if (token) {
      const { sub, user_id } = jwtDecode(token) as { [key: string]: any } ?? {};
      ctx.patchState({
        signedIn: sub && user_id?.length > 0,
      });
    } else {
      ctx.patchState({
        signedIn: false,
      });
    }
  }

  @Action(GetUserDetails)
  GetUserDetails(
    ctx: StateContext<AuthStateModel>,
    action: GetUserDetails
  ) {
    const token = ctx.getState().token;
    
    if(!token) return;
    const { user_id } = JSON.parse(
      atob(String(token)?.split('.')[1])
    );
    return this._userService.getUserDetails(user_id).pipe(
      tap({
        next: (res: DigitalIdentityClientObject) => {
          ctx.dispatch(new GetUsersBillCycleDate());
          return ctx.patchState({
            axiomUser: res,
            signInLoading: false,
          });
        },
        error: (err: unknown) => console.error(err)
      })
    );
  }

  @Action(CoreActions.TurnOffSignInLoading)
  TurnOffSignInLoading(
    ctx: StateContext<AuthStateModel>,
    action: CoreActions.TurnOffSignInLoading
  ) {
    ctx.patchState({
      signInLoading: false,
    });
  }

  @Action(ShowLoader)
  ShowLoader(
    ctx: StateContext<AuthStateModel>,
    action: CoreActions.TurnOffSignInLoading
  ) {
    ctx.patchState({
      show_loader: true,
    });
  }

  @Action(HideLoader)
  HideLoader(
    ctx: StateContext<AuthStateModel>,
    action: CoreActions.TurnOffSignInLoading
  ) {
    ctx.patchState({
      show_loader: false,
    });
  }

  @Action(CoreActions.SSOSignIn)
  SSOSignIn(ctx: StateContext<AuthStateModel>, action: CoreActions.SSOSignIn) {
    const ssoPayload = action.payload;
    ctx.patchState({
      tauToken: ssoPayload.tauToken,
      token: ssoPayload.token,
      signInLoading: true,
      signedIn: true,
      isAuthed: true,
      isSSOLogin: true,
      hasPrepaid: ssoPayload.config?.hasPrepaid
    });
    if(ssoPayload.tokenSme) {
      ctx.patchState({
        tokenSME: ssoPayload.tokenSme
      })
    }
    const decodedToken = Utils.Helpers.decodeJWT(ssoPayload.token);

    const actions: any[] = [
      new ShowLoader(),
      new GetUserDetails(),
      new FetchAccountStatus({ email: decodedToken.sub, ignoreCache: true }),
      new UpdateSignedInStatus(),
      // new ProductActions.FetchProducts(),
      new CoreActions.FetchServices('SSOSignIn'),
      new CoreActions.FetchTopupHistory(),
      // new CoreActions.EndHomeLoader(),
    ];

    this.store.dispatch(actions);

    // setTimeout(() => {
    //   this.store.dispatch(new HideLoader())
    // }, 1000);
  }

  @Action(SignOut)
  SignOut(ctx: StateContext<AuthStateModel>, action: SignOut) {
    ctx.setState(loadDefault);

    if (!action?.sso) {
      ctx.dispatch(new CoreActions.AuthWithTLS());
    }
  }

  @Action(CoreActions.FetchAccountStatus)
  FetchAccountStatus(
    ctx: StateContext<AuthStateModel>,
    action: CoreActions.FetchAccountStatus
  ) {
    const state = ctx.getState();
    const { email } = action.payload;
    if(!action.payload.ignoreCache && state.accountStatus !== null) return;
    
    return this._accSvc.getAccountStatus(email).pipe(
      tap({
        next: (res) => ctx.dispatch(new FetchAccountStatusSuccess(res)),
        error: (err) => ctx.dispatch(new FetchAccountStatusFail(err)),
      })
    );
  }

  @Action(CoreActions.FetchAccountStatusSuccess)
  FetchAccountStatusSuccess(
    ctx: StateContext<AuthStateModel>,
    action: CoreActions.FetchAccountStatusSuccess
  ) {
    const status: IAccountStatusDetail = action.payload;
    ctx.patchState({
      accountStatus: status,
    });
  }

  @Action(SignInWithToken)
  SignInWithToken(
    ctx: StateContext<AuthStateModel>,
    action: SignInWithToken
  ) {
    
    ctx.patchState({
      token: action.token,
    });

    ctx.dispatch([
      new UpdateSignedInStatus(),
      new GetUserDetails(),
      new ProductActions.FetchProducts(),
      new CoreActions.FetchServices('SignInWithToken'),
      new AuthWithTLS(),
      new CoreActions.FetchTopupHistory(),
      // new CoreActions.EndHomeLoader(),
    ]);
  }

  @Action(AuthIfNotAuthed)
  AuthIfNotAuthed(ctx: StateContext<AuthStateModel>) {
    
    const { signedIn, token, isAuthed } = ctx.getState();
    const tokenExpired = this.store.selectSnapshot(AuthState.isTokenExpired);
    if(isAuthed) return;

    if (!signedIn || !token || tokenExpired) {
      ctx.dispatch([new AuthWithTLS()]);
    } else {
      ctx.dispatch([
        new AuthWithTLS(),
        new UpdateSignedInStatus(),
        new ProductActions.FetchProducts(),
        new CoreActions.FetchServices('AuthIfNotAuthed'),
        new CoreActions.FetchTopupHistory(),
        // new CoreActions.EndHomeLoader(),
      ]);
    }
  }

  @Action(AuthUserWithSessionId)
  AuthWithSessionId(
    ctx: StateContext<AuthStateModel>,
    action: AuthUserWithSessionId
  ) {
    return this.authService.sessionIdAuth(action.sessionId).pipe(
      tap({
        next: (res: AxiomAuthResponse) => {
          ctx.patchState({
            token: res?.tokenCredential,
          });
          return ctx.dispatch([
            new UpdateSignedInStatus(),
            new ProductActions.FetchProducts(),
            new CoreActions.FetchServices('AuthWithSessionId'),
            // new CoreActions.EndHomeLoader(),
          ]);
        },
        error: (err: unknown) => {
          return ctx.dispatch([
            new UpdateSignedInStatus(),
            new ProductActions.FetchProducts(),
            new CoreActions.FetchServices('AuthWithSessionId'),
            // new CoreActions.EndHomeLoader(),
          ]);
        },
      })
    );
  }

  @Action(GetUsersBillCycleDate)
  GetUsersBillCycleDate(
    ctx: StateContext<AuthStateModel>,
    action: GetUsersBillCycleDate
  ) {

    return this._userService.getBillCycleDate().subscribe({
      next: (res) => ctx.dispatch(new GetUsersBillCycleDateSuccess(res)),
      error: (err) => ctx.dispatch(new GetUsersBillCycleDateFail(err))
    })
  }

  @Action(GetUsersBillCycleDateSuccess)
  GetUsersBillCycleDateSuccess(
    ctx: StateContext<AuthStateModel>,
    action: GetUsersBillCycleDateSuccess
  ) {
    const start = action.payload.user.bill_cycle_spec_detail.cycle_period?.start;
    if(!start) return;

    let month = moment().month();
		let todayDay = moment().date();
    let todayYear = moment().year();
    
		if (start < todayDay) {
      if(month === 12) todayYear = todayYear + 1; // increment year as month is december before going into jan 2025
      month = month + 1;
    }
    
    ctx.patchState({
      bill_cycle_date: moment({ date: start, month: month, year: todayYear }).format('DD MMM YYYY').toString()
    })
  }

  @Action(ShowTokenExpiredModal)
  ShowTokenExpiredModal(
    ctx: StateContext<AuthStateModel>,
    action: ShowTokenExpiredModal
  ) {
    const state = ctx.getState();

    ctx.patchState({
      showExpiredModal: true
    })
  }

  @Action(HideTokenExpiredModal)
  HideTokenExpiredModal(
    ctx: StateContext<AuthStateModel>,
    action: ShowTokenExpiredModal
  ) {

    ctx.patchState({
      showExpiredModal: false
    })
  }

  @Action(FetchAccountInvoices)
  FetchAccountInvoices(
    ctx: StateContext<AuthStateModel>,
    action: FetchAccountInvoices
  ) {

    return this._userService.getInvoices().subscribe({
      next: (res: IInvoice[]) => ctx.dispatch(new FetchAccountInvoicesSuccess(res)),
      error: (err: any) => ctx.dispatch(new FetchAccountInvoicesFail(err))
    })
  }

  @Action(FetchAccountInvoicesSuccess)
  FetchAccountInvoicesSuccess(
    ctx: StateContext<AuthStateModel>,
    action: FetchAccountInvoicesSuccess
  ) {
    const invoices = action.payload;

    if(invoices) {
      ctx.patchState({
        invoices: invoices
      });
    }
  }

  @Action(FetchUsersFCDetails)
  FetchUsersFCDetails(
    ctx: StateContext<AuthStateModel>,
    action: FetchUsersFCDetails
  ) {

    return this._userService.getUserFCDetails().subscribe({
      next: (res: any) => ctx.dispatch(new FetchUsersFCDetailsSuccess(res)),
      error: (err: any) => ctx.dispatch(new FetchUsersFCDetailsFail(err))
    })
  }

  @Action(FetchUsersFCDetailsSuccess)
  FetchUsersFCDetailsSuccess(
    ctx: StateContext<AuthStateModel>,
    action: FetchUsersFCDetailsSuccess
  ) {
    const details = action.payload;
    ctx.patchState({
      fc_user_details: details
    })
    console.log('dets:', details);
    
  }

  @Action(FetcOptInCategroryValues)
  FetcOptInCategroryValues(
    ctx: StateContext<AuthStateModel>,
    action: FetcOptInCategroryValues
  ) {

    return this._accSvc.GetOptInCategoryDetails().subscribe({
      next: (res: any) => ctx.dispatch(new FetcOptInValuesSuccess(res)),
      error: (err: any) => ctx.dispatch(new FetcOptInValuesFail(err))
    })
  }

  @Action(FetcOptInValues)
  FetcOptInValues(
    ctx: StateContext<AuthStateModel>,
    action: FetcOptInValues
  ) {

    return this._accSvc.GetOptInChannelDetails().subscribe({
      next: (res: any) => ctx.dispatch(new FetcOptInValuesSuccess(res)),
      error: (err: any) => ctx.dispatch(new FetcOptInValuesFail(err))
    })
  }

  @Action(FetcOptInValuesSuccess)
  FetcOptInValuesSuccess(
    ctx: StateContext<AuthStateModel>,
    action: FetcOptInValuesSuccess
  ) {
    const state = ctx.getState();
    let currentOptions = state.opt_in_options !== null ? [...state.opt_in_options] : [];
    const option = action.payload.data;
    currentOptions.push(option)
    ctx.patchState({
      opt_in_options: currentOptions
    })
  }

  @Action(FetchActivePaymentMethod)
  FetchActivePaymentMethod(
    ctx: StateContext<AuthStateModel>,
    action: FetcOptInValues
  ) {

    return this._accSvc.GetActivePaymentMethod().subscribe({
      next: (res: any) => ctx.dispatch(new FetchActivePaymentMethodSuccess(res)),
      error: (err: any) => ctx.dispatch(new FetchActivePaymentMethodFail(err))
    })
  }

  @Action(FetchActivePaymentMethodSuccess)
  FetchActivePaymentMethodSuccess(
    ctx: StateContext<AuthStateModel>,
    action: FetchActivePaymentMethodSuccess
  ) {
    const state = ctx.getState();
    const method = action.payload;
    ctx.patchState({
      active_payment_method: method
    })
    console.log('active payment method:', method);
    
  }

  @Action(SetClientMode)
  onSetClientMode(
    ctx: StateContext<AuthStateModel>,
    action: SetClientMode
  ){
    ctx.dispatch([
      new FetchUsersFCDetails(),
      new FetchAccountInvoices(),
      new FetcOptInCategroryValues(),
      new FetcOptInValues(),
      new FetchActivePaymentMethod()
    ])
  }
 
  @Action(UpdatePaymentDate)
  UpdatePaymentDate(
    ctx: StateContext<AuthStateModel>,
    action: UpdatePaymentDate
  ){
    const date = action.payload;
    this._accSvc.UpdatePaymentDate(date.start_day.toString()).subscribe({
      next: (res: any) => ctx.dispatch(new UpdatePaymentDateSuccess(res)),
      error: (err: any) => ctx.dispatch(new UpdatePaymentDateFail(err))
    })
  }

  @Action(UpdatePaymentDateSuccess)
  UpdatePaymentDateSuccess(
    ctx: StateContext<AuthStateModel>,
    action: UpdatePaymentDateSuccess
  ){
    const response = action.payload;

    console.log('response:', response);

    ctx.dispatch(new FetchUsersFCDetails())
    
  }

  @Action(UpdatePaymentDate)
  UpdateBillCycleDate(
    ctx: StateContext<AuthStateModel>,
    action: UpdatePaymentDate
  ){
    const state = ctx.getState();
    const date = action.payload;
    const payload: IBillCyclePayload = {
      user_id: state.fc_user_details?.id!,
      bill_cycle_spec: date.id
    }
    this._accSvc.SetBillCycleOption(payload).subscribe({
      next: (res: any) => ctx.dispatch(new UpdateBillCycleDateSuccess(res)),
      error: (err: any) => ctx.dispatch(new UpdateBillCycleDateFail(err))
    })
  }

  @Action(UpdateBillCycleDateSuccess)
  UpdateBillCycleDateSuccess(
    ctx: StateContext<AuthStateModel>,
    action: UpdateBillCycleDateSuccess
  ){
    const response = action.payload;

    console.log('response:', response);

    ctx.dispatch(new FetchUsersFCDetails())
    
  }

  @Action(UpdateAccountDetails)
  UpdateAccountDetails(
    ctx: StateContext<AuthStateModel>,
    action: UpdateAccountDetails
  ){
    const payload = action.payload;

    console.log('payload:', payload);

    this._accSvc.UpdateUserDetailsOld(payload).subscribe({
      next: (res) => ctx.dispatch(new UpdateBillCycleDateSuccess(res)),
      error: (err: unknown) => ctx.dispatch(new UpdateBillCycleDateFail(err)),
      complete: () => ctx.dispatch(new FetchUsersFCDetails())
    })
    
  }

  @Action(SetOptInCategory)
  SetOptInCategory(
    ctx: StateContext<AuthStateModel>,
    action: SetOptInCategory
  ){
    const payload = action.payload;
    this._accSvc.SetOptInCategory(payload).subscribe({
      next: (res) => ctx.dispatch(new SetOptInCategorySuccess(res)),
      error: (err: unknown) => ctx.dispatch(new SetOptInCategoryFail(err)),
      complete: () => ctx.dispatch([new FetchUsersFCDetails(), new FetcOptInCategroryValues()])
    })
  }

  @Action(SetOptInChannel)
  SetOptInChannel(
    ctx: StateContext<AuthStateModel>,
    action: SetOptInChannel
  ){
    const payload = action.payload;
    this._accSvc.SetOptInChannel(payload).subscribe({
      next: (res) => ctx.dispatch(new SetOptInChannelSuccess(res)),
      error: (err: unknown) => ctx.dispatch(new SetOptInChannelFail(err)),
      complete: () => ctx.dispatch([new FetchUsersFCDetails(), new FetcOptInValues()])
    })
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(null);
    this.ngDestroy$.complete();
  }
}