<app-bottom-sheet-content-template>
  <h1 Header class="cancellation-header"><strong>could your 5G signal be better?</strong> </h1>

  <div Content>
    <div class="message-container">
      <p>We want to make sure you’re getting the best signal from your 5G router. Tap the button below and we’ll give
        you a call to help you out.</p>
    </div>

    <div class="button-container">
      <button class="cancel-btn" (click)="cancel()">cancel SIM</button>
      <button (click)="requestACall()">call me</button>
    </div>
  </div>
</app-bottom-sheet-content-template>