import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberPortingPageRoutingModule } from './number-porting-page-routing.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NumberPortingPageRoutingModule,
    ReactiveFormsModule,

  ]
})
export class NumberPortingPageModule { }
