import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { PortSim } from 'src/app/store/NumberPorting/actions/number-porting.actions';
import { DashboardState } from '../../Dashboard/store/state/dashboard.state';

@Component({
  selector: 'raingo-number-porting-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,

  ],
  templateUrl: './number-porting-form.component.html',
  styleUrl: './number-porting-form.component.scss'
})
export class NumberPortingFormComponent implements OnInit {
  portInForm: FormGroup;
  portType: string
  serviceId = this.store.selectSnapshot(DashboardState.GetSelectedService)?.id
  accountOptions = ['Prepaid', 'Contract/Postpaid'];
  contractOptions = ['Personal', 'Business'];

  selectedAccountType = 'Prepaid';
  selectedContractType = 'Personal';



  constructor(
    private store: Store
  ) { }


  ngOnInit(): void {
    this.portInForm = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      accountType: new FormControl(this.selectedAccountType, [Validators.required]),
      contractType: new FormControl(this.selectedContractType, [Validators.required]),
      idNumber: new FormControl('', [Validators.required, Validators.minLength(13)]),
      accountNumber: new FormControl('', [Validators.required]),
      businessRegistrationNumber: new FormControl('', [Validators.required]),
      businessContactPersonName: new FormControl('', [Validators.required]),
      businessPersonNumber: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    });
  }


  onAccountTypeChange(newAccountType: string) {
    this.selectedAccountType = newAccountType;
  }

  onContractTypeChange(newContractType: string) {
    this.selectedContractType = newContractType
  }

  submitPort() {

    if (this.selectedAccountType === "Prepaid") {
      this.portType = 'prepaid'
    } else if (this.selectedAccountType === "Contract/Postpaid" && this.selectedContractType === "Personal") {
      this.portType = 'postpaid'
    } else if (this.selectedAccountType === "Contract/Postpaid" && this.selectedContractType === "Business") {
      this.portType = 'business'
    } else {
      this.portType = 'prepaid'
    }

    switch (this.portType) {
      case 'prepaid':
        this.store.dispatch(new PortSim({
          serviceId: this.serviceId,
          fromMisidn: this.portInForm.get('phoneNumber')?.value ?? '',
          serviceType: 'MOBILE',
          accountType: this.selectedAccountType
        }));
        break;
      case 'postpaid':
        this.store.dispatch(new PortSim({
          serviceId: this.serviceId,
          fromMisidn: this.portInForm.get('phoneNumber')?.value ?? '',
          serviceType: 'MOBILE',
          accountType: this.selectedAccountType,
          accountId: this.portInForm.get('idNumber')?.value ?? '',
          accountNumber: this.portInForm.get('accountNumber')?.value ?? ''
        }));
        break;
      case 'business': console.log('business');
        break;

    }
  }



  getPhoneNumberErrorMessage() {
    const control = this.portInForm.get('phoneNumber');
    if (!control) return '';

    if (control.errors) {
      if (control.errors['required'] && (control.touched || control.dirty)) {
        return 'Phone number is required';
      }
      if (control.errors['pattern'] && (control.touched || control.dirty)) {
        return 'Phone number must start with "0" and have 10 digits';
      }
    }
    return '';
  }

  getControlErrorMessage(controlName: string): string {
    const control = this.portInForm.get(controlName);
    if (!control) return '';
    if (control.touched || control.dirty) {
      if (control.hasError('required')) {
        return `${controlName.replace(/([A-Z])/g, ' $1')} is required`;
      }
    }
    return '';
  }


}
