<div class="number-porting-form-container">
  <div class="number-port-inputs">
    <form [formGroup]="portInForm">
      <div class="input-container">
        <label for="port-in-number">Enter phone number</label>
        <br />
        <input type="tel" name="port-in-number" required maxlength="10" pattern="0[0-9]{9}"
          formControlName="phoneNumber" placeholder="(example: 069 600 0000)" />
        @if(getPhoneNumberErrorMessage()){
        <div class="errors">
          <p>{{getPhoneNumberErrorMessage()}}</p>
        </div>
        }
      </div>


      <div class="port-in-inputs">
        <label for="port-in-account">Select type of account</label>
        <br />
        <div class="radio-input">
          @for(accountType of accountOptions; track accountType){
          <div class="radio-input-contaner">
            <input type="radio" name="accountType" [value]="accountType" formControlName="accountType"
              [checked]="accountType === selectedAccountType" (change)="onAccountTypeChange(accountType)" />
            {{accountType}}
          </div>
          }
        </div>
      </div>

      @if(selectedAccountType === "Contract/Postpaid"){
      <div class="postpaid-options">
        <div class="port-in-inputs">
          <label for="port-in-account">Select type of contract</label>
          <br />
          <div class="radio-input">
            @for(contract of contractOptions; track contract){
            <div class="radio-input-contaner">
              <input type="radio" name="contractType" formControlName="contractType" [value]="contract"
                (change)="onContractTypeChange(contract)" [checked]="contract === selectedContractType" />
              {{contract}}
            </div>
            }
          </div>
        </div>

        <div class="input-container">
          <label for="port-in-number">Enter account number*</label>
          <br />
          <input type="tel" name="port-in-number" required placeholder="account number"
            formControlName="accountNumber" />
          <p class="account-number-text">
            *You can find your account number on invoices
            or statements from your old network.
          </p>
          @if(getControlErrorMessage('accountNumber')){
          <div class="errors">
            <p>{{ getControlErrorMessage("accountNumber") }}</p>
          </div>
          }
        </div>


        <div class="input-container">
          <label for="port-in-number">Account holder ID/passport number</label>
          <br />
          <input type="number" name="port-in-number" required placeholder="ID/passport number"
            formControlName="idNumber" />
          @if(getControlErrorMessage('idNumber')){
          <div class="errors">
            <p>{{ getControlErrorMessage("idNumber") }}</p>
          </div>
          }
        </div>

        @if(selectedContractType === "Business"){
        <div class="work-details">
          <!--business-->
          <div class="input-container">
            <label for="port-in-number">Business registration number</label>
            <br />
            <input type="tel" name="port-in-number" required placeholder="registration number"
              formControlName="businessRegistrationNumber" />
            @if(getControlErrorMessage('businessRegistrationNumber')){
            <div class="errors">
              <p>{{ getControlErrorMessage("businessRegistrationNumber") }}</p>
            </div>
            }
          </div>

          <div class="input-container">
            <label for="port-in-number">Business contact name</label>
            <br />
            <input type="tel" name="port-in-number" required placeholder="name"
              formControlName="businessContactPersonName" />
            @if(getControlErrorMessage('businessContactPersonName')){
            <div class="errors">
              <p>{{ getControlErrorMessage("businessContactPersonName") }}</p>
            </div>
            }
          </div>

          <div class="input-container">
            <label for="port-in-number">Business contact number</label>
            <br />
            <input type="tel" name="port-in-number" required placeholder="number"
              formControlName="businessPersonNumber" />
            @if(getControlErrorMessage('businessPersonNumber')){
            <div class="errors">
              <p>{{ getControlErrorMessage("businessPersonNumber") }}</p>
            </div>
            }
          </div>

        </div>
        }

      </div>

      }

    </form>


  </div>

  <div class="button-container">
    <button (click)="submitPort()">confirm </button>
  </div>
</div>
